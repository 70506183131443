<template>
  <div class="tabs__tab-content-wrapper">
    <div class="tabs__tab-content-actions">
      <button class="btn btn--filter" :class="{ '--filter-active': isFieldsFilterActive }" @click.prevent="fieldsFilterShowModal">
        <img src="/img/baseline_filter_list_white_48dp.png" :alt="$t('filter')" :title="$t('filter')" class="btn__img btn__img--small">{{ $t('filter') }}
      </button>
      <button class="btn btn--filter" :class="{ '--filter-active': isColumnsFilterActive }" @click.prevent="columnsFilterShowModal">
        <img src="/img/baseline_view_week_white_48dp.png" :alt="$t('columns')" :title="$t('columns')" class="btn__img btn__img--small">{{ $t('columns') }}
      </button>
      <button v-if="$parent.canUse('campaign.create')" class="btn btn--new" @click.prevent="createCampaign" :disabled="!filteredLineItems || filteredLineItems.length !== 1">
        <img src="/img/baseline_add_white_48dp.png" :alt="$t('create')" :title="$t('create')" class="btn__img btn__img--small">{{ $t('new-campaign') }}
      </button>
      <!-- FIELDS-SELECTOR-MODAL -->
      <fields-selector
        id="fields-filter-selector"
        v-if="isFieldsSelectorVisible"
        :fieldsFilter="fieldsFilter"
        :fieldsMeta="fieldsMeta"
        v-on:on-fields-filter-hide-modal="onFieldsFilterHideModal"
        v-on:on-fields-filter-change="onFieldsFilterChange"></fields-selector>
      <!-- COLUMNS-SELECTOR-MODAL -->
      <columns-selector
        id="columns-filter-selector"
        v-if="isColumnsSelectorVisible"
        :columnsFilter="columnsFilter"
        :columnsMeta="columnsMeta"
        v-on:on-columns-filter-hide-modal="onColumnsFilterHideModal"
        v-on:on-columns-filter-change="onColumnsFilterChange"></columns-selector>
    </div>
    <div class="card campaigns-card">
      <div class="card__content">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('campaigns') }}</h4>
          </div>
          <div class="card__header-actions">
            <button v-if="$parent.canUse('campaign.update')" class="btn" @click.prevent="viewCampaignSummary" :disabled="!filteredCampaigns || filteredCampaigns.length !== 1 || !isFacebookCampaign()">
              <img src="/img/baseline_summarize_white_48dp.png" :alt="$t('summary')" :title="$t('summary')" class="btn__img">{{ $t('summary') }}
            </button>
            <button v-if="$parent.canUse('report.create')" class="btn" @click.prevent="createCampaignReport" :disabled="isCreateReportDisabled">
              <img src="/img/baseline_assessment_white_48dp.png" :alt="$t('report')" :title="$t('report')" class="btn__img">{{ $t('report') }}
            </button>
            <button v-if="$parent.canUse('campaign.delete')" class="btn" @click.prevent="deleteCampaign" :disabled="!filteredCampaigns || filteredCampaigns.length !== 1">
              <img src="/img/baseline_delete_white_48dp.png" :alt="$t('delete')" :title="$t('delete')" class="btn__img">{{ $t('delete') }}
            </button>
          </div>
        </div>
        <div class="card__header" v-if="filteredCampaignsTags.length > 0">
          <div class="card__header-text">
            <a v-for="(data, index) in filteredCampaignsTags" :key="index" href="#" class="tag tag--with-margin tag--collapsible" :title="$t('clear')" @click.prevent="clearCampaignTag(data)">
              {{ data.label }}
              <img class="btn__img btn__img--small --clear-cursor" src="/img/baseline_cancel_white_48dp.png" :alt="$t('clear')">
            </a>
          </div>
        </div>
        <div class="scrollable-cols">
          <table class="card__table">
            <colgroup>
              <col class="--w50px">
              <col :class="[ showFullWidthName ? '--w600px' : '--w300px' ]">
              <col class="--w150px"><!-- platform -->
              <col class="--w150px"><!-- status -->
              <template v-for="(columnId, columnIndex) in columnsFilter">
                <col :class="getColumnsMeta(columnId).class" :key="columnIndex">
              </template>
              <col class="--w100px">
            </colgroup>
            <thead>
              <tr>
                <th class="sticky --left-1">&nbsp;</th>
                <th class="sticky --left-2">
                  {{ $t('name') }}
                  <button class="btn btn--square--small" @click="changeTableOrder('name')">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'name' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button>
                  <button class="btn btn--square--small btn--sticky-right" @click="showFullWidthName = !showFullWidthName">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': showFullWidthName }" src="/img/baseline_swap_horiz_white_48dp.png" :alt="$t('expand')">
                  </button>
                </th>
                <th class="--ta-c">{{ $t('platforms') }}</th>
                <th class="--ta-r">
                  {{ $t('status') }}
                  <button class="btn btn--square--small" @click="changeTableOrder('status')">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'status' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button>
                </th>
                <template v-for="(columnId, columnIndex) in columnsFilter">
                  <th class="--ta-r" :key="columnIndex">
                    {{ getColumnsMeta(columnId).label }}
                    <button class="btn btn--square--small" @click="changeTableOrder(columnId)" v-if="getColumnsMeta(columnId).sortable">
                      <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === columnId }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                    </button>
                  </th>
                </template>
                <th class="sticky --right">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(campaign, campaignIndex) in orderedTableItems">
                <tr :key="campaign._id" v-if="isRowFiltered(campaign)" v-show="isRowVisible(campaignIndex)" :class="{ 'is-selected': filteredCampaigns.includes(campaign._id), 'is-fav': isFavTableItem(campaign._id) }" @dblclick="onTableRowDblClick(campaign._id)">
                  <td class="sticky --left-1">
                    <input type="checkbox" class="input__checkbox" :class="[ filteredCampaigns.includes(campaign._id) ? 'input__checkbox--checked' : '' ]" :id="`campaign-id-${campaign._id}`" :name="`campaign-id-${campaign._id}`" :value="campaign._id" :checked="filteredCampaigns.includes(campaign._id)" @change="onFilteredCampaignsChange">
                    <label class="input__label input__label--checkbox input__label--only--checkbox" :for="`campaign-id-${campaign._id}`"></label>
                  </td>
                  <td class="sticky --left-2" :title="campaign.name">{{ campaign.name }}</td>
                  <td class="--ta-c"><img class="table-cell-icon" :src="$parent.translatePlatformToIcon(campaign.platform)"></td>
                  <td class="--ta-r">
                    {{ $t(`status-${campaign.status}`) }}
                    <button class="btn btn--table-actions btn--round--small btn--with-hover btn--with-menu" :title="$t('actions')">
                      <img class="btn__img btn__img--small btn__img--action" src="/img/baseline_arrow_drop_down_white_48dp.png">
                      <ol class="btn__menu">
                        <li class="btn__menu__item" v-if="campaign.status && campaign.status.toUpperCase() === 'ACTIVE'">
                          <a class="btn__menu__item__link" :title="$t('pause')" @click.prevent="pauseCampaign({ id: campaign._id })">
                            <img class="btn__menu__item__link_icon" src="/img/baseline_pause_white_48dp.png" />{{ $t('pause') }}
                          </a>
                        </li>
                        <li class="btn__menu__item" v-else>
                          <a class="btn__menu__item__link" :title="$t('play')" @click.prevent="playCampaign({ id: campaign._id })">
                            <img class="btn__menu__item__link_icon" src="/img/baseline_play_arrow_white_48dp.png" />{{ $t('activate') }}
                          </a>
                        </li>
                        <li class="btn__menu__item">
                          <a class="btn__menu__item__link" :title="$t('synchronize')" @click.prevent="refreshCampaign({ id: campaign._id })">
                            <img class="btn__menu__item__link_icon" src="/img/baseline_autorenew_white_48dp.png" />{{ $t('synchronize') }}
                          </a>
                        </li>
                      </ol>
                    </button>
                  </td>
                  <template v-for="(columnId, columnIndex) in columnsFilter">
                    <td class="--ta-r" :key="columnIndex" :title="getColumnsMeta(columnId).format === '' ? $parent.format({ value: campaign[columnId], format: getColumnsMeta(columnId).format }) : false">{{ $parent.format({ value: campaign[columnId], format: getColumnsMeta(columnId).format }) }}</td>
                  </template>
                  <td class="--ta-r sticky --right">
                    <div class="table-cell-actions">
                      <!-- SUMMARY: SOLO FACEBOOK -->
                      <!-- <button v-if="$parent.canUse('campaign.update') && campaign.platform === 1" class="btn btn--hover" :title="$t('summary')" @click.prevent="viewSummary(campaign)">
                        <img class="btn__img" src="/img/baseline_summarize_white_48dp.png">{{ $t('summary') }}
                      </button> -->
                      <!-- EDICION: SOLO FACEBOOK -->
                      <button v-if="$parent.canUse('campaign.update') && campaign.platform === 1" class="btn btn--hover" :title="$t('edit')" @click.prevent="editCampaign(campaign)">
                        <img class="btn__img" src="/img/baseline_edit_white_48dp.png">{{ $t('edit') }}
                      </button>
                      <!-- <button v-if="$parent.canUse('campaign.update')" class="btn btn--hover" :title="$t('optimize')" @click.prevent="optimizeCampaign(campaign)">
                        <img class="btn__img" src="/img/baseline_trending_up_white_48dp.png">{{ $t('optimize') }}
                      </button> -->
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="pagination.total > pagination.perPage" class="card__actions">
        <div class="card__actions--left">&nbsp;</div>
        <div class="card__actions--right">
          <div class="tfoot__rows-page"
            v-for="(page, index) in new Array(pagination.totalPages)"
            :key="index"
            :class="{ 'tfoot__current-page': pagination.currentPage - 1 === index }"
            @click="setCurrentPage(index + 1)">
            {{ 1 + (index * pagination.perPage) }} - {{ Math.min((index + 1) * pagination.perPage, pagination.total) }}</div>
          <div class="tfoot__total-rows">&nbsp;{{ $t('of') }}&nbsp;{{ pagination.total }}&nbsp;</div>
          <div class="tfoot__page-actions">
            <button class="btn btn--square--small" :disabled="pagination.currentPage === 1" @click="setCurrentPage(pagination.currentPage - 1)">
              <img class="btn__img" src="/img/baseline_chevron_left_white_48dp.png" :alt="$t('prev')">
            </button>
            <button class="btn btn--square--small" :disabled="pagination.currentPage === pagination.totalPages" @click="setCurrentPage(pagination.currentPage + 1)">
              <img class="btn__img" src="/img/baseline_chevron_right_white_48dp.png" :alt="$t('next')">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FieldsSelector from '@/core/components/FieldsSelector.vue'
import ColumnsSelector from '@/core/components/ColumnsSelector.vue'
export default {
  name: 'campaigns',
  props: [
    'campaigns',
    'filteredLineItems',
    'filteredCampaigns'
  ],
  components: {
    FieldsSelector,
    ColumnsSelector
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        perPage: 40,
        totalPages: 1,
        total: 0
      },
      orderField: 'none',
      orderedTableItems: [],
      favTableItems: '',
      showFullWidthName: false,
      fieldsFilter: [],
      isFieldsSelectorVisible: false,
      isFieldsFilterActive: false,
      fieldsMeta: [
        {
          id: 'platform',
          label: this.$t('platform'),
          values: [
            { id: 'platform-1', label: 'Facebook' },
            { id: 'platform-2', label: 'Twitter' }
          ]
        },
        {
          id: 'objective',
          label: this.$t('objective'),
          values: [
            { id: 'objective-LINK_CLICKS', label: this.$t('objective-link-clicks') },
            { id: 'objective-IMPRESSIONS', label: this.$t('objective-impressions') },
            { id: 'objective-REACH', label: this.$t('objective-reach') },
            { id: 'objective-PURCHASES', label: this.$t('objective-purchases') },
            { id: 'objective-APP_INSTALLS', label: this.$t('objective-app-installs') },
            { id: 'objective-CONVERSIONS', label: this.$t('objective-conversions') },
            { id: 'objective-LEAD_GENERATION', label: this.$t('objective-lead-generation') },
            { id: 'objective-VIDEO_VIEWS', label: this.$t('objective-video-views') },
            { id: 'objective-THRUPLAY', label: this.$t('objective-thruplay-views') },
            { id: 'objective-PAGE_ENGAGEMENT', label: this.$t('objective-page-engagement') },
            { id: 'objective-PAGE_LIKES', label: this.$t('objective-page-likes') },
            { id: 'objective-POST_ENGAGEMENT', label: this.$t('objective-engagement') }
          ]
        },
        {
          id: 'status',
          label: this.$t('status'),
          values: [
            { id: 'status-ACTIVE', label: this.$t('status-active') },
            { id: 'status-PAUSED', label: this.$t('status-paused') },
            { id: 'status-ARCHIVED', label: this.$t('status-archived') },
            { id: 'status-PROGRAMMED', label: this.$t('status-programmed') },
            { id: 'status-BILLED', label: this.$t('status-billed') },
            { id: 'status-FINALIZED', label: this.$t('status-finalized') }
          ]
        }
      ],
      columnsFilter: [],
      isColumnsSelectorVisible: false,
      isColumnsFilterActive: false,
      columnsMeta: [
        { id: 'bid_strategy', label: this.$t('bid-strategy'), format: '', sortable: true, class: '--w150px' },
        { id: 'objective', label: this.$t('objective'), format: '', sortable: true, class: '--w150px' },
        { id: 'budget', label: this.$t('budget'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'results', label: this.$t('results'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'eCPR', label: this.$t('eCPR'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'selling_cost', label: this.$t('selling-cost'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'spend', label: this.$t('spend'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'margin', label: this.$t('margin'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'impressions', label: this.$t('impressions'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'cv_link_clicks', label: this.$t('clicks'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'ctr', label: this.$t('ctr'), format: 'decimal', sortable: true, class: '--w150px' },
        { id: 'result_rate', label: this.$t('result-rate'), format: 'decimal', sortable: true, class: '--w150px' },
        { id: 'reach', label: this.$t('reach'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'frequency', label: this.$t('frequency'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'start_date', label: this.$t('start-date'), format: 'date', sortable: true, class: '--w150px' },
        { id: 'end_date', label: this.$t('end-date'), format: 'date', sortable: true, class: '--w150px' }
      ]
    }
  },
  computed: {
    isCreateReportDisabled () {
      let returnValue = false
      if (this.campaigns.length === 0) {
        returnValue = true
      } else {
        if (!this.filteredCampaigns || this.filteredCampaigns.length === 0) {
          returnValue = true
        } else {
          let objective = ''
          let platform = 0
          for (let filteredCampaignId of this.filteredCampaigns) {
            let campaign = this.campaigns.find(campaign => campaign._id === filteredCampaignId)
            if (objective === '' && platform === 0) {
              objective = campaign.objective
              platform = campaign.platform
            } else if (objective !== campaign.objective || platform !== campaign.platform) {
              returnValue = true
              break
            }
          }
        }
      }

      return returnValue
    },
    filteredCampaignsTags () {
      return this.campaigns && this.campaigns.length > 0 && this.filteredCampaigns && this.filteredCampaigns.length > 0 ? this.filteredCampaigns.map(campaignId => {
        let campaign = this.campaigns.find(campaign => campaign._id === campaignId)
        return (campaign) ? {
          value: campaignId,
          label: campaign.name
        } : false
      }) : []
    }
  },
  mounted () {
    this.orderField = window.localStorage.getItem('orderField_campaigns') || 'none'
    this.favTableItems = window.localStorage.getItem('fav_campaigns') || ''
    this.fieldsFilter = window.localStorage.getItem('fieldsFilter_campaigns') ? window.localStorage.getItem('fieldsFilter_campaigns').split(',') : []
    this.columnsFilter = window.localStorage.getItem('columnsFilter_campaigns') ? window.localStorage.getItem('columnsFilter_campaigns').split(',') : this.columnsMeta.map(item => item.id)
  },
  watch: {
    orderField (newValue, oldValue) {
      window.localStorage.setItem('orderField_campaigns', newValue)
    },
    favTableItems (newValue, oldValue) {
      window.localStorage.setItem('fav_campaigns', newValue)
    },
    fieldsFilter (newValue, oldValue) {
      window.localStorage.setItem('fieldsFilter_campaigns', newValue.join(','))
      this.isFieldsFilterActive = true
    },
    columnsFilter (newValue, oldValue) {
      window.localStorage.setItem('columnsFilter_campaigns', newValue.join(','))
      this.isColumnsFilterActive = true
    },
    campaigns (newValue, oldValue) {
      this.pagination.total = newValue.length
      this.pagination.totalPages = Math.ceil(newValue.length / this.pagination.perPage)
      this.pagination.currentPage = 1
      this.orderedTableItems = this.orderTableByField(newValue)
    }
  },
  methods: {
    createCampaign () {
      this.$emit('on-create-campaign')
    },
    editCampaign (campaign) {
      this.$emit('on-edit-campaign', { campaign })
    },
    deleteCampaign () {
      this.$emit('on-delete-campaign', { campaignId: this.filteredCampaigns[0] })
    },
    optimizeCampaign (campaign) {
      this.$emit('on-optimize-campaign', { campaign })
    },
    createCampaignReport () {
      // this.$emit('on-create-campaign-report', { campaignId: this.filteredCampaigns[0] })
      this.$emit('on-create-campaign-report', { entityIds: this.filteredCampaigns })
    },
    playCampaign ({ id }) {
      this.$emit('on-play-campaign', { id })
    },
    pauseCampaign ({ id }) {
      this.$emit('on-pause-campaign', { id })
    },
    refreshCampaign ({ id }) {
      this.$emit('on-refresh-campaign', { id })
    },
    isFacebookCampaign () {
      const campaign = this.orderedTableItems.find(campaign => campaign._id === this.filteredCampaigns[0])
      return this.filteredCampaigns[0] && campaign?.platform === 1
    },
    viewCampaignSummary () {
      this.$router.push({ path: `/campaign/${this.filteredCampaigns[0]}/summary`, query: this.$route.query })
    },
    favTableItem (teamId) {
      if (this.favTableItems.indexOf(teamId) < 0) {
        this.favTableItems = `${this.favTableItems},${teamId}`
      } else {
        this.favTableItems = this.favTableItems.replace(`,${teamId}`, '')
      }
    },
    isFavTableItem (teamId) {
      return this.favTableItems.indexOf(teamId) >= 0
    },
    setCurrentPage (page) {
      this.pagination.currentPage = page
    },
    isRowVisible (index) {
      return index >= (this.pagination.currentPage - 1) * this.pagination.perPage && index < this.pagination.currentPage * this.pagination.perPage
    },
    orderTableByField (table) {
      const sortArray = function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      }
      const sortTextArray = function (a, b) {
        return a.localeCompare(b)
      }
      let sortedTable = (table) ? [...table] : []
      switch (this.orderField) {
        case 'name':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.name.toUpperCase(), b.name.toUpperCase()))
          break
        case 'role':
          sortedTable = sortedTable.sort((a, b) => sortArray(a.role.toUpperCase(), b.role.toUpperCase()))
          break
        case 'status':
          sortedTable = sortedTable.sort((a, b) => sortArray(a.status.toUpperCase(), b.status.toUpperCase()))
          break
        case 'bid_strategy':
          sortedTable = sortedTable.sort((a, b) => sortArray(a.bid_strategy.toUpperCase(), b.bid_strategy.toUpperCase()))
          break
        case 'start_date':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.start_date), new Date(b.start_date)))
          break
        case 'end_date':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.end_date), new Date(b.end_date)))
          break
        case 'none':
          break
        default:
          sortedTable = sortedTable.sort((a, b) => sortArray(Number(a[this.orderField]), Number(b[this.orderField])))
      }
      return sortedTable
    },
    changeTableOrder (field) {
      this.orderField = field
      this.orderedTableItems = this.orderTableByField(this.campaigns)
    },
    isRowFiltered (row) {
      const filters = this.fieldsFilter.map(item => item.split('-'))
      let allFiltersPassed = {}
      filters.forEach(filter => { allFiltersPassed[filter[0]] = false })
      filters.forEach(filter => {
        if (row[filter[0]] === filter[1] || row[filter[0]] === Number(filter[1])) {
          allFiltersPassed[filter[0]] = true
        }
      })
      return Object.keys(allFiltersPassed).map(key => allFiltersPassed[key]).every(value => value === true)
    },
    getFieldsMeta (fieldId) {
      return this.fieldsMeta.find(field => field.id === fieldId)
    },
    getColumnsMeta (columnId) {
      return this.columnsMeta.find(column => column.id === columnId)
    },
    fieldsFilterShowModal () {
      this.isFieldsSelectorVisible = true
    },
    columnsFilterShowModal () {
      this.isColumnsSelectorVisible = true
    },
    clearCampaignTag (data) {
      let filteredCampaigns = [...this.filteredCampaigns.filter(item => item !== data.value)]
      this.$emit('on-filtered-campaigns-change', { filteredCampaigns })
    },
    viewSummary (campaign) {
      this.$router.push({ path: `/campaign/${campaign._id}/summary`, query: this.$route.query })
    },
    // EVENT handlers
    onFilteredCampaignsChange (e) {
      let filteredCampaigns
      if (e.target.checked) {
        filteredCampaigns = [...this.filteredCampaigns, e.target.value]
      } else {
        filteredCampaigns = [...this.filteredCampaigns.filter(item => item !== e.target.value)]
      }
      this.$emit('on-filtered-campaigns-change', { filteredCampaigns })
    },
    onTableRowDblClick (campaignId) {
      let filteredCampaigns = [...this.filteredCampaigns]
      if (!filteredCampaigns.includes(campaignId)) {
        filteredCampaigns.push(campaignId)
      }
      this.$emit('on-filtered-campaigns-change', { filteredCampaigns })
      window.setTimeout(() => {
        this.$parent.clickAdsetsTab()
      })
    },
    onFieldsFilterHideModal () {
      this.isFieldsSelectorVisible = false
    },
    onFieldsFilterChange ({ fieldsFilter }) {
      this.fieldsFilter = [...fieldsFilter]
    },
    onColumnsFilterHideModal () {
      this.isColumnsSelectorVisible = false
    },
    onColumnsFilterChange ({ columnsFilter }) {
      this.columnsFilter = [...columnsFilter]
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
.tabs__tab-content-wrapper {
  grid-template-columns: 1fr;
  grid-template-rows: 66px 1fr;
  grid-column-gap: 20px;
}
#columns-filter-selector,
#fields-filter-selector {
  position: absolute;
  top: 150px;
  right: 50px;
}
#fields-filter-selector {
  right: 150px;
}
</style>
