<template>
  <div class="tabs__tab-content-wrapper">
    <div class="tabs__tab-content-actions">
      <button class="btn btn--filter" :class="{ '--filter-active': isFieldsFilterActive }" @click.prevent="fieldsFilterShowModal" v-show="false">
        <img src="/img/baseline_filter_list_white_48dp.png" :alt="$t('filter')" :title="$t('filter')" class="btn__img btn__img--small">{{ $t('filter') }}
      </button>
      <button class="btn btn--filter" :class="{ '--filter-active': isColumnsFilterActive }" @click.prevent="columnsFilterShowModal">
        <img src="/img/baseline_view_week_white_48dp.png" :alt="$t('columns')" :title="$t('columns')" class="btn__img btn__img--small">{{ $t('columns') }}
      </button>
      <button v-if="$parent.canUse('team.create')" class="btn btn--new" @click.prevent="createTeam">
        <img src="/img/baseline_add_white_48dp.png" :alt="$t('create')" :title="$t('create')" class="btn__img btn__img--small">{{ $t('new-agency') }}
      </button>
      <!-- FIELDS-SELECTOR-MODAL -->
      <fields-selector
        id="fields-filter-selector"
        v-if="isFieldsSelectorVisible"
        :fieldsFilter="fieldsFilter"
        :fieldsMeta="fieldsMeta"
        v-on:on-fields-filter-hide-modal="onFieldsFilterHideModal"
        v-on:on-fields-filter-change="onFieldsFilterChange"></fields-selector>
      <!-- COLUMNS-SELECTOR-MODAL -->
      <columns-selector
        id="columns-filter-selector"
        v-if="isColumnsSelectorVisible"
        :columnsFilter="columnsFilter"
        :columnsMeta="columnsMeta"
        v-on:on-columns-filter-hide-modal="onColumnsFilterHideModal"
        v-on:on-columns-filter-change="onColumnsFilterChange"></columns-selector>
    </div>
    <div class="card agencies-card">
      <div class="card__content">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('agencies') }}</h4>
          </div>
          <div class="card__header-actions">
            <!-- <button v-if="$parent.canUse('report.create')" class="btn" @click.prevent="createTeamReport" :disabled="!filteredAgencies || filteredAgencies.length !== 1">
              <img src="/img/baseline_assessment_white_48dp.png" :alt="$t('report')" :title="$t('report')" class="btn__img">{{ $t('report') }}
            </button> -->
            <button v-if="$parent.canUse('team.delete')" class="btn" @click.prevent="deleteTeam" :disabled="!filteredAgencies || filteredAgencies.length !== 1">
              <img src="/img/baseline_delete_white_48dp.png" :alt="$t('delete')" :title="$t('delete')" class="btn__img">{{ $t('delete') }}
            </button>
          </div>
        </div>
        <div class="card__header" v-if="filteredAgenciesTags.length > 0">
          <div class="card__header-text">
            <a v-for="(data, index) in filteredAgenciesTags" :key="index" href="#" class="tag tag--with-margin tag--collapsible" :title="$t('clear')" @click.prevent="clearAgencyTag(data)">
              {{ data.label }}
              <img class="btn__img btn__img--small --clear-cursor" src="/img/baseline_cancel_white_48dp.png" :alt="$t('clear')">
            </a>
          </div>
        </div>
        <div class="scrollable-cols">
        <table class="card__table">
          <colgroup>
            <col class="--w50px">
            <col>
            <template v-for="(columnId, columnIndex) in columnsFilter">
              <col :class="getColumnsMeta(columnId).class" :key="columnIndex">
            </template>
            <col class="--w100px">
          </colgroup>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>
                {{ $t('name') }}
                <button class="btn btn--square--small" @click="changeTableOrder('name')">
                  <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'name' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                </button>
              </th>
              <template v-for="(columnId, columnIndex) in columnsFilter">
                <th class="--ta-r" :key="columnIndex">
                  {{ getColumnsMeta(columnId).label }}
                  <button class="btn btn--square--small" @click="changeTableOrder(columnId)" v-if="getColumnsMeta(columnId).sortable">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === columnId }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button>
                </th>
              </template>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(team, index) in orderedTableItems">
            <tr :key="team._id" v-show="isRowVisible(index)" :class="{ 'is-selected': filteredAgencies.includes(team._id), 'is-fav': isFavTableItem(team._id) }" @dblclick="onTableRowDblClick(team._id)">
              <td>
                <input type="checkbox" class="input__checkbox" :class="[ filteredAgencies.includes(team._id) ? 'input__checkbox--checked' : '' ]" :id="`team-id-${team._id}`" :name="`team-id-${team._id}`" :value="team._id" :checked="filteredAgencies.includes(team._id)" @change="onFilteredAgenciesChange">
                <label class="input__label input__label--checkbox input__label--only--checkbox" :for="`team-id-${team._id}`"></label>
              </td>
              <td :title="team.name">{{ team.name }}</td>
              <template v-for="(columnId, columnIndex) in columnsFilter">
                <td class="--ta-r" :key="columnIndex">{{ $parent.format({ value: team[columnId], format: getColumnsMeta(columnId).format }) }}</td>
              </template>
              <td class="--ta-r">
                <div class="table-cell-actions">
                  <button v-if="$parent.canUse('team.update')" class="btn btn--hover" :title="$t('edit')" @click.prevent="editTeam(team)">
                    <img class="btn__img" src="/img/baseline_edit_white_48dp.png">{{ $t('edit') }}
                  </button>
                </div>
              </td>
            </tr>
            </template>
          </tbody>
        </table>
        </div>
      </div>
      <div v-if="pagination.total > pagination.perPage" class="card__actions">
        <div class="card__actions--left">&nbsp;</div>
        <div class="card__actions--right">
          <div class="tfoot__rows-page"
            v-for="(page, index) in new Array(pagination.totalPages)"
            :key="index"
            :class="{ 'tfoot__current-page': pagination.currentPage - 1 === index }"
            @click="setCurrentPage(index + 1)">
            {{ 1 + (index * pagination.perPage) }} - {{ Math.min((index + 1) * pagination.perPage, pagination.total) }}</div>
          <div class="tfoot__total-rows">&nbsp;{{ $t('of') }}&nbsp;{{ pagination.total }}&nbsp;</div>
          <div class="tfoot__page-actions">
            <button class="btn btn--square--small" :disabled="pagination.currentPage === 1" @click="setCurrentPage(pagination.currentPage - 1)">
              <img class="btn__img" src="/img/baseline_chevron_left_white_48dp.png" :alt="$t('prev')">
            </button>
            <button class="btn btn--square--small" :disabled="pagination.currentPage === pagination.totalPages" @click="setCurrentPage(pagination.currentPage + 1)">
              <img class="btn__img" src="/img/baseline_chevron_right_white_48dp.png" :alt="$t('next')">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FieldsSelector from '@/core/components/FieldsSelector.vue'
import ColumnsSelector from '@/core/components/ColumnsSelector.vue'
export default {
  name: 'agencies',
  props: [
    'teams',
    'filteredAgencies'
  ],
  components: {
    FieldsSelector,
    ColumnsSelector
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        perPage: 40,
        totalPages: 1,
        total: 0
      },
      orderField: 'none',
      orderedTableItems: [],
      favTableItems: '',
      fieldsFilter: [],
      isFieldsSelectorVisible: false,
      isFieldsFilterActive: false,
      fieldsMeta: [
        {
          id: 'platform',
          label: this.$t('platform'),
          values: [
            { id: 'platform-1', label: 'Facebook' },
            { id: 'platform-2', label: 'Twitter' }
          ]
        },
        {
          id: 'objective',
          label: this.$t('objective'),
          values: [
            { id: 'objective-LINK_CLICKS', label: this.$t('objective-link-clicks') },
            { id: 'objective-IMPRESSIONS', label: this.$t('objective-impressions') },
            { id: 'objective-REACH', label: this.$t('objective-reach') },
            { id: 'objective-PURCHASES', label: this.$t('objective-purchases') },
            { id: 'objective-APP_INSTALLS', label: this.$t('objective-app-installs') },
            { id: 'objective-CONVERSIONS', label: this.$t('objective-conversions') },
            { id: 'objective-LEAD_GENERATION', label: this.$t('objective-lead-generation') },
            { id: 'objective-VIDEO_VIEWS', label: this.$t('objective-video-views') },
            { id: 'objective-THRUPLAY_VIEWS', label: this.$t('objective-thruplay-views') },
            { id: 'objective-PAGE_ENGAGEMENT', label: this.$t('objective-page-engagement') },
            { id: 'objective-POST_ENGAGEMENT', label: this.$t('objective-engagement') }
          ]
        },
        {
          id: 'status',
          label: this.$t('status'),
          values: [
            { id: 'status-ACTIVE', label: this.$t('status-active') },
            { id: 'status-PAUSED', label: this.$t('status-paused') },
            { id: 'status-ARCHIVED', label: this.$t('status-archived') },
            { id: 'status-PROGRAMMED', label: this.$t('status-programmed') },
            { id: 'status-BILLED', label: this.$t('status-billed') }
          ]
        }
      ],
      columnsFilter: [],
      isColumnsSelectorVisible: false,
      isColumnsFilterActive: false,
      columnsMeta: [
        { id: 'ncampaigns', label: this.$t('campaigns'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'budget', label: this.$t('budget'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'selling_cost', label: this.$t('spend'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'spend', label: this.$t('buying-cost'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'margin', label: this.$t('margin'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'revenue', label: this.$t('revenue'), format: 'currency', sortable: true, class: '--w150px' }
      ]
    }
  },
  computed: {
    filteredAgenciesTags () {
      return this.teams && this.teams.length > 0 && this.filteredAgencies && this.filteredAgencies.length > 0 ? this.filteredAgencies.map(agencyId => {
        return {
          value: agencyId,
          label: this.teams.find(team => team._id === agencyId).name
        }
      }) : []
    }
  },
  mounted () {
    this.orderField = window.localStorage.getItem('orderField_agencies') || 'none'
    this.favTableItems = window.localStorage.getItem('fav_agencies') || ''
    this.fieldsFilter = window.localStorage.getItem('fieldsFilter_agencies') ? window.localStorage.getItem('fieldsFilter_agencies').split(',') : this.fieldsMeta.map(field => field.values.map(value => value.id)).flat()
    this.columnsFilter = window.localStorage.getItem('columnsFilter_agencies') ? window.localStorage.getItem('columnsFilter_agencies').split(',') : this.columnsMeta.map(item => item.id)
  },
  watch: {
    orderField (newValue, oldValue) {
      window.localStorage.setItem('orderField_agencies', newValue)
    },
    favTableItems (newValue, oldValue) {
      window.localStorage.setItem('fav_agencies', newValue)
    },
    fieldsFilter (newValue, oldValue) {
      window.localStorage.setItem('fieldsFilter_agencies', newValue.join(','))
      this.isFieldsFilterActive = true
    },
    columnsFilter (newValue, oldValue) {
      window.localStorage.setItem('columnsFilter_agencies', newValue.join(','))
      this.isColumnsFilterActive = true
    },
    teams (newValue, oldValue) {
      this.pagination.total = newValue.length
      this.pagination.totalPages = Math.ceil(newValue.length / this.pagination.perPage)
      this.pagination.currentPage = 1
      this.orderedTableItems = this.orderTableByField(newValue)
    }
  },
  methods: {
    createTeam () {
      this.$emit('on-create-team', { isAgency: true })
    },
    editTeam (team) {
      this.$emit('on-edit-team', { team, isAgency: true })
    },
    deleteTeam () {
      this.$emit('on-delete-team', { teamId: this.filteredAgencies[0] })
    },
    createTeamReport () {
      this.$emit('on-create-team-report', { teamId: 'TODO: CREATE AGENCY REPORT' })
    },
    favTableItem (teamId) {
      if (this.favTableItems.indexOf(teamId) < 0) {
        this.favTableItems = `${this.favTableItems},${teamId}`
      } else {
        this.favTableItems = this.favTableItems.replace(`,${teamId}`, '')
      }
    },
    isFavTableItem (teamId) {
      return this.favTableItems.indexOf(teamId) >= 0
    },
    setCurrentPage (page) {
      this.pagination.currentPage = page
    },
    isRowVisible (index) {
      return index >= (this.pagination.currentPage - 1) * this.pagination.perPage && index < this.pagination.currentPage * this.pagination.perPage
    },
    orderTableByField (table) {
      const sortArray = function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      }
      const sortTextArray = function (a, b) {
        return a.localeCompare(b)
      }
      let sortedTable = (table) ? [...table] : []
      switch (this.orderField) {
        case 'name':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.name.toUpperCase(), b.name.toUpperCase()))
          break
        case 'role':
          sortedTable = sortedTable.sort((a, b) => sortArray(a.role.toUpperCase(), b.role.toUpperCase()))
          break
        case 'status':
          sortedTable = sortedTable.sort((a, b) => sortArray(a.status.toUpperCase(), b.status.toUpperCase()))
          break
        case 'start_date':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.start_date), new Date(b.start_date)))
          break
        case 'end_date':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.end_date), new Date(b.end_date)))
          break
        case 'none':
          break
        default:
          sortedTable = sortedTable.sort((a, b) => sortArray(Number(a[this.orderField]), Number(b[this.orderField])))
      }
      return sortedTable
    },
    changeTableOrder (field) {
      this.orderField = field
      this.orderedTableItems = this.orderTableByField(this.teams)
    },
    isRowFiltered (row) {
      const filters = this.fieldsFilter.map(item => item.split('-'))
      let allFiltersPassed = {}
      filters.forEach(filter => { allFiltersPassed[filter[0]] = false })
      filters.forEach(filter => {
        if (row[filter[0]] === filter[1] || row[filter[0]] === Number(filter[1])) {
          allFiltersPassed[filter[0]] = true
        }
      })
      return Object.keys(allFiltersPassed).map(key => allFiltersPassed[key]).every(value => value === true)
    },
    getFieldsMeta (fieldId) {
      return this.fieldsMeta.find(field => field.id === fieldId)
    },
    getColumnsMeta (columnId) {
      return this.columnsMeta.find(column => column.id === columnId)
    },
    fieldsFilterShowModal () {
      this.isFieldsSelectorVisible = true
    },
    columnsFilterShowModal () {
      this.isColumnsSelectorVisible = true
    },
    clearAgencyTag (data) {
      let filteredAgencies = [...this.filteredAgencies.filter(item => item !== data.value)]
      this.$emit('on-filtered-agencies-change', { filteredAgencies })
    },
    // EVENT handlers
    onFilteredAgenciesChange (e) {
      let filteredAgencies
      if (e.target.checked) {
        filteredAgencies = [...this.filteredAgencies, e.target.value]
      } else {
        filteredAgencies = [...this.filteredAgencies.filter(item => item !== e.target.value)]
      }
      this.$emit('on-filtered-agencies-change', { filteredAgencies })
    },
    onTableRowDblClick (agencyId) {
      let filteredAgencies = [...this.filteredAgencies]
      if (!filteredAgencies.includes(agencyId)) {
        filteredAgencies.push(agencyId)
      }
      this.$emit('on-filtered-agencies-change', { filteredAgencies })
      window.setTimeout(() => {
        this.$parent.clickClientsTab()
      })
    },
    onFieldsFilterHideModal () {
      this.isFieldsSelectorVisible = false
    },
    onFieldsFilterChange ({ fieldsFilter }) {
      this.fieldsFilter = [...fieldsFilter]
    },
    onColumnsFilterHideModal () {
      this.isColumnsSelectorVisible = false
    },
    onColumnsFilterChange ({ columnsFilter }) {
      this.columnsFilter = [...columnsFilter]
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
.tabs__tab-content-wrapper {
  grid-template-columns: 1fr;
  grid-template-rows: 66px 1fr;
  grid-column-gap: 20px;
}
#columns-filter-selector,
#fields-filter-selector {
  position: absolute;
  top: 150px;
  right: 50px;
}
#fields-filter-selector {
  right: 150px;
}
</style>
