<template>
  <main id="dashboard-view">
    <div class="dashboard-header">
      <!-- TODO: BREADCRUMBS -->
      <div class="dashboard-header__breadcrumb">
        <router-link class="header__breadcrumb-link" :to="{ path: '/dashboard/agencies', query: $route.query }" :title="agencyBreadcrumb"
          v-if="agencyBreadcrumb !== ''">{{ agencyBreadcrumb }}</router-link>
        <router-link class="header__breadcrumb-link" :to="{ path: '/dashboard/advertisers', query: $route.query }" :title="clientBreadcrumb"
          v-if="clientBreadcrumb !== ''">{{ clientBreadcrumb }}</router-link>
        <router-link class="header__breadcrumb-link" :to="{ path: '/dashboard/planning', query: $route.query }" :title="planningBreadcrumb"
          v-if="planningBreadcrumb !== ''">{{ planningBreadcrumb }}</router-link>
        <router-link class="header__breadcrumb-link" :to="{ path: '/dashboard/campaigns', query: $route.query }" :title="campaignBreadcrumb"
          v-if="campaignBreadcrumb !== ''">{{ campaignBreadcrumb }}</router-link>
        <router-link class="header__breadcrumb-link" :to="{ path: '/dashboard/adsets', query: $route.query }" :title="adsetBreadcrumb"
          v-if="adsetBreadcrumb !== ''">{{ adsetBreadcrumb }}</router-link>
      </div>
      <form class="form dashboard-header__form" @submit.prevent>
        <input type="text" class="input__search input--white-bg" :placeholder="$t('search')" v-model="searchText" @change="onSearchTextChange" @keydown.enter.prevent="onSearchTextChange">
        <button v-if="searchText" id="btn-clear-text" class="btn btn--clear-text" @click.prevent="clearSearchText">
          <img src="/img/baseline_clear_white_48dp.png" :alt="$t('clear')" :title="$t('clear')" class="btn__img btn__img--small">
        </button>
        <!-- DATE-RANGE -->
        <input
          readonly
          type="text"
          id="date-filter-range"
          class="input__date input__date-range input--white-bg"
          v-model="dateFilter"
          :placeholder="$t('date')"
          @click="dateFilterShowModal">
        <!-- DATE-SELECTOR-MODAL -->
        <date-selector
          id="date-filter-selector"
          v-if="isDateSelectorVisible"
          :dateFilterFrom="dateFilterFrom"
          :dateFilterTo="dateFilterTo"
          v-on:on-date-filter-hide-modal="onDateFilterHideModal"
          v-on:on-date-filter-change="onDateFilterChange"></date-selector>
      </form>
    </div>
    <input v-if="canUse('team.show')" class="tabs__tab-state" type="radio" title="tab-agencies" name="tabs-state" id="tab-agencies" ref="tab-agencies" @click="showAgenciesTab" v-bind:checked="tabAgenciesChecked" />
    <input v-if="canUse('team.show')" class="tabs__tab-state" type="radio" title="tab-clients" name="tabs-state" id="tab-clients" ref="tab-clients" @click="showClientsTab" v-bind:checked="tabClientsChecked" v-bind:disabled="!filteredAgencies || filteredAgencies.length === 0" />
    <input v-if="canUse('order.show')" class="tabs__tab-state" type="radio" title="tab-planning" name="tabs-state" id="tab-planning" ref="tab-planning" @click="showPlanningTab" v-bind:checked="tabPlanningChecked" v-bind:disabled="!filteredClients || filteredClients.length === 0" />
    <input v-if="canUse('campaign.show')" class="tabs__tab-state" type="radio" title="tab-campaigns" name="tabs-state" id="tab-campaigns" ref="tab-campaigns" @click="showCampaignsTab" v-bind:checked="tabCampaignsChecked" v-bind:disabled="(!filteredOrders || filteredOrders.length === 0) && (!filteredLineItems || filteredLineItems.length === 0)" />
    <input v-if="canUse('adset.show')" class="tabs__tab-state" type="radio" title="tab-adsets" name="tabs-state" id="tab-adsets" ref="tab-adsets" @click="showAdsetsTab" v-bind:checked="tabAdsetsChecked" v-bind:disabled="!filteredCampaigns || filteredCampaigns.length !== 1" />
    <input v-if="canUse('ad.show')" class="tabs__tab-state" type="radio" title="tab-ads" name="tabs-state" id="tab-ads" ref="tab-ads" @click="showAdsTab" v-bind:checked="tabAdsChecked" v-bind:disabled="!filteredAdsets || filteredAdsets.length !== 1" />
    <div class="tabs">
      <label v-if="canUse('team.show')" for="tab-agencies" id="tab-agencies-label" class="tabs__tab-button" :class="{ 'tabs__tab-button--checked': tabAgenciesChecked }">{{ $t('agency') }}<span v-if="agencies && agencies.length > 0"> ({{ agencies.length }})</span></label>
      <label v-if="canUse('team.show')" for="tab-clients" id="tab-clients-label" class="tabs__tab-button" :class="{ 'tabs__tab-button--checked': tabClientsChecked, 'tabs__tab-button--disabled' : !filteredAgencies || filteredAgencies.length === 0, 'tabs__tab-button--enabled' : filteredAgencies && filteredAgencies.length > 0 }">{{ $t('advertiser') }}<span v-if="clients && clients.length > 0"> ({{ clients.length }})</span></label>
      <label v-if="canUse('order.show')" for="tab-planning" id="tab-planning-label" class="tabs__tab-button" :class="{ 'tabs__tab-button--checked': tabPlanningChecked, 'tabs__tab-button--disabled' : !filteredClients || filteredClients.length === 0, 'tabs__tab-button--enabled' : filteredClients && filteredClients.length > 0 }">{{ $t('planning') }}<span v-if="orders && orders.length > 0"> ({{ orders.length }})</span></label>
      <label v-if="canUse('campaign.show')" for="tab-campaigns" id="tab-campaigns-label" class="tabs__tab-button" :class="{ 'tabs__tab-button--checked': tabCampaignsChecked, 'tabs__tab-button--disabled' : (!filteredOrders || filteredOrders.length === 0) && (!filteredLineItems || filteredLineItems.length === 0), 'tabs__tab-button--enabled' : (filteredOrders && filteredOrders.length > 0) || (filteredLineItems && filteredLineItems.length > 0) }">{{ $t('campaigns') }}<span v-if="campaigns && campaigns.length > 0"> ({{ campaigns.length }})</span></label>
      <label v-if="canUse('adset.show')" for="tab-adsets" id="tab-adsets-label" class="tabs__tab-button" :class="{ 'tabs__tab-button--checked': tabAdsetsChecked, 'tabs__tab-button--disabled' : !filteredCampaigns || filteredCampaigns.length !== 1, 'tabs__tab-button--enabled' : filteredCampaigns && filteredCampaigns.length === 1 }">{{ $t('ad-sets') }}<span v-if="adsets && adsets.length > 0"> ({{ adsets.length }})</span></label>
      <label v-if="canUse('ad.show')" for="tab-ads" id="tab-ads-label" class="tabs__tab-button" :class="{ 'tabs__tab-button--checked': tabAdsChecked, 'tabs__tab-button--disabled' : !filteredAdsets || filteredAdsets.length !== 1, 'tabs__tab-button--enabled' : filteredAdsets && filteredAdsets.length === 1 }">{{ $t('ads') }}<span v-if="ads && ads.length > 0"> ({{ ads.length }})</span></label>
      <agencies
        v-if="canUse('team.show')"
        ref="content-agencies"
        class="tabs__tab-content"
        :class="{ 'tabs__tab-content--checked': tabAgenciesChecked }"
        id="tab-agencies-panel"
        :teams="agencies"
        :filteredAgencies="filteredAgencies"
        v-on:on-create-team="onCreateTeam"
        v-on:on-edit-team="onEditTeam"
        v-on:on-delete-team="onDeleteTeam"
        v-on:on-filtered-agencies-change="onFilteredAgenciesChange"></agencies>
      <clients
        v-if="canUse('team.show')"
        ref="content-clients"
        class="tabs__tab-content"
        :class="{ 'tabs__tab-content--checked': tabClientsChecked }"
        id="tab-clients-panel"
        :teams="clients"
        :filteredAgencies="filteredAgencies"
        :filteredClients="filteredClients"
        v-on:on-create-team="onCreateTeam"
        v-on:on-edit-team="onEditTeam"
        v-on:on-delete-team="onDeleteTeam"
        v-on:on-filtered-clients-change="onFilteredClientsChange"></clients>
      <planning
        v-if="canUse('order.show')"
        ref="content-planning"
        class="tabs__tab-content"
        :class="{ 'tabs__tab-content--checked': tabPlanningChecked }"
        id="tab-planning-panel"
        :orders="orders"
        :filteredClients="filteredClients"
        :filteredOrders="filteredOrders"
        :filteredLineItems="filteredLineItems"
        v-on:on-create-order="onCreateOrder"
        v-on:on-edit-order="onEditOrder"
        v-on:on-delete-order="onDeleteOrder"
        v-on:on-create-order-report="onCreateOrderReport"
        v-on:on-filtered-orders-change="onFilteredOrdersChange"
        v-on:on-create-line-item="onCreateLineItem"
        v-on:on-edit-line-item="onEditLineItem"
        v-on:on-delete-line-item="onDeleteLineItem"
        v-on:on-refresh-order="onRefreshOrder"
        v-on:on-refresh-line-item="onRefreshLineItem"
        v-on:on-create-line-item-report="onCreateLineItemReport"
        v-on:on-filtered-line-items-change="onFilteredLineItemsChange"></planning>
      <campaigns
        v-if="canUse('campaign.show')"
        ref="content-campaigns"
        class="tabs__tab-content"
        :class="{ 'tabs__tab-content--checked': tabCampaignsChecked }"
        id="tab-campaigns-panel"
        :campaigns="campaigns"
        :filteredLineItems="filteredLineItems"
        :filteredCampaigns="filteredCampaigns"
        v-on:on-create-campaign="onCreateCampaign"
        v-on:on-edit-campaign="onEditCampaign"
        v-on:on-delete-campaign="onDeleteCampaign"
        v-on:on-optimize-campaign="onOptimizeCampaign"
        v-on:on-play-campaign="onPlayCampaign"
        v-on:on-pause-campaign="onPauseCampaign"
        v-on:on-refresh-campaign="onRefreshCampaign"
        v-on:on-create-campaign-report="onCreateCampaignReport"
        v-on:on-filtered-campaigns-change="onFilteredCampaignsChange"></campaigns>
      <adsets
        v-if="canUse('adset.show')"
        ref="content-ads"
        class="tabs__tab-content"
        :class="{ 'tabs__tab-content--checked': tabAdsetsChecked }"
        id="tab-adsets-panel"
        :adsets="adsets"
        :filteredCampaigns="filteredCampaigns"
        :filteredAdsets="filteredAdsets"
        v-on:on-create-adset="onCreateAdset"
        v-on:on-edit-adset="onEditAdset"
        v-on:on-delete-adset="onDeleteAdset"
        v-on:on-optimize-adset="onOptimizeAdset"
        v-on:on-play-adset="onPlayAdset"
        v-on:on-pause-adset="onPauseAdset"
        v-on:on-refresh-adset="onRefreshAdset"
        v-on:on-create-adset-report="onCreateAdsetReport"
        v-on:on-filtered-adsets-change="onFilteredAdsetsChange"></adsets>
      <ads
        v-if="canUse('ad.show')"
        ref="content-ads"
        class="tabs__tab-content"
        :class="{ 'tabs__tab-content--checked': tabAdsChecked }"
        id="tab-ads-panel"
        :ads="ads"
        :filteredCampaigns="filteredCampaigns"
        :filteredAdsets="filteredAdsets"
        :filteredAds="filteredAds"
        v-on:on-create-ad="onCreateAd"
        v-on:on-edit-ad="onEditAd"
        v-on:on-delete-ad="onDeleteAd"
        v-on:on-optimize-ad="onOptimizeAd"
        v-on:on-play-ad="onPlayAd"
        v-on:on-pause-ad="onPauseAd"
        v-on:on-refresh-ad="onRefreshAd"
        v-on:on-create-ad-report="onCreateAdReport"
        v-on:on-filtered-ads-change="onFilteredAdsChange"></ads>
    </div>
  </main>
</template>
<script>
import Agencies from './components/Agencies.vue'
import Clients from './components/Clients.vue'
import DateSelector from '@/core/components/DateSelector.vue'
import Planning from './components/Planning.vue'
import Campaigns from './components/Campaigns.vue'
import Adsets from './components/Adsets.vue'
import Ads from './components/Ads.vue'
import {
  mapGetters,
  mapActions
} from 'vuex'
import {
  GET_USER_DATA,
  GET_ORDERS,
  GET_AGENCIES,
  GET_CLIENTS,
  GET_TEAMS,
  GET_CAMPAIGNS,
  GET_ADSETS,
  GET_ADS,
  GET_TEMPLATES,
  GET_ACCOUNTS,
  GET_OBJECTIVES,
  GET_PLATFORMS,
  GET_INSIGHTS,
  GET_STATUSES,
  STORE_FILTER_DATE_FROM,
  STORE_FILTER_DATE_TO,
  STORE_FILTER_SEARCH_TEXT,
  HIDE_MODAL_VEIL,
  SHOW_MODAL_VEIL,
  SHOW_MODAL,
  HIDE_MODAL,
  SAVE_NEW_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  STORE_FILTERED_AGENCIES,
  STORE_FILTERED_CLIENTS,
  SAVE_NEW_ORDER,
  UPDATE_ORDER,
  REFRESH_ORDER,
  DELETE_ORDER,
  STORE_FILTERED_ORDERS,
  SAVE_NEW_LINE_ITEM,
  UPDATE_LINE_ITEM,
  REFRESH_LINE_ITEM,
  DELETE_LINE_ITEM,
  STORE_FILTERED_LINE_ITEMS,
  SEARCH_CAMPAIGNS,
  SAVE_NEW_CAMPAIGN,
  UPDATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  REFRESH_CAMPAIGN,
  SAVE_NEW_ADSET,
  REFRESH_ADSET,
  UPDATE_ADSET,
  DELETE_ADSET,
  SAVE_NEW_AD,
  REFRESH_AD,
  UPDATE_AD,
  DELETE_AD,
  STORE_FILTERED_CAMPAIGNS,
  STORE_FILTERED_ADSETS,
  STORE_FILTERED_ADS,
  GET_BILLING_EVENTS,
  GET_OPTIMIZATION_GOALS,
  GET_GENDERS,
  GET_TARGETING_FACEBOOK,
  // GET_PAGES_FACEBOOK,
  GET_CREATIVE_FORMATS,
  GET_AD_CREATIVE,
  GET_COUNTRIES,
  GET_SPECIAL_AD_CATEGORIES,
  GET_BID_STRATEGIES,
  GET_REPORT,
  HIDE_REPORT,
  SHOW_NOTIFICATION
  // SEARCH_ORDERS,
  // SEARCH_CAMPAIGNS
} from '@/core/action-types'
import i18n from '@/plugins/i18n'
export default {
  name: 'dashboard',
  components: {
    DateSelector,
    Agencies,
    Clients,
    Planning,
    Campaigns,
    Adsets,
    Ads
  },
  data: function () {
    return {
      locale: i18n.locale,
      // filteredCampaignAds: null,
      searchText: '',
      isDateSelectorVisible: false,
      newAgencyModalForm: {
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'country',
            type: 'select',
            label: `${this.$t('country')} *`,
            title: this.$t('country'),
            dataList: [],
            value: 'ES',
            required: true,
            class: '--w50'
          },
          {
            name: 'vat',
            type: 'text',
            label: this.$t('vat-number'),
            title: this.$t('vat-number'),
            value: '',
            required: false,
            maxlength: 50,
            pattern: null,
            class: '--w50'
          },
          {
            name: 'billing_email',
            type: 'email',
            label: this.$t('billing-email'),
            title: this.$t('billing-email'),
            value: '',
            required: false,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'legal_name',
            type: 'text',
            label: `${this.$t('legal-name')} *`,
            title: this.$t('legal-name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'address',
            type: 'text',
            label: `${this.$t('address')} *`,
            title: this.$t('address'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'city',
            type: 'text',
            label: `${this.$t('city')} *`,
            title: this.$t('city'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w75'
          },
          {
            name: 'zip',
            type: 'text',
            label: `${this.$t('zip')} *`,
            title: this.$t('zip'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w25'
          }
        ],
        hiddenFields: [
          {
            name: 'role',
            value: 'agency'
          },
          {
            name: 'team_id',
            value: ''
          }
        ],
        hideModal: this.hideModal,
        saveNewTeam: this.saveNewTeam,
        fetchAgencies: this.fetchAgencies,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        save: function (query) {
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('agency-created'), type: 'success', icon: 'success' })
            this.fetchAgencies({ team_id: this.filteredTeam })
          }
          query.onComplete = onComplete.bind(this)
          this.saveNewTeam(query)
          this.hideModal()
        }
      },
      editAgencyModalForm: {
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'country',
            type: 'select',
            label: `${this.$t('country')} *`,
            title: this.$t('country'),
            dataList: [],
            value: 'ES',
            required: true,
            class: '--w50'
          },
          {
            name: 'vat',
            type: 'text',
            label: this.$t('vat-number'),
            title: this.$t('vat-number'),
            value: '',
            required: false,
            maxlength: 50,
            pattern: null,
            class: '--w50'
          },
          {
            name: 'billing_email',
            type: 'email',
            label: this.$t('billing-email'),
            title: this.$t('billing-email'),
            value: '',
            required: false,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'legal_name',
            type: 'text',
            label: `${this.$t('legal-name')} *`,
            title: this.$t('legal-name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'address',
            type: 'text',
            label: `${this.$t('address')} *`,
            title: this.$t('address'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'city',
            type: 'text',
            label: `${this.$t('city')} *`,
            title: this.$t('city'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w75'
          },
          {
            name: 'zip',
            type: 'text',
            label: `${this.$t('zip')} *`,
            title: this.$t('zip'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w25'
          }
        ],
        hiddenFields: [
          {
            name: 'id',
            value: ''
          },
          {
            name: 'role',
            value: 'agency'
          }
        ],
        originalData: {},
        hideModal: this.hideModal,
        updateTeam: this.updateTeam,
        defaultSaveButtonName: this.$t('save'),
        fetchAgencies: this.fetchAgencies,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        save: function (query) {
          if (this.originalData.name === query.name) {
            delete query.name
          }
          if (this.originalData.role === query.role) {
            delete query.role
          }
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('agency-updated'), type: 'success', icon: 'success' })
            this.fetchAgencies({ team_id: this.filteredTeam })
          }
          query.onComplete = onComplete.bind(this)
          this.updateTeam(query)
          this.hideModal()
        },
        deleteTeam: this.deleteTeam,
        saveDelete: function (query) {
          // TODO: PROMPT UI!
          if (window.confirm(this.$t('sure-to-delete-agency'))) {
            const onComplete = function (responseData) {
              this.showNotification({ text: this.$t('agency-deleted'), type: 'success', icon: 'success' })
              this.fetchAgencies({ team_id: this.filteredTeam })
            }
            query.onComplete = onComplete.bind(this)
            this.deleteTeam(query)
            this.hideModal()
          }
        }
      },
      newClientModalForm: {
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'team_id',
            type: 'select',
            label: this.$t('agency'),
            title: this.$t('agency'),
            dataList: [],
            value: '',
            required: false,
            class: '--w100'
          },
          {
            name: 'facebook_account_ids',
            type: 'select',
            label: this.$t('facebook-accounts'),
            title: this.$t('facebook-accounts'),
            dataList: [],
            value: [],
            required: false,
            class: '--w100',
            multiple: true,
            inputClass: 'input__select--multiple--large'
          },
          {
            name: 'twitter_account_ids',
            type: 'select',
            label: this.$t('twitter-accounts'),
            title: this.$t('twitter-accounts'),
            dataList: [],
            value: [],
            required: false,
            class: '--w100',
            multiple: true,
            inputClass: 'input__select--multiple--large'
          }
        ],
        hiddenFields: [
          {
            name: 'role',
            value: 'client'
          }
        ],
        hideModal: this.hideModal,
        saveNewTeam: this.saveNewTeam,
        fetchAgencies: this.fetchAgencies,
        fetchClients: this.fetchClients,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        save: function (query) {
          // TODO: Eneko tiene que quitarlo de la API, de momento lo mockeo así:
          query.users = []
          query.accounts = []
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('advertiser-created'), type: 'success', icon: 'success' })
            this.fetchAgencies({ team_id: this.filteredTeam })
            this.fetchClients({ team_id: this.filteredAgencies.join(',') })
          }
          query.onComplete = onComplete.bind(this)
          this.saveNewTeam(query)
          this.hideModal()
        }
      },
      editClientModalForm: {
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'team_id',
            type: 'select',
            label: this.$t('agency'),
            title: this.$t('agency'),
            dataList: [],
            value: '',
            required: false,
            class: '--w100'
          },
          {
            name: 'facebook_account_ids',
            type: 'select',
            label: this.$t('facebook-accounts'),
            title: this.$t('facebook-accounts'),
            dataList: [],
            value: '',
            required: false,
            class: '--w100',
            multiple: true,
            inputClass: 'input__select--multiple--large'
          },
          {
            name: 'twitter_account_ids',
            type: 'select',
            label: this.$t('twitter-accounts'),
            title: this.$t('twitter-accounts'),
            dataList: [],
            value: '',
            required: false,
            class: '--w100',
            multiple: true,
            inputClass: 'input__select--multiple--large'
          }
        ],
        hiddenFields: [
          {
            name: 'id',
            value: ''
          },
          {
            name: 'role',
            value: 'client'
          }
        ],
        originalData: {},
        hideModal: this.hideModal,
        updateTeam: this.updateTeam,
        defaultSaveButtonName: this.$t('save'),
        fetchAgencies: this.fetchAgencies,
        fetchClients: this.fetchClients,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        save: function (query) {
          if (this.originalData.name === query.name) {
            delete query.name
          }
          if (this.originalData.role === query.role) {
            delete query.role
          }
          if (this.originalData.team_id === query.team_id) {
            delete query.team_id
          }
          // TODO: habría que hacer lo mismo con users, pero es un array así que no dará problemas
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('advertiser-updated'), type: 'success', icon: 'success' })
            this.fetchAgencies({ team_id: this.filteredTeam })
            this.fetchClients({ team_id: this.filteredAgencies.join(',') })
          }
          query.onComplete = onComplete.bind(this)
          this.updateTeam(query)
          this.hideModal()
        },
        deleteTeam: this.deleteTeam,
        saveDelete: function (query) {
          // TODO: PROMPT UI!
          if (window.confirm(this.$t('sure-to-delete-advertiser'))) {
            const onComplete = function (responseData) {
              this.showNotification({ text: this.$t('advertiser-deleted'), type: 'success', icon: 'success' })
              this.fetchAgencies({ team_id: this.filteredTeam })
              this.fetchClients({ team_id: this.filteredAgencies.join(',') })
            }
            query.onComplete = onComplete.bind(this)
            this.deleteTeam(query)
            this.hideModal()
          }
        }
      },
      newOrderModalForm: {
        steps: [
          { value: '1', label: this.$t('new-planning'), status: 'active' },
          { value: '2', label: this.$t('new-planning-line'), status: 'inactive' }
        ],
        fields: [
          {
            // formStep: '1',
            name: 'team_ids',
            type: 'select',
            label: `${this.$t('advertiser-name')} *`,
            title: this.$t('advertiser-name'),
            dataList: [],
            value: '',
            required: true,
            class: '--w100'
          },
          {
            // formStep: '1',
            name: 'name',
            type: 'text',
            label: `${this.$t('planning-name')} *`,
            title: this.$t('planning-name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            // formStep: '1',
            name: 'start_date',
            type: 'date',
            label: `${this.$t('from')} *`,
            title: this.$t('from'),
            value: '',
            required: true,
            maxlength: 10,
            pattern: null,
            class: '--w50'
          },
          {
            // formStep: '1',
            name: 'end_date',
            type: 'date',
            label: `${this.$t('to')} *`,
            title: this.$t('to'),
            value: '',
            required: true,
            maxlength: 10,
            pattern: null,
            class: '--w50'
          },
          {
            // formStep: '1',
            name: 'status',
            type: 'select',
            label: `${this.$t('status')} *`,
            title: this.$t('status'),
            dataList: [],
            value: 'PROGRAMMED',
            required: true,
            class: '--w50'
          }
        ],
        saveNewOrder: this.saveNewOrder,
        hideModal: this.hideModal,
        onFilteredOrdersChange: this.onFilteredOrdersChange,
        onCreateLineItem: this.onCreateLineItem,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        // that: this,
        defaultSaveButtonName: this.$t('continue'),
        save: function (query) {
          delete query.teams_selector
          delete query.teams_tags
          query.start_date = query.start_date.split('T')[0]
          query.end_date = query.end_date.split('T')[0]
          const onComplete = function (responseData) {
            let filteredOrders = this.filteredOrders !== undefined ? [...this.filteredOrders, responseData.data._id] : [responseData.data._id]
            this.onFilteredOrdersChange({ filteredOrders })
            this.showNotification({ text: this.$t('planning-created'), type: 'success', icon: 'success' })
            this.onCreateLineItem({ orderId: responseData.data._id })
          }
          query.onComplete = onComplete.bind(this)
          this.saveNewOrder(query)
          this.hideModal()
        }
      },
      editOrderModalForm: {
        fields: [
          {
            name: 'team_ids',
            type: 'select',
            label: `${this.$t('advertiser-name')} *`,
            title: this.$t('advertiser-name'),
            dataList: [],
            value: '',
            required: true,
            class: '--w100'
          },
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('planning-name')} *`,
            title: this.$t('planning-name'),
            value: '',
            required: true,
            maxlength: 50,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'start_date',
            type: 'date',
            label: `${this.$t('from')} *`,
            title: this.$t('from'),
            value: '',
            required: true,
            maxlength: 10,
            pattern: null,
            class: '--w50'
          },
          {
            name: 'end_date',
            type: 'date',
            label: `${this.$t('to')} *`,
            title: this.$t('to'),
            value: '',
            required: true,
            maxlength: 10,
            pattern: null,
            class: '--w50'
          },
          {
            name: 'status',
            type: 'select',
            label: `${this.$t('status')} *`,
            title: this.$t('status'),
            dataList: [],
            value: 'PROGRAMMED',
            required: true,
            class: '--w50'
          }
        ],
        hiddenFields: [
          {
            name: 'id',
            value: ''
          }
        ],
        originalData: {},
        hideModal: this.hideModal,
        updateOrder: this.updateOrder,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        defaultSaveButtonName: this.$t('save'),
        save: function (query) {
          query.start_date = query.start_date.split('T')[0]
          query.end_date = query.end_date.split('T')[0]
          if (this.originalData.name === query.name) {
            delete query.name
          }
          if (this.originalData.status === query.status) {
            delete query.status
          }
          if (this.originalData.start_date === query.start_date) {
            delete query.start_date
          }
          if (this.originalData.end_date === query.end_date) {
            delete query.end_date
          }
          if (this.originalData.team_ids === query.team_ids) {
            delete query.team_ids
          }
          // Add filtered teams and orders
          // if (this.filteredTeams && this.filteredTeams.length > 0) {
          //   query.teams = this.filteredTeams.join(',')
          // }
          // if (this.filteredOrders && this.filteredOrders.length > 0) {
          //   query.orders = this.filteredOrders.join(',')
          // }
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('planning-updated'), type: 'success', icon: 'success' })
          }
          query.onComplete = onComplete.bind(this)
          this.updateOrder(query)
          this.hideModal()
        },
        deleteOrder: this.deleteOrder,
        saveDelete: function (query) {
          // Add filtered teams and orders
          // if (this.filteredTeams && this.filteredTeams.length > 0) {
          //   query.teams = this.filteredTeams.join(',')
          // }
          // if (this.filteredOrders && this.filteredOrders.length > 0) {
          //   query.orders = this.filteredOrders.join(',')
          // }
          // TODO: PROMPT UI!
          if (window.confirm(this.$t('sure-to-delete-planning'))) {
            const onComplete = function (responseData) {
              this.showNotification({ text: this.$t('planning-deleted'), type: 'success', icon: 'success' })
            }
            query.onComplete = onComplete.bind(this)
            this.deleteOrder(query)
            this.hideModal()
          }
        }
      },
      newLineItemModalForm: {
        steps: [
          { value: '1', label: this.$t('new-planning'), status: 'completed' },
          { value: '2', label: this.$t('new-planning-line'), status: 'active' }
        ],
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 100,
            class: '--w100'
          },
          {
            name: 'start_date',
            type: 'date',
            label: `${this.$t('from')} *`,
            title: this.$t('from'),
            value: '',
            required: true,
            maxlength: 10,
            pattern: null,
            class: '--w50'
          },
          {
            name: 'end_date',
            type: 'date',
            label: `${this.$t('to')} *`,
            title: this.$t('to'),
            value: '',
            required: true,
            maxlength: 10,
            pattern: null,
            class: '--w50'
          },
          {
            name: 'status',
            type: 'select',
            label: `${this.$t('status')} *`,
            title: this.$t('status'),
            dataList: [],
            value: 'PROGRAMMED',
            required: true,
            class: '--w50'
          },
          {
            name: 'objective',
            type: 'select',
            label: `${this.$t('objective')} *`,
            title: this.$t('objective'),
            dataList: [],
            value: '',
            required: true,
            class: '--w50',
            onChange (data, form) {
              const targetResultsField = form.fields.find(field => field.name === 'target_results')
              const budgetField = form.fields.find(field => field.name === 'budget')
              const cprField = form.fields.find(field => field.name === 'cpr')
              // target_results = budget / cpr
              let result = budgetField.value / cprField.value
              if (data.value === 'IMPRESSIONS' || data.value === 'REACH') {
                cprField.label = `${this.$t('cpm')} *`
                result = result * 1000
              } else {
                cprField.label = `${this.$t('cpr')} *`
              }
              targetResultsField.value = isNaN(result) || result === Infinity ? 0 : Math.round(result)
            }
          },
          {
            name: 'cpr',
            type: 'number',
            step: 0.0001,
            min: 0.0001,
            label: `${this.$t('cpr')} (€) *`,
            title: this.$t('cpr'),
            value: '0',
            required: true,
            maxlength: 10,
            class: '--w50 --ta-r',
            onChange (data, form) {
              const targetResultsField = form.fields.find(field => field.name === 'target_results')
              const budgetField = form.fields.find(field => field.name === 'budget')
              const objectiveField = form.fields.find(field => field.name === 'objective')
              // target_results = budget / cpr
              let result = budgetField.value / data.value
              if (objectiveField.value === 'IMPRESSIONS' || objectiveField.value === 'REACH') {
                // target_results = budget / cpr * 1000
                result = result * 1000
              }
              targetResultsField.value = isNaN(result) || result === Infinity ? 0 : Math.round(result)
            }
          },
          {
            name: 'budget',
            type: 'number',
            step: 0.01,
            min: 0.01,
            label: `${this.$t('budget')} (€)*`,
            title: this.$t('budget'),
            value: '0',
            required: true,
            maxlength: 10,
            class: '--w50',
            onChange (data, form) {
              const targetResultsField = form.fields.find(field => field.name === 'target_results')
              const cprField = form.fields.find(field => field.name === 'cpr')
              const objectiveField = form.fields.find(field => field.name === 'objective')
              // target_results = budget / cpr
              let result = data.value / cprField.value
              if (objectiveField.value === 'IMPRESSIONS' || objectiveField.value === 'REACH') {
                // target_results = budget / cpr * 1000
                result = result * 1000
              }
              targetResultsField.value = isNaN(result) || result === Infinity ? 0 : Math.round(result)
            }
          },
          {
            name: 'target_results',
            type: 'number',
            step: 1,
            label: this.$t('target_results'),
            title: this.$t('target_results'),
            value: '0',
            required: true,
            readonly: true,
            class: '--w50'
          },
          {
            name: 'description',
            type: 'text',
            label: this.$t('description'),
            title: this.$t('name'),
            value: '',
            required: false,
            // maxlength: 150,
            class: '--w50'
          },
          {
            name: 'model',
            type: 'select',
            label: `${this.$t('model')} *`,
            title: this.$t('model'),
            dataList: [ { value: 'MARGINAL', label: this.$t('marginal') }, { value: 'TRANSPARENT', label: this.$t('transparent') } ],
            value: 'MARGINAL',
            required: true,
            class: '--w50',
            onChange (data, form) {
              const cprField = form.fields.find(field => field.name === 'initial_margin')
              if (data.value === 'MARGINAL') {
                cprField.label = this.$t('margin')
              } else {
                cprField.label = this.$t('fee')
              }
            }
          },
          {
            name: 'initial_margin',
            type: 'number',
            step: 0.01,
            label: `${this.$t('commercial-margin')}`,
            title: this.$t('margin'),
            value: null,
            required: false,
            maxlength: 10,
            class: '--w50 --percent-input'
          }
        ],
        data: {
          objectives: [],
          statuses: []
        },
        hiddenFields: [
          {
            name: 'order_id',
            value: ''
          },
          {
            name: 'campaign_ids',
            value: []
          }
        ],
        searchCampaigns: this.searchCampaigns,
        saveNewLineItem: this.saveNewLineItem,
        hideModal: this.hideModal,
        onCreateLineItem: this.onCreateLineItem,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        save (query) {
          if (!query.initial_margin || query.initial_margin === undefined) {
            delete query.initial_margin
          }
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('planning-line-created'), type: 'success', icon: 'success' })
          }
          query.onComplete = onComplete.bind(this)
          this.saveNewLineItem(query)
          this.hideModal()
        }
      },
      editLineItemModalForm: {
        modalClass: 'card__modal--large',
        tabs: [
          {
            id: 'planning',
            label: this.$t('planning'),
            classSuffix: '--one'
          },
          {
            id: 'campaigns',
            label: this.$t('campaigns'),
            classSuffix: '--two'
          }
        ],
        fields: [
          {
            tab: 'planning',
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 100,
            class: '--w100'
          },
          {
            tab: 'planning',
            name: 'start_date',
            type: 'date',
            label: `${this.$t('from')} *`,
            title: this.$t('from'),
            value: '',
            required: true,
            maxlength: 10,
            pattern: null,
            class: '--w50'
          },
          {
            tab: 'planning',
            name: 'end_date',
            type: 'date',
            label: `${this.$t('to')} *`,
            title: this.$t('to'),
            value: '',
            required: true,
            maxlength: 10,
            pattern: null,
            class: '--w50'
          },
          {
            tab: 'planning',
            name: 'status',
            type: 'select',
            label: `${this.$t('status')} *`,
            title: this.$t('status'),
            dataList: [],
            value: '',
            required: true,
            class: '--w50'
          },
          {
            tab: 'planning',
            name: 'objective',
            type: 'select',
            label: `${this.$t('objective')} *`,
            title: this.$t('objective'),
            dataList: [],
            value: 'PROGRAMMED',
            required: true,
            class: '--w50',
            onChange (data, form) {
              const targetResultsField = form.fields.find(field => field.name === 'target_results')
              const budgetField = form.fields.find(field => field.name === 'budget')
              const cprField = form.fields.find(field => field.name === 'cpr')
              // target_results = budget / cpr
              let result = budgetField.value / cprField.value
              if (data.value === 'IMPRESSIONS' || data.value === 'REACH') {
                cprField.label = `${this.$t('cpm')} *`
                result = result * 1000
              } else {
                cprField.label = `${this.$t('cpr')} *`
              }
              targetResultsField.value = isNaN(result) || result === Infinity ? 0 : Math.round(result)
            }
          },
          {
            tab: 'planning',
            name: 'cpr',
            type: 'number',
            step: 0.0001,
            min: 0.0001,
            label: `${this.$t('cpr')} (€) *`,
            title: this.$t('cpr'),
            value: '0',
            required: true,
            maxlength: 10,
            class: '--w50 --ta-r',
            onChange (data, form) {
              const targetResultsField = form.fields.find(field => field.name === 'target_results')
              const budgetField = form.fields.find(field => field.name === 'budget')
              const objectiveField = form.fields.find(field => field.name === 'objective')
              // target_results = budget / cpr
              let result = budgetField.value / data.value
              if (objectiveField.value === 'IMPRESSIONS' || objectiveField.value === 'REACH') {
                // target_results = budget / cpr * 1000
                result = result * 1000
              }
              targetResultsField.value = isNaN(result) || result === Infinity ? 0 : Math.round(result)
            }
          },
          {
            tab: 'planning',
            name: 'budget',
            type: 'number',
            step: 0.01,
            min: 0.01,
            label: `${this.$t('budget')} (€) *`,
            title: this.$t('budget'),
            value: '0',
            required: true,
            maxlength: 10,
            class: '--w50',
            onChange (data, form) {
              const targetResultsField = form.fields.find(field => field.name === 'target_results')
              const cprField = form.fields.find(field => field.name === 'cpr')
              const objectiveField = form.fields.find(field => field.name === 'objective')
              // target_results = budget * cpr
              let result = data.value / cprField.value
              if (objectiveField.value === 'IMPRESSIONS' || objectiveField.value === 'REACH') {
                // target_results = budget * cpr * 1000
                result = result * 1000
              }
              targetResultsField.value = isNaN(result) || result === Infinity ? 0 : Math.round(result)
            }
          },
          {
            tab: 'planning',
            name: 'target_results',
            type: 'number',
            step: 1,
            // label: `${this.$t('target_results')} *`,
            label: this.$t('target_results'),
            title: this.$t('target_results'),
            value: '0',
            required: true,
            readonly: true,
            // maxlength: 10,
            class: '--w50'
            // ,
            // onChange (data, form) {
            //   const cprField = form.fields.find(field => field.name === 'cpr')
            //   const budgetField = form.fields.find(field => field.name === 'budget')
            //   const objectiveField = form.fields.find(field => field.name === 'objective')
            //   let result
            //   if (objectiveField.value === 'IMPRESSIONS' || objectiveField.value === 'REACH') {
            //     // cpr = budget / target_results
            //     result = budgetField.value / data.value * 1000
            //     cprField.value = isNaN(result) || result === Infinity ? 0 : result
            //   } else {
            //     // cpr = budget / target_results
            //     result = budgetField.value / data.value
            //     cprField.value = isNaN(result) || result === Infinity ? 0 : result
            //   }
            // }
          },
          {
            tab: 'planning',
            name: 'description',
            type: 'text',
            label: this.$t('description'),
            title: this.$t('name'),
            value: '',
            required: false,
            // maxlength: 150,
            class: '--w50'
          },
          {
            tab: 'planning',
            name: 'model',
            type: 'select',
            label: `${this.$t('model')} *`,
            title: this.$t('model'),
            dataList: [ { value: 'MARGINAL', label: this.$t('marginal') }, { value: 'TRANSPARENT', label: this.$t('transparent') } ],
            value: 'MARGINAL',
            required: true,
            class: '--w50',
            onChange (data, form) {
              const marginField = form.fields.find(field => field.name === 'initial_margin')
              if (data.value === 'MARGINAL') {
                marginField.label = this.$t('commercial-margin')
              } else {
                marginField.label = this.$t('fee')
              }
            }
          },
          {
            tab: 'planning',
            name: 'initial_margin',
            type: 'number',
            step: 0.01,
            label: `${this.$t('commercial-margin')}`,
            title: this.$t('commercial-margin'),
            value: '',
            required: false,
            maxlength: 10,
            class: '--w50 --percent-input'
          },
          {
            tab: 'campaigns',
            name: 'search',
            type: 'text',
            label: `${this.$t('search')} ${this.$t('campaigns')}`,
            title: this.$t('search'),
            value: '',
            required: false,
            maxlength: 255,
            class: '--w100',
            onChange (data, form) {
              let query = {
                search: data.value
              }
              const onComplete = function (form, responseData) {
                this.$nextTick(function () {
                  form.fields.find(item => item.name === 'search_results').dataList = responseData.map(item => {
                    return { label: `${item.name} (${translateObjective(item)}, ${translateStatus(item)})`, value: item._id }
                  })
                  function translateObjective (item) {
                    const objectives = form.data.objectives
                    if (objectives.find(objective => objective.value === item.objective)) {
                      return objectives.find(objective => objective.value === item.objective).label
                    } else {
                      return item.objective
                    }
                  }
                  function translateStatus (item) {
                    const statuses = form.data.statuses
                    if (statuses.find(status => status.value === item.status)) {
                      return statuses.find(status => status.value === item.status).label
                    } else {
                      return item.status
                    }
                  }
                })
              }
              query.onComplete = onComplete.bind(this, form)
              const lineItemId = form.hiddenFields.find(field => field.name === 'id').value
              query.params = '?id=' + lineItemId
              if (data.value.length > 2) {
                form.searchCampaigns(query)
              }
            }
          },
          {
            tab: 'campaigns',
            name: 'search_results',
            type: 'select',
            title: this.$t('search-results'),
            dataList: [],
            multiple: true,
            value: [],
            required: false,
            class: '--w100',
            inputClass: 'input__select--multiple--large',
            onChange (data, form) {
              const selectorField = form.fields.find(item => item.name === 'search_results')
              const idsField = form.hiddenFields.find(item => item.name === 'campaign_ids')
              const tagsField = form.fields.find(item => item.name === 'campaigns_tags')
              data.value.forEach(value => {
                if (!idsField.value.includes(value)) {
                  idsField.value.push(value)
                  tagsField.value.push(selectorField.dataList.find(item => item.value === value))
                }
              })
            }
          },
          {
            tab: 'campaigns',
            name: 'campaigns_tags',
            type: 'taglist',
            value: [],
            class: '--w100',
            inputClass: 'tags-list--w100',
            required: false,
            onChange (data, form) {
              const idsField = form.hiddenFields.find(item => item.name === 'campaign_ids')
              const ids = data.value.map(item => item.value)
              idsField.value = [...ids]
            }
          }
        ],
        data: {
          objectives: [],
          statuses: []
        },
        hiddenFields: [
          {
            name: 'id',
            value: ''
          },
          {
            name: 'order_id',
            value: ''
          },
          {
            name: 'campaign_ids',
            value: []
          }
        ],
        originalData: {},
        searchCampaigns: this.searchCampaigns,
        updateLineItem: this.updateLineItem,
        hideModal: this.hideModal,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        defaultSaveButtonName: this.$t('save'),
        save (query) {
          delete query.search
          delete query.search_results
          delete query.campaigns_tags
          if (this.originalData.initial_margin === query.initial_margin) {
            delete query.initial_margin
          }
          if (this.originalData.name === query.name) {
            delete query.name
          }
          if (this.originalData.description === query.description) {
            delete query.description
          }
          if (this.originalData.end_date === query.end_date) {
            delete query.end_date
          }
          if (this.originalData.start_date === query.start_date) {
            delete query.start_date
          }
          if (this.originalData.model === query.model) {
            delete query.model
          }
          if (this.originalData.status === query.status) {
            delete query.status
          }
          if (this.originalData.objective === query.objective) {
            delete query.objective
          }
          if (this.originalData.cpr === query.cpr) {
            delete query.cpr
          }
          if (this.originalData.budget === query.budget) {
            delete query.budget
          }
          if (this.originalData.target_results === query.target_results) {
            delete query.target_results
          }
          if (this.originalData.initial_margin === query.initial_margin) {
            delete query.initial_margin
          }
          if (this.originalData.campaign_ids === query.campaign_ids) {
            delete query.campaign_ids
          }
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('planning-line-updated'), type: 'success', icon: 'success' })
          }
          query.onComplete = onComplete.bind(this)
          this.updateLineItem(query)
          this.hideModal()
        },
        deleteLineItem: this.deleteLineItem,
        saveDelete (query) {
          // TODO: PROMPT UI!
          if (window.confirm(this.$t('sure-to-delete-planning-line'))) {
            const onComplete = function (responseData) {
              this.showNotification({ text: this.$t('planning-line-deleted'), type: 'success', icon: 'success' })
            }
            query.onComplete = onComplete.bind(this)
            this.deleteLineItem(query)
            this.hideModal()
          }
        }
      },
      editCampaignModalForm: {
        modalClass: 'card__modal--large',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 255,
            pattern: null,
            class: '--w100'
          },
          // No se pueda cambiar de plataforma, solo se muestra el nombre
          {
            name: 'platform_name',
            type: 'text',
            label: this.$t('platform'),
            title: this.$t('platform'),
            value: '',
            required: false,
            disabled: true,
            class: '--w50'
          },
          // No se pueda cambiar de account, solo se muestra el nombre
          {
            name: 'account_name',
            type: 'text',
            label: this.$t('platform-account'),
            title: this.$t('platform-account'),
            value: '',
            required: false,
            disabled: true,
            class: '--w50'
          },
          // No se pueda cambiar de objetivo, solo se muestra el nombre
          {
            name: 'objective_name',
            type: 'text',
            label: this.$t('objective'),
            title: this.$t('objective'),
            value: '',
            required: false,
            disabled: true,
            class: '--w50'
          },
          {
            name: 'status',
            type: 'select',
            label: `${this.$t('status')} *`,
            title: this.$t('status'),
            dataList: [],
            value: 'PAUSED',
            required: true,
            class: '--w50'
          },
          {
            name: 'start_date',
            type: 'date',
            label: `${this.$t('from')} *`,
            title: this.$t('from'),
            value: '',
            required: true,
            maxlength: 10,
            class: '--w50'
          },
          {
            name: 'end_date',
            type: 'date',
            label: `${this.$t('to')} *`,
            title: this.$t('to'),
            value: '',
            required: true,
            maxlength: 10,
            class: '--w50'
          },
          {
            name: 'daily_budget',
            type: 'number',
            step: 0.01,
            label: `${this.$t('daily-budget')} (€)`,
            title: this.$t('daily-budget'),
            value: undefined,
            required: false,
            maxlength: 10,
            class: '--w50',
            invisible: true
          },
          {
            name: 'lifetime_budget',
            type: 'number',
            step: 0.01,
            label: `${this.$t('lifetime-budget')} (€)`,
            title: this.$t('lifetime-budget'),
            value: undefined,
            required: false,
            maxlength: 10,
            class: '--w50',
            invisible: true
          },
          {
            name: 'bid_strategy',
            type: 'select',
            label: `${this.$t('budget-strategy')} *`,
            title: this.$t('budget-strategy'),
            dataList: [],
            value: '',
            required: true,
            class: '--w50'
          }
        ],
        hiddenFields: [
          {
            name: 'id',
            value: ''
          }// ,
          // TODO: de momento se queda así, hasta que podamos añadir los adsets directamente
          // {
          //   name: 'adset_count',
          //   value: '0'
          // },
        ],
        originalData: {},
        updateCampaign: this.updateCampaign,
        hideModal: this.hideModal,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        defaultSaveButtonName: this.$t('save'),
        save (query) {
          delete query.platform
          delete query.platform_name
          delete query.account_name
          delete query.objective_name
          query.start_date = query.start_date.split('T')[0]
          query.end_date = query.end_date.split('T')[0]
          if (this.originalData.name === query.name) {
            delete query.name
          }
          if (this.originalData.start_date === query.start_date) {
            delete query.start_date
          }
          if (this.originalData.end_date === query.end_date) {
            delete query.end_date
          }
          if (this.originalData.daily_budget === query.daily_budget) {
            delete query.daily_budget
          }
          if (this.originalData.lifetime_budget === query.lifetime_budget) {
            delete query.lifetime_budget
          }
          if (this.originalData.status === query.status) {
            delete query.status
          }
          if (this.originalData.bid_strategy === query.bid_strategy) {
            delete query.bid_strategy
          }
          if (query.daily_budget !== undefined && (query.daily_budget === '' || query.daily_budget === '0.0')) {
            query.daily_budget = null
          }
          if (query.lifetime_budget !== undefined && (query.lifetime_budget === '' || query.lifetime_budget === '0.0')) {
            query.lifetime_budget = null
          }
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('campaign-updated'), type: 'success', icon: 'success' })
            // this.$parent.filteredLineItemsSearchText = query.name
            // this.$parent.clickCampaignsTab()
          }
          query.onComplete = onComplete.bind(this)
          // query.lineItemCampaignIds = this.data.lineItemCampaignIds
          this.updateCampaign(query)
          this.hideModal()
        },
        deleteCampaign: this.deleteCampaign,
        saveDelete (query) {
          // TODO: PROMPT UI!
          if (window.confirm(this.$t('sure-to-delete-campaign'))) {
            const onComplete = function (responseData) {
              this.showNotification({ text: this.$t('campaign-deleted'), type: 'success', icon: 'success' })
            }
            query.onComplete = onComplete.bind(this)
            this.deleteCampaign(query)
            this.hideModal()
          }
        }
      },
      editAdsetModalForm: {
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 255,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'status',
            type: 'select',
            label: `${this.$t('status')} *`,
            title: this.$t('status'),
            dataList: [],
            value: 'PAUSED',
            required: true,
            class: '--w25'
          },
          {
            name: 'end_date',
            type: 'datetime-local',
            label: `${this.$t('end-date')} *`,
            title: this.$t('to'),
            value: '',
            required: true,
            invisible: true,
            maxlength: 10,
            class: '--w50'
          },
          {
            name: 'billing_event',
            type: 'text',
            label: `${this.$t('billing-event')}`,
            title: this.$t('billing-event'),
            value: '',
            required: false,
            disabled: true,
            class: '--w50'
          },
          {
            name: 'optimization_goal',
            type: 'text',
            label: `${this.$t('optimization-goal')}`,
            title: this.$t('optimization-goal'),
            value: '',
            required: false,
            disabled: true,
            class: '--w50'
          },
          {
            name: 'lifetime_budget',
            type: 'number',
            step: 0.01,
            label: `${this.$t('total-budget')} (€) *`,
            title: this.$t('total-budget'),
            value: undefined,
            required: true,
            invisible: true,
            maxlength: 10,
            class: '--w50'
          },
          {
            name: 'daily_budget',
            type: 'number',
            step: 0.01,
            label: `${this.$t('daily-budget')} (€) *`,
            title: this.$t('daily-budget'),
            value: undefined,
            required: true,
            maxlength: 10,
            class: '--w50'
          },
          {
            name: 'bid_amount',
            type: 'number',
            step: 0.01,
            label: `${this.$t('bid-amount')} (€) *`,
            title: this.$t('bid-amount'),
            value: '0',
            required: true,
            maxlength: 10,
            class: '--w50'
          }
        ],
        hiddenFields: [
          {
            name: 'id',
            value: ''
          }
          // ,
          // {
          //   name: 'platform',
          //   value: ''
          // },
          // {
          //   name: 'account_id',
          //   value: ''
          // },
          // {
          //   name: 'campaign_id',
          //   value: ''
          // }
        ],
        originalData: {},
        updateAdset: this.updateAdset,
        hideModal: this.hideModal,
        showNotification: this.showNotification,
        $t: this.$t.bind(this),
        defaultSaveButtonName: this.$t('save'),
        save: function (query) {
          delete query.billing_event
          delete query.optimization_goal
          // query.end_date = query.end_date.split('T')[0]
          if (this.originalData.name === query.name) {
            delete query.name
          }
          if (this.originalData.end_date === query.end_date) {
            delete query.end_date
          }
          if (query.daily_budget === null || this.originalData.daily_budget === query.daily_budget) {
            delete query.daily_budget
          }
          if (query.lifetime_budget === null || this.originalData.lifetime_budget === query.lifetime_budget) {
            delete query.lifetime_budget
          }
          if (this.originalData.status === query.status) {
            delete query.status
          }
          if (this.originalData.bid_amount === query.bid_amount) {
            delete query.bid_amount
          }
          if (query.daily_budget !== undefined && (query.daily_budget === '' || query.daily_budget === '0.0')) {
            query.daily_budget = null
          }
          if (query.lifetime_budget !== undefined && (query.lifetime_budget === '' || query.lifetime_budget === '0.0')) {
            query.lifetime_budget = null
          }
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('adset-updated'), type: 'success', icon: 'success' })
          }
          query.onComplete = onComplete.bind(this)
          this.updateAdset(query)
          this.hideModal()
        }
      },
      newAdModalForm: {
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 255,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'title',
            type: 'text',
            label: `${this.$t('title')} *`,
            title: this.$t('title'),
            value: '',
            required: true,
            maxlength: 255,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'link',
            type: 'text',
            label: `${this.$t('link')} *`,
            title: this.$t('link'),
            value: '',
            required: true,
            maxlength: 255,
            pattern: null,
            class: '--w100'
          },
          // FILES !!!
          {
            name: 'text',
            type: 'textarea',
            label: `${this.$t('text')} *`,
            title: this.$t('text'),
            value: '',
            required: true,
            maxlength: 1000,
            pattern: null,
            class: '--w100'
          },
          {
            name: 'page',
            type: 'select',
            label: `${this.$t('page')} *`,
            title: this.$t('page'),
            dataList: [],
            value: '',
            required: true,
            class: '--w50'
          },
          {
            name: 'status',
            type: 'select',
            label: `${this.$t('status')} *`,
            title: this.$t('status'),
            dataList: [],
            value: 'PAUSED',
            required: true,
            class: '--w50'
          }
          // TODO: AÑADIR ESTOS CUANDO ENEKO LOS CONFIGURE
          // format: [SINGLE_IMAGE,CAROUSEL,VIDEO,ORGANIC_POST,LEAD,PAGE_ENGAGEMENT]
          // post_id: required if format=ORGANIC_POST String
          // questions: required if format=LEAD_AD Array
        ],
        hiddenFields: [
          {
            name: 'adset_id',
            value: ''
          },
          {
            name: 'platform',
            value: ''
          }
        ],
        saveNewAd: this.hideModal,
        hideModal: this.hideModal,
        save: function (query) {
          this.saveNewAd(query)
          this.hideModal()
        }
      },
      newReportForm: {
        modalClass: 'card__modal--large',
        fields: [
          {
            name: 'name',
            type: 'text',
            label: `${this.$t('name')} *`,
            title: this.$t('name'),
            value: '',
            required: true,
            maxlength: 255,
            minlength: 3,
            class: '--w50'
          },
          {
            name: 'start_date',
            type: 'date',
            label: `${this.$t('from')}`,
            title: this.$t('from'),
            value: '',
            required: false,
            maxlength: 10,
            pattern: null,
            class: '--w25'
          },
          {
            name: 'end_date',
            type: 'date',
            label: `${this.$t('to')}`,
            title: this.$t('to'),
            value: '',
            required: false,
            maxlength: 10,
            pattern: null,
            class: '--w25'
          },
          {
            name: 'template',
            type: 'select',
            label: `${this.$t('template')} *`,
            title: this.$t('template'),
            dataList: [],
            value: '',
            required: true,
            class: '--w100',
            onChange (data, form) {
              const template = form.data.templates.find(template => template._id === data.value)
              const segmentationIds = form.hiddenFields.find(item => item.name === 'segmentation_ids')
              segmentationIds.value = [form.data.segmentation.find(item => item.value === template.segmentation).value]
              const segmentationField = form.fields.find(item => item.name === 'segmentation_text')
              segmentationField.value = form.data.segmentation.find(item => item.value === template.segmentation).label
              let metricsIds = form.hiddenFields.find(item => item.name === 'metrics_ids')
              let metricsField = form.fields.find(item => item.name === 'metrics_text')
              metricsIds.value = [...template.metrics]
              metricsField.value = template.metrics.map(metric => {
                return form.data.metrics.find(item => item.value === metric)?.label
              }).join(', ')
              const summaryField = form.fields.find(item => item.name === 'summary_fields')
              if (template?.options?.summary_fields) {
                summaryField.value = ['summary_fields']
              }
              const formatField = form.fields.find(item => item.name === 'format')
              formatField.value = template?.options?.format
            }
          },
          {
            name: 'segmentation_text',
            type: 'text',
            label: `${this.$t('segmentation')}`,
            title: this.$t('segmentation'),
            value: '',
            required: false,
            disabled: true,
            class: '--w100'
          },
          {
            name: 'metrics_text',
            type: 'text',
            label: `${this.$t('metrics')}`,
            title: this.$t('metrics'),
            value: '',
            required: false,
            disabled: true,
            class: '--w100'
          },
          {
            name: 'summary_fields',
            type: 'checkbox',
            label: this.$t('options'),
            title: this.$t('options'),
            dataList: [ { label: this.$t('summary_fields'), value: 'summary_fields' } ],
            value: [],
            required: false,
            class: '--w45'
          },
          {
            name: 'format',
            type: 'select',
            label: `${this.$t('format')} *`,
            title: this.$t('format'),
            dataList: [
              { label: 'csv', value: 'csv' },
              { label: 'pdf', value: 'pdf' },
              { label: 'xls', value: 'xls', disabled: true }
            ],
            value: 'pdf',
            required: true,
            class: '--w25'
          }
        ],
        data: {
          objectives: [],
          statuses: [],
          segmentation: [
            { label: this.$t('objective'), value: 'objective' },
            { label: this.$t('day'), value: 'day', disabled: true },
            { label: this.$t('gender'), value: 'gender', disabled: true },
            { label: this.$t('age'), value: 'age', disabled: true }
          ],
          metrics: [
            { label: this.$t('budget'), value: 'budget' },
            { label: this.$t('cpr-cpm'), value: 'cpr' },
            { label: this.$t('cpm'), value: 'cpm' },
            { label: this.$t('results'), value: 'results' },
            { label: this.$t('progress'), value: 'progress' },
            { label: this.$t('spend'), value: 'spend' },
            { label: this.$t('ecpr'), value: 'ecpr' },
            { label: this.$t('reach'), value: 'reach' },
            { label: this.$t('ctr'), value: 'ctr' },
            { label: this.$t('frequency'), value: 'frequency' },
            { label: this.$t('results-rate'), value: 'results_rate' },
            { label: this.$t('impressions'), value: 'impressions' }
          ],
          templates: []
        },
        hiddenFields: [
          // TODO: EN LA EDICION NO SE MODIFICA segmentation NI metrics!!!
          {
            name: 'segmentation_ids',
            value: []
          },
          {
            name: 'metrics_ids',
            value: []
          },
          {
            name: 'entity_ids',
            value: []
          },
          {
            name: 'entity_class',
            value: ''
          },
          // {
          //   name: 'entity_objective',
          //   value: ''
          // },
          {
            name: 'team_id',
            value: ''
          }
        ],
        // TODO: hacer la busqueda por line items, campaigns, adsets y ads
        searchOrders: this.searchOrders,
        searchCampaigns: this.searchCampaigns,
        getReport: this.getReport,
        hideModal: this.hideModal,
        showNotification: this.showNotification,
        previewReport: this.previewReport,
        viewReports: this.viewReports,
        defaultSaveButtonName: this.$t('preview-and-download'),
        $t: this.$t.bind(this),
        save (query) {
          query.start_date = query.start_date.split('T')[0]
          query.end_date = query.end_date.split('T')[0]
          delete query.segmentation_text
          query.segmentation = query.segmentation_ids
          delete query.segmentation_ids
          delete query.metrics_text
          query.metrics = query.metrics_ids
          delete query.metrics_ids
          query.options = {}
          if (query.summary_fields.includes('summary_fields')) {
            query.options.summary_fields = 'true'
          }
          delete query.summary_fields
          if (query.format !== 'null') {
            query.options.format = query.format
          }
          delete query.format
          if (Object.keys(query.options).length === 0) {
            delete query.options
          }
          delete query.entity_tags
          delete query.search_results
          delete query.search
          const onComplete = function (responseData) {
            this.showNotification({ text: this.$t('report-created'), type: 'success', icon: 'success' })
            this.previewReport(query.entity_class)
          }
          query.onComplete = onComplete.bind(this)
          this.getReport(query)
          this.hideModal()
        },
        secondaryLinkText: this.$t('view-reports'),
        secondaryLinkAction (query) {
          this.viewReports({ entityClass: query.entity_class, entityId: query.entity_ids[0] })
          this.hideModal()
        }
      }
      // adCreativeForm: {
      //   fields: [
      //     {
      //       name: 'format',
      //       type: 'select',
      //       label: `${this.$t('format')} *`,
      //       title: this.$t('format'),
      //       dataList: [],
      //       value: '',
      //       required: false,
      //       class: '--w100',
      //       onChange (data, form) {
      //         let query = {
      //           id: form.hiddenFields.find(item => item.name === 'id').value,
      //           creative_id: form.hiddenFields.find(item => item.name === 'creative_id').value,
      //           format: data.value
      //         }
      //         const onComplete = function (form, responseData) {
      //           this.$nextTick(function () {
      //             if (responseData.data && responseData.data.length > 0) {
      //               this.$refs['iframe-modal'].innerHTML = responseData.data
      //             }
      //           })
      //         }
      //         query.onComplete = onComplete.bind(this, form)
      //         form.getAdCreative(query)
      //       }
      //     }
      //   ],
      //   hiddenFields: [
      //     {
      //       name: 'id',
      //       value: ''
      //     },
      //     {
      //       name: 'creative_id',
      //       value: ''
      //     }
      //   ],
      //   iframe: true,
      //   hideModal: this.hideModal,
      //   getAdCreative: this.getAdCreative
      // }
    }
  },
  computed: {
    ...mapGetters([
      'authData',
      'dateFilterFrom',
      'dateFilterTo',
      'insights',
      'statuses',
      'billingEvents',
      'optimizationGoals',
      'genders',
      'creativeFormats',
      'objectives',
      'platforms',
      'orders',
      'agencies',
      'clients',
      'teams',
      'accounts',
      'campaigns',
      'adsets',
      'ads',
      'filteredAgencies',
      'filteredClients',
      'filteredOrders',
      'filteredLineItems',
      'filteredCampaigns',
      'filteredAdsets',
      'filteredAds',
      'filteredSearchText',
      // 'pages',
      'countries',
      'specialAdCategories',
      'bidStrategies',
      'templates',
      'reportData'
    ]),
    agencyBreadcrumb () {
      let agency = this.agencies?.find(agency => agency._id === this.filteredAgencies[0])
      return (this.filteredAgencies.length > 0 && agency) ? agency.name : ''
    },
    clientBreadcrumb () {
      let client = this.clients?.find(client => client._id === this.filteredClients[0])
      return (this.filteredClients.length > 0 && client) ? client.name : ''
    },
    planningBreadcrumb () {
      let planning = this.orders.map(order => order.line_items).flat().find(lineItem => lineItem._id === this.filteredLineItems[0])
      return (this.filteredLineItems.length > 0 && planning) ? planning.name : ''
    },
    campaignBreadcrumb () {
      let campaign = this.campaigns.find(campaign => campaign._id === this.filteredCampaigns[0])
      return (this.filteredCampaigns.length > 0 && campaign) ? campaign.name : ''
    },
    adsetBreadcrumb () {
      let adset = this.adsets.find(adset => adset._id === this.filteredAdsets[0])
      return (this.filteredAdsets.length > 0 && adset) ? adset.name : ''
    },
    tabAgenciesChecked () {
      return this.$route.params.tab === 'agencies'
    },
    tabClientsChecked () {
      return this.$route.params.tab === 'advertisers'
    },
    tabPlanningChecked () {
      return this.$route.params.tab === 'planning'
    },
    tabCampaignsChecked () {
      return this.$route.params.tab === 'campaigns'
    },
    tabAdsetsChecked () {
      return this.$route.params.tab === 'adsets'
    },
    tabAdsChecked () {
      return this.$route.params.tab === 'ads'
    },
    dateFilter () {
      if (this.dateFilterFrom === null) {
        return null
      }
      return (this.dateFilterFrom === this.dateFilterTo)
        ? this.formatDateForDateFilter(this.dateFilterTo)
        : `${this.formatDateForDateFilter(this.dateFilterFrom)} - ${this.formatDateForDateFilter(this.dateFilterTo)}`
    }
  },
  mounted () {
    if (this.$route.query[''] !== undefined) {
      let query = Object.assign({}, this.$route.query)
      delete query['']
      this.$router.replace({ query })
    }
    this.fetchUserData()
    this.getStatuses()
    this.getObjectives()
    this.getInsights()
    this.getAccounts()
    this.getPlatforms()
    this.getBillingEvents()
    this.getOptimizationGoals()
    this.getGenders()
    // this.getPagesFacebook()
    this.getCreativeFormats()
    this.getCountries()
    this.getSpecialAdCategories()
    this.getBidStrategies()
    if (this.$route.query && Object.values(this.$route.query).flat().length) {
      if (this.$route.query.fta) {
        this.storeFilteredAgencies(typeof this.$route.query.fta === 'string' ? Array(this.$route.query.fta) : this.$route.query.fta)
      }
      if (this.$route.query.ftc) {
        this.storeFilteredClients(typeof this.$route.query.ftc === 'string' ? Array(this.$route.query.ftc) : this.$route.query.ftc)
      }
      if (this.$route.query.fo) {
        this.storeFilteredOrders(typeof this.$route.query.fo === 'string' ? Array(this.$route.query.fo) : this.$route.query.fo)
      }
      if (this.$route.query.fli) {
        this.storeFilteredLineItems(typeof this.$route.query.fli === 'string' ? Array(this.$route.query.fli) : this.$route.query.fli)
      }
      if (this.$route.query.fc) {
        this.storeFilteredCampaigns(typeof this.$route.query.fc === 'string' ? Array(this.$route.query.fc) : this.$route.query.fc)
      }
      if (this.$route.query.fas) {
        this.storeFilteredAdsets(typeof this.$route.query.fas === 'string' ? Array(this.$route.query.fas) : this.$route.query.fas)
      }
      if (this.$route.query.fa) {
        this.storeFilteredAds(typeof this.$route.query.fa === 'string' ? Array(this.$route.query.fa) : this.$route.query.fa)
      }
    } else {
      this.onFilteredAgenciesChange({ filteredAgencies: [] })
    }
    this.fecthDataAfterAuthData()
  },
  watch: {
    $route (to, from) {
      this.fetchData()
    },
    filteredSearchText (newValue, oldValue) {
      this.searchText = newValue
      this.fetchData(true)
    },
    dateFilter () {
      this.fetchData()
    },
    // TODO: ARREGLAR EL FILTRADO ACCIDENTAL POR TEXTO DE BÚSQUEDA
    agencies (newValue, oldValue) {
      const updatedAgenciesDataList = newValue.filter(team => {
        return team.role === 'agency'
      }).map(team => {
        return {
          label: team.name,
          value: team._id
        }
      })
      this.newClientModalForm.fields.find(item => item.name === 'team_id').dataList = updatedAgenciesDataList
      this.editClientModalForm.fields.find(item => item.name === 'team_id').dataList = updatedAgenciesDataList
    },
    filteredAgencies (newValue, oldValue) {
      this.$route.query.fta = [...this.filteredAgencies]
      this.addParamsToLocation()
    },
    filteredClients (newValue, oldValue) {
      this.$route.query.ftc = [...this.filteredClients]
      this.addParamsToLocation()
    },
    filteredOrders (newValue, oldValue) {
      this.$route.query.fo = [...this.filteredOrders]
      this.addParamsToLocation()
    },
    filteredLineItems (newValue, oldValue) {
      this.$route.query.fli = [...this.filteredLineItems]
      this.addParamsToLocation()
    },
    filteredCampaigns (newValue, oldValue) {
      this.$route.query.fc = [...this.filteredCampaigns]
      this.addParamsToLocation()
    },
    filteredAdsets (newValue, oldValue) {
      this.$route.query.fas = [...this.filteredAdsets]
      this.addParamsToLocation()
    },
    filteredAds (newValue, oldValue) {
      this.$route.query.fa = [...this.filteredAds]
      this.addParamsToLocation()
    }
    // ,
    // filteredLineItems (newValue, oldValue) {
    //   let filteredCampaigns = []
    //   const orders = this.orders
    //   newValue.forEach(filteredLineItemId => {
    //     const order = orders.find(order => order.line_items.find(lineItem => lineItem._id === filteredLineItemId))
    //     if (order) {
    //       const lineItem = order.line_items.find(lineItem => lineItem._id === filteredLineItemId)
    //       if (lineItem.campaign_ids && lineItem.campaign_ids.length > 0) {
    //         filteredCampaigns = [...filteredCampaigns, ...lineItem.campaign_ids]
    //       }
    //     }
    //   })
    //   // CAMBIAR A FILTRAR CAMPAÑAS DIRECTAMENTE:
    //   this.$route.query.fc = filteredCampaigns
    //   this.addParamsToLocation()
    //   this.storeFilteredCampaigns([...filteredCampaigns])
    // }
  },
  methods: {
    ...mapActions({
      fetchUserData: GET_USER_DATA,
      fetchOrders: GET_ORDERS,
      fetchCampaigns: GET_CAMPAIGNS,
      fetchAgencies: GET_AGENCIES,
      fetchClients: GET_CLIENTS,
      fetchTeams: GET_TEAMS,
      getAccounts: GET_ACCOUNTS,
      fetchAdsets: GET_ADSETS,
      fetchAds: GET_ADS,
      fetchTemplates: GET_TEMPLATES,
      getObjectives: GET_OBJECTIVES,
      getPlatforms: GET_PLATFORMS,
      getInsights: GET_INSIGHTS,
      getStatuses: GET_STATUSES,
      storeFilterDateFrom: STORE_FILTER_DATE_FROM,
      storeFilterDateTo: STORE_FILTER_DATE_TO,
      storeFilterSearchText: STORE_FILTER_SEARCH_TEXT,
      showModalVeil: SHOW_MODAL_VEIL,
      hideModalVeil: HIDE_MODAL_VEIL,
      showModal: SHOW_MODAL,
      hideModal: HIDE_MODAL,
      saveNewTeam: SAVE_NEW_TEAM,
      updateTeam: UPDATE_TEAM,
      deleteTeam: DELETE_TEAM,
      storeFilteredAgencies: STORE_FILTERED_AGENCIES,
      storeFilteredClients: STORE_FILTERED_CLIENTS,
      saveNewOrder: SAVE_NEW_ORDER,
      updateOrder: UPDATE_ORDER,
      refreshOrder: REFRESH_ORDER,
      deleteOrder: DELETE_ORDER,
      storeFilteredOrders: STORE_FILTERED_ORDERS,
      saveNewLineItem: SAVE_NEW_LINE_ITEM,
      updateLineItem: UPDATE_LINE_ITEM,
      refreshLineItem: REFRESH_LINE_ITEM,
      deleteLineItem: DELETE_LINE_ITEM,
      storeFilteredLineItems: STORE_FILTERED_LINE_ITEMS,
      searchCampaigns: SEARCH_CAMPAIGNS,
      saveNewCampaign: SAVE_NEW_CAMPAIGN,
      refreshCampaign: REFRESH_CAMPAIGN,
      updateCampaign: UPDATE_CAMPAIGN,
      deleteCampaign: DELETE_CAMPAIGN,
      saveNewAdset: SAVE_NEW_ADSET,
      refreshAdset: REFRESH_ADSET,
      updateAdset: UPDATE_ADSET,
      deleteAdset: DELETE_ADSET,
      saveNewAd: SAVE_NEW_AD,
      refreshAd: REFRESH_AD,
      updateAd: UPDATE_AD,
      deleteAd: DELETE_AD,
      storeFilteredCampaigns: STORE_FILTERED_CAMPAIGNS,
      storeFilteredAdsets: STORE_FILTERED_ADSETS,
      storeFilteredAds: STORE_FILTERED_ADS,
      getBillingEvents: GET_BILLING_EVENTS,
      getOptimizationGoals: GET_OPTIMIZATION_GOALS,
      getGenders: GET_GENDERS,
      getTargetingFacebook: GET_TARGETING_FACEBOOK,
      // getPagesFacebook: GET_PAGES_FACEBOOK,
      getCreativeFormats: GET_CREATIVE_FORMATS,
      getAdCreative: GET_AD_CREATIVE,
      getCountries: GET_COUNTRIES,
      getSpecialAdCategories: GET_SPECIAL_AD_CATEGORIES,
      getBidStrategies: GET_BID_STRATEGIES,
      getReport: GET_REPORT,
      hideReport: HIDE_REPORT,
      showNotification: SHOW_NOTIFICATION
      // searchOrders: SEARCH_ORDERS,
      // searchCampaigns: SEARCH_CAMPAIGNS
    }),
    fecthDataAfterAuthData () {
      if (this.authData && this.authData !== null) {
        this.fetchData()
      } else {
        window.setTimeout(this.fecthDataAfterAuthData.bind(this), 100)
      }
    },
    fetchData (isSearchText) {
      switch (this.$route.params.tab) {
        case 'agencies':
          this.fetchAgencies({ team_id: this.authData.team._id })
          break
        case 'advertisers':
          if (!isSearchText) {
            this.fetchAgencies({ team_id: this.authData.team._id })
          }
          this.fetchClients({ team_id: this.filteredAgencies.join(',') })
          break
        case 'planning':
          if (!isSearchText) {
            this.fetchAgencies({ team_id: this.authData.team._id })
            this.fetchClients({ team_id: this.filteredAgencies.join(',') })
          }
          this.fetchOrders({ teams: this.filteredClients.join(',') })
          if (this.canUse('report.create')) {
            this.fetchTemplates()
          }
          break
        case 'campaigns':
          if (!isSearchText) {
            this.fetchAgencies({ team_id: this.authData.team._id })
            this.fetchClients({ team_id: this.filteredAgencies.join(',') })
            this.fetchOrders({ teams: this.filteredClients.join(',') })
          }
          this.fetchCampaigns({ teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(',') })
          if (this.canUse('report.create')) {
            this.fetchTemplates()
          }
          break
        case 'adsets':
          if (!isSearchText) {
            this.fetchAgencies({ team_id: this.authData.team._id })
            this.fetchClients({ team_id: this.filteredAgencies.join(',') })
            this.fetchOrders({ teams: this.filteredClients.join(',') })
            this.fetchCampaigns({ teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(',') })
          }
          this.fetchAdsets({ teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(','), campaigns: this.filteredCampaigns.join(',') })
          break
        case 'ads':
          if (!isSearchText) {
            this.fetchAgencies({ team_id: this.authData.team._id })
            this.fetchClients({ team_id: this.filteredAgencies.join(',') })
            this.fetchOrders({ teams: this.filteredClients.join(',') })
            this.fetchCampaigns({ teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(',') })
            this.fetchAdsets({ teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(','), campaigns: this.filteredCampaigns.join(',') })
          }
          this.fetchAds({ teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(','), campaigns: this.filteredCampaigns.join(','), adsets: this.filteredAdsets.join(',') })
          break
      }
    },
    // showNotification ({ text, type, icon }) {
    //   this.showNotification({ text, type, icon })
    // },
    showAgenciesTab () {
      this.addParamsToLocation()
      this.$nextTick(function () {
        if (this.$route.path !== '/dashboard/agencies') {
          this.$router.push({ name: 'dashboard', params: { tab: 'agencies' }, query: this.$route.query })
        }
        this.clearSearchText()
      })
    },
    showClientsTab () {
      this.addParamsToLocation()
      this.$nextTick(function () {
        if (this.$route.path !== '/dashboard/advertisers') {
          this.$router.push({ name: 'dashboard', params: { tab: 'advertisers' }, query: this.$route.query })
        }
        this.clearSearchText()
      })
    },
    showPlanningTab () {
      this.addParamsToLocation()
      this.$nextTick(function () {
        if (this.$route.path !== '/dashboard/planning') {
          this.$router.push({ name: 'dashboard', params: { tab: 'planning' }, query: this.$route.query })
        }
        this.fetchOrders({ teams: this.filteredClients.join(',') })
        this.clearSearchText()
      })
    },
    showCampaignsTab () {
      this.addParamsToLocation()
      this.$nextTick(function () {
        if (this.$route.path !== '/dashboard/campaigns') {
          this.$router.push({ name: 'dashboard', params: { tab: 'campaigns' }, query: this.$route.query })
        }
        this.fetchCampaigns({ teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(',') })
        this.clearSearchText()
      })
    },
    showAdsetsTab (e) {
      this.addParamsToLocation()
      this.$nextTick(function () {
        if (this.$route.path !== '/dashboard/adsets') {
          this.$router.push({ name: 'dashboard', params: { tab: 'adsets' }, query: this.$route.query })
        }
      })
      this.fetchAdsets({ teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(','), campaigns: this.filteredCampaigns.join(',') })
      this.clearSearchText()
    },
    showAdsTab (e) {
      this.addParamsToLocation()
      this.$nextTick(function () {
        if (this.$route.path !== '/dashboard/ads') {
          this.$router.push({ name: 'dashboard', params: { tab: 'ads' }, query: this.$route.query })
        }
      })
      this.fetchAds({ teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(','), campaigns: this.filteredCampaigns.join(','), adsets: this.filteredAdsets.join(',') })
      this.clearSearchText()
    },
    dateFilterShowModal () {
      this.isDateSelectorVisible = true
    },
    getNewAgencyModalForm () {
      let modalFormData = Object.assign({}, this.newAgencyModalForm)
      modalFormData.hiddenFields.find(item => item.name === 'team_id').value = this.authData.team._id
      modalFormData.fields.find(item => item.name === 'name').value = ''
      modalFormData.fields.find(item => item.name === 'country').dataList = [...this.countries]
      modalFormData.fields.find(item => item.name === 'country').value = 'ES'
      modalFormData.fields.find(item => item.name === 'vat').value = ''
      modalFormData.fields.find(item => item.name === 'billing_email').value = ''
      modalFormData.fields.find(item => item.name === 'legal_name').value = ''
      modalFormData.fields.find(item => item.name === 'address').value = ''
      modalFormData.fields.find(item => item.name === 'city').value = ''
      modalFormData.fields.find(item => item.name === 'zip').value = ''
      modalFormData.filteredTeam = this.authData.team._id
      return modalFormData
    },
    getEditAgencyForm (team) {
      let modalFormData = Object.assign({}, this.editAgencyModalForm)
      modalFormData.hiddenFields.find(item => item.name === 'id').value = team._id
      modalFormData.fields.find(item => item.name === 'name').value = team.name
      modalFormData.originalData.name = team.name
      modalFormData.fields.find(item => item.name === 'country').dataList = [...this.countries]
      modalFormData.fields.find(item => item.name === 'country').value = team.country
      modalFormData.originalData.country = team.country
      modalFormData.fields.find(item => item.name === 'vat').value = team.vat
      modalFormData.originalData.vat = team.vat
      modalFormData.fields.find(item => item.name === 'billing_email').value = team.billing_email
      modalFormData.originalData.billing_email = team.billing_email
      modalFormData.fields.find(item => item.name === 'legal_name').value = team.legal_name
      modalFormData.originalData.legal_name = team.legal_name
      modalFormData.fields.find(item => item.name === 'address').value = team.address
      modalFormData.originalData.address = team.address
      modalFormData.fields.find(item => item.name === 'city').value = team.city
      modalFormData.originalData.city = team.city
      modalFormData.fields.find(item => item.name === 'zip').value = team.zip
      modalFormData.originalData.zip = team.zip
      modalFormData.filteredTeam = this.authData.team._id
      if (!this.canUse('team.delete')) {
        delete modalFormData.saveDelete
      }
      return modalFormData
    },
    getNewClientModalForm () {
      let modalFormData = Object.assign({}, this.newClientModalForm)
      modalFormData.fields.find(item => item.name === 'name').value = ''
      modalFormData.fields.find(item => item.name === 'facebook_account_ids').dataList = [...this.accounts.filter(account => account.platform === 1).map(account => {
        return {
          value: account._id,
          label: account.name
        }
      })]
      modalFormData.fields.find(item => item.name === 'facebook_account_ids').value = []
      modalFormData.fields.find(item => item.name === 'twitter_account_ids').dataList = [...this.accounts.filter(account => account.platform === 2).map(account => {
        return {
          value: account._id,
          label: account.name
        }
      })]
      modalFormData.fields.find(item => item.name === 'twitter_account_ids').value = []
      modalFormData.fields.find(item => item.name === 'team_id').value = this.filteredAgencies[0]
      modalFormData.filteredTeam = this.authData.team._id
      modalFormData.filteredAgencies = this.filteredAgencies
      return modalFormData
    },
    getEditClientForm (team) {
      let modalFormData = Object.assign({}, this.editClientModalForm)
      modalFormData.hiddenFields.find(item => item.name === 'id').value = team._id
      modalFormData.fields.find(item => item.name === 'name').value = team.name
      modalFormData.originalData.name = team.name
      modalFormData.fields.find(item => item.name === 'team_id').value = team.team_id
      modalFormData.originalData.team_id = team.team_id
      modalFormData.fields.find(item => item.name === 'facebook_account_ids').dataList = [...this.accounts.filter(account => account.platform === 1).map(account => {
        return {
          value: account._id,
          label: account.name
        }
      })]
      modalFormData.fields.find(item => item.name === 'facebook_account_ids').value = team.facebook_account_ids || []
      modalFormData.originalData.facebook_account_ids = team.facebook_account_ids
      modalFormData.fields.find(item => item.name === 'twitter_account_ids').dataList = [...this.accounts.filter(account => account.platform === 2).map(account => {
        return {
          value: account._id,
          label: account.name
        }
      })]
      modalFormData.fields.find(item => item.name === 'twitter_account_ids').value = team.twitter_account_ids || []
      modalFormData.originalData.twitter_account_ids = team.twitter_account_ids
      modalFormData.filteredTeam = this.authData.team._id
      modalFormData.filteredAgencies = this.filteredAgencies
      if (!this.canUse('team.delete')) {
        delete modalFormData.saveDelete
      }
      return modalFormData
    },
    getNewOrderForm () {
      const today = new Date()
      const teamsDataList = this.clients.filter(team => team.team_id === this.filteredAgencies[0]).map(team => {
        return { label: team.name, value: team._id }
      })
      let modalFormData = Object.assign({}, this.newOrderModalForm)
      modalFormData.fields.find(item => item.name === 'team_ids').dataList = teamsDataList
      modalFormData.fields.find(item => item.name === 'team_ids').value = this.filteredClients[0]
      modalFormData.fields.find(item => item.name === 'name').value = ''
      modalFormData.fields.find(item => item.name === 'start_date').value = today.toISOString().split('T')[0]
      modalFormData.fields.find(item => item.name === 'end_date').value = ''
      modalFormData.fields.find(item => item.name === 'status').dataList = [...this.statuses]
      modalFormData.fields.find(item => item.name === 'status').value = 'PROGRAMMED'
      return modalFormData
    },
    getEditOrderForm (order) {
      const teamsDataList = this.clients.filter(team => team.team_id === this.filteredAgencies[0]).map(team => {
        return { label: team.name, value: team._id }
      })
      let modalFormData = Object.assign({}, this.editOrderModalForm)
      modalFormData.hiddenFields.find(item => item.name === 'id').value = order._id
      modalFormData.originalData.name = order.name
      modalFormData.fields.find(item => item.name === 'name').value = order.name
      modalFormData.originalData.status = order.status
      modalFormData.fields.find(item => item.name === 'status').value = order.status
      modalFormData.fields.find(item => item.name === 'status').dataList = [...this.statuses]
      modalFormData.originalData.start_date = order.start_date.split('T')[0]
      modalFormData.fields.find(item => item.name === 'start_date').value = order.start_date.split('T')[0]
      modalFormData.originalData.end_date = order.end_date.split('T')[0]
      modalFormData.fields.find(item => item.name === 'end_date').value = order.end_date.split('T')[0]
      modalFormData.fields.find(item => item.name === 'team_ids').dataList = teamsDataList
      modalFormData.fields.find(item => item.name === 'team_ids').value = order.team_ids
      modalFormData.originalData.team_ids = order.team_ids
      if (!this.canUse('order.delete')) {
        delete modalFormData.saveDelete
      }
      return modalFormData
    },
    // AL ELEGIR MODEL MARGINAL CAMBIAR EL LABEL DEL LABEL A "FEE"
    getNewLineItemForm (orderId) {
      const today = new Date()
      let modalFormData = Object.assign({}, this.newLineItemModalForm)
      modalFormData.data.objectives = [...this.objectives]
      modalFormData.data.statuses = [...this.statuses]
      modalFormData.fields.find(item => item.name === 'name').value = ''
      modalFormData.fields.find(item => item.name === 'start_date').value = today.toISOString().split('T')[0]
      modalFormData.fields.find(item => item.name === 'end_date').value = ''
      modalFormData.fields.find(item => item.name === 'status').dataList = [...this.statuses]
      modalFormData.fields.find(item => item.name === 'status').value = 'PROGRAMMED'
      modalFormData.fields.find(item => item.name === 'objective').dataList = [...this.objectives]
      modalFormData.fields.find(item => item.name === 'objective').value = ''
      modalFormData.fields.find(item => item.name === 'cpr').value = ''
      modalFormData.fields.find(item => item.name === 'budget').value = ''
      modalFormData.fields.find(item => item.name === 'target_results').value = ''
      modalFormData.fields.find(item => item.name === 'description').value = ''
      modalFormData.fields.find(item => item.name === 'model').value = 'MARGINAL'
      modalFormData.fields.find(item => item.name === 'initial_margin').value = ''
      modalFormData.hiddenFields.find(item => item.name === 'order_id').value = orderId
      return modalFormData
    },
    getEditLineItemForm (lineItem) {
      let modalFormData = Object.assign({}, this.editLineItemModalForm)
      modalFormData.data.objectives = [...this.objectives]
      modalFormData.data.statuses = [...this.statuses]
      modalFormData.hiddenFields.find(item => item.name === 'id').value = lineItem._id
      modalFormData.hiddenFields.find(item => item.name === 'order_id').value = lineItem.orderId
      modalFormData.originalData.name = lineItem.name
      modalFormData.fields.find(item => item.name === 'name').value = lineItem.name
      modalFormData.originalData.start_date = lineItem.start_date
      modalFormData.fields.find(item => item.name === 'start_date').value = lineItem.start_date.split('T')[0]
      modalFormData.originalData.end_date = lineItem.end_date
      modalFormData.fields.find(item => item.name === 'end_date').value = lineItem.end_date.split('T')[0]
      modalFormData.originalData.status = lineItem.status
      modalFormData.fields.find(item => item.name === 'status').dataList = [...this.statuses]
      modalFormData.fields.find(item => item.name === 'status').value = lineItem.status
      modalFormData.originalData.objective = lineItem.objective
      modalFormData.fields.find(item => item.name === 'objective').value = lineItem.objective
      // modalFormData.fields.find(item => item.name === 'objective').disabled = true
      modalFormData.fields.find(item => item.name === 'objective').dataList = [...this.objectives]
      if (lineItem.objective === 'IMPRESSIONS' || lineItem.objective === 'REACH') {
        modalFormData.fields.find(item => item.name === 'cpr').label = `${this.$t('cpm')} *`
        // modalFormData.originalData.cpr = lineItem.cpr * 1000
        // modalFormData.fields.find(item => item.name === 'cpr').value = lineItem.cpr * 1000
      } else {
        modalFormData.fields.find(item => item.name === 'cpr').label = `${this.$t('cpr')} *`
      }
      modalFormData.originalData.budget = lineItem.budget
      modalFormData.fields.find(item => item.name === 'budget').value = lineItem.budget
      modalFormData.originalData.cpr = lineItem.cpr
      modalFormData.fields.find(item => item.name === 'cpr').value = lineItem.cpr
      modalFormData.originalData.target_results = lineItem.target_results
      modalFormData.fields.find(item => item.name === 'target_results').value = lineItem.target_results
      modalFormData.originalData.description = lineItem.description
      modalFormData.fields.find(item => item.name === 'description').value = lineItem.description
      modalFormData.originalData.model = lineItem.model
      modalFormData.fields.find(item => item.name === 'model').value = lineItem.model
      // modalFormData.fields.find(item => item.name === 'model').disabled = true
      modalFormData.fields.find(item => item.name === 'model').dataList = [ { value: 'MARGINAL', label: this.$t('marginal') }, { value: 'TRANSPARENT', label: this.$t('transparent') } ]
      if (lineItem.model === 'MARGINAL') {
        modalFormData.fields.find(item => item.name === 'initial_margin').label = this.$t('commercial-margin')
        // modalFormData.fields.find(item => item.name === 'initial_margin').disabled = true
        // modalFormData.originalData.cpr = lineItem.cpr * 1000
        // modalFormData.fields.find(item => item.name === 'cpr').value = lineItem.cpr * 1000
      } else {
        modalFormData.fields.find(item => item.name === 'initial_margin').label = this.$t('fee')
        // modalFormData.fields.find(item => item.name === 'initial_margin').disabled = true
      }
      modalFormData.originalData.initial_margin = lineItem.margin
      modalFormData.fields.find(item => item.name === 'initial_margin').value = lineItem.margin
      modalFormData.originalData.search = []
      modalFormData.fields.find(item => item.name === 'search').value = ''
      modalFormData.originalData.search_results = []
      modalFormData.fields.find(item => item.name === 'search_results').dataList = []
      modalFormData.fields.find(item => item.name === 'search_results').value = []
      if (lineItem.campaign_ids.length > 0) {
        let query = {
          directSearch: true
        }
        const onComplete = function (responseData) {
          this.$nextTick(function () {
            const searchResults = responseData.data.map(item => {
              return { label: `${item.name} (${translateObjective.bind(this)(item)}, ${translateStatus.bind(this)(item)})`, value: item._id }
            })
            this.editLineItemModalForm.originalData.campaigns_tags = [...searchResults]
            this.editLineItemModalForm.fields.find(item => item.name === 'campaigns_tags').value = [...searchResults]
            function translateObjective (item) {
              const objectives = this.objectives
              if (objectives.find(objective => objective.value === item.objective)) {
                return objectives.find(objective => objective.value === item.objective).label
              } else {
                return item.objective
              }
            }
            function translateStatus (item) {
              const statuses = this.statuses
              if (statuses.find(status => status.value === item.status)) {
                return statuses.find(status => status.value === item.status).label
              } else {
                return item.status
              }
            }
          })
        }
        query.onComplete = onComplete.bind(this)
        query.campaign_ids = [...lineItem.campaign_ids]
        this.fetchCampaigns(query)
        modalFormData.originalData.campaigns_tags = []
        modalFormData.fields.find(item => item.name === 'campaigns_tags').value = []
      } else {
        modalFormData.originalData.campaigns_tags = []
        modalFormData.fields.find(item => item.name === 'campaigns_tags').value = []
      }
      modalFormData.originalData.campaign_ids = [...lineItem.campaign_ids]
      modalFormData.hiddenFields.find(item => item.name === 'campaign_ids').value = [...lineItem.campaign_ids]
      if (!this.canUse('order.delete')) {
        delete modalFormData.saveDelete
      }
      return modalFormData
    },
    getEditCampaignForm (campaign) {
      const filteredFacebookStatuses = ['ACTIVE', 'PAUSED']
      const campaignAccount = this.accounts.find(item => item.api_id === campaign.account_id)
      let modalFormData = Object.assign({}, this.editCampaignModalForm)
      modalFormData.hiddenFields.find(item => item.name === 'id').value = campaign._id
      modalFormData.originalData.name = campaign.name
      modalFormData.fields.find(item => item.name === 'name').value = campaign.name
      // TODO: DEJO ESTO ASI HASTA QUE ENEKO AÑADA .platform a campaign
      modalFormData.originalData.platform_name = this.platforms.find(item => item.value === campaign.platform).label
      modalFormData.fields.find(item => item.name === 'platform_name').value = this.platforms.find(item => item.value === campaign.platform).label
      modalFormData.originalData.account_name = (campaignAccount) ? campaignAccount.name : ''
      modalFormData.fields.find(item => item.name === 'account_name').value = (campaignAccount) ? campaignAccount.name : ''
      modalFormData.originalData.objective_name = this.objectives.find(item => item.value === campaign.objective).label
      modalFormData.fields.find(item => item.name === 'objective_name').value = this.objectives.find(item => item.value === campaign.objective).label
      modalFormData.originalData.start_date = campaign.start_date.split('T')[0]
      modalFormData.fields.find(item => item.name === 'start_date').value = campaign.start_date.split('T')[0]
      modalFormData.originalData.end_date = campaign.end_date.split('T')[0]
      modalFormData.fields.find(item => item.name === 'end_date').value = campaign.end_date.split('T')[0]
      modalFormData.originalData.daily_budget = campaign.daily_budget
      modalFormData.fields.find(item => item.name === 'daily_budget').value = campaign.daily_budget
      modalFormData.originalData.lifetime_budget = campaign.lifetime_budget
      modalFormData.fields.find(item => item.name === 'lifetime_budget').value = campaign.lifetime_budget
      if (campaign.daily_budget !== undefined && campaign.daily_budget !== null && campaign.daily_budget !== '') {
        modalFormData.fields.find(item => item.name === 'lifetime_budget').invisible = true
        modalFormData.fields.find(item => item.name === 'daily_budget').invisible = false
      } else {
        modalFormData.fields.find(item => item.name === 'daily_budget').invisible = true
        modalFormData.fields.find(item => item.name === 'lifetime_budget').invisible = false
      }
      modalFormData.fields.find(item => item.name === 'status').dataList = [...this.statuses.filter(item => filteredFacebookStatuses.includes(item.value))]
      modalFormData.originalData.status = campaign.status
      modalFormData.fields.find(item => item.name === 'status').value = campaign.status
      // REVISAR: bid_strategy sólo para Facebook?
      modalFormData.fields.find(item => item.name === 'bid_strategy').dataList = [...this.bidStrategies.map(item => {
        return { value: item.value, label: this.$t(`bid-strategy-${item.label}`) }
      })]
      modalFormData.originalData.bid_strategy = campaign.bid_strategy
      modalFormData.fields.find(item => item.name === 'bid_strategy').value = campaign.bid_strategy
      if (!this.canUse('campaign.delete')) {
        delete modalFormData.saveDelete
      }
      return modalFormData
    },
    getEditAdsetForm (adset) {
      const filteredFacebookStatuses = ['ACTIVE', 'PAUSED']
      let modalFormData = Object.assign({}, this.editAdsetModalForm)
      modalFormData.hiddenFields.find(item => item.name === 'id').value = adset._id
      modalFormData.originalData.name = adset.name
      modalFormData.fields.find(item => item.name === 'name').value = adset.name
      modalFormData.originalData.bid_amount = adset.bid_amount
      modalFormData.fields.find(item => item.name === 'bid_amount').value = adset.bid_amount
      if (adset.daily_budget !== null) {
        modalFormData.originalData.daily_budget = adset.daily_budget
        modalFormData.fields.find(item => item.name === 'daily_budget').value = adset.daily_budget
        modalFormData.fields.find(item => item.name === 'daily_budget').invisible = false
      } else {
        modalFormData.fields.find(item => item.name === 'daily_budget').invisible = true
        modalFormData.fields.find(item => item.name === 'daily_budget').value = null
      }
      if (adset.lifetime_budget !== null) {
        modalFormData.originalData.lifetime_budget = adset.lifetime_budget
        modalFormData.fields.find(item => item.name === 'lifetime_budget').value = adset.lifetime_budget
        modalFormData.fields.find(item => item.name === 'lifetime_budget').invisible = false
      } else {
        modalFormData.fields.find(item => item.name === 'lifetime_budget').invisible = true
        modalFormData.fields.find(item => item.name === 'lifetime_budget').value = null
      }
      if (adset.end_date) {
        modalFormData.originalData.end_date = adset.end_date
        modalFormData.fields.find(item => item.name === 'end_date').value = adset.end_date
        modalFormData.fields.find(item => item.name === 'end_date').invisible = false
      } else {
        modalFormData.fields.find(item => item.name === 'end_date').invisible = true
        modalFormData.fields.find(item => item.name === 'end_date').value = null
      }
      modalFormData.originalData.status = adset.status
      modalFormData.fields.find(item => item.name === 'status').dataList = [...this.statuses.filter(item => filteredFacebookStatuses.includes(item.value))]
      modalFormData.fields.find(item => item.name === 'status').value = adset.status
      modalFormData.fields.find(item => item.name === 'billing_event').value = this.$t(`billing-event-${adset.billing_event}`)
      modalFormData.fields.find(item => item.name === 'optimization_goal').value = (adset.optimization_goal) ? this.$t(`optimization-goal-${adset.optimization_goal}`) : null
      if (!this.canUse('adset.delete')) {
        delete modalFormData.saveDelete
      }
      return modalFormData
    },
    // getNewAdForm ({ campaignId, adsetId }) {
    //   let campaign = this.campaigns.find(item => item._id === campaignId)
    //   let adset = this.adsets.find(item => item._id === adsetId)
    //   let modalFormData = Object.assign({}, this.newAdModalForm)
    //   const filteredFacebookStatuses = ['ACTIVE', 'PAUSED']
    //   modalFormData.hiddenFields.find(item => item.name === 'adset_id').value = adset.api_id
    //   modalFormData.hiddenFields.find(item => item.name === 'platform').value = campaign.platform
    //   modalFormData.fields.find(item => item.name === 'status').dataList = [...this.statuses.filter(item => filteredFacebookStatuses.includes(item.value))]
    //   modalFormData.fields.find(item => item.name === 'status').value = 'PAUSED'
    //   switch (campaign.platform) {
    //     case 1:
    //     default:
    //       modalFormData.fields.find(item => item.name === 'page').dataList = [...this.pages.facebook.filter(item => item.platform === 1).map(item => {
    //         return { label: item.name, value: item.api_id }
    //       })]
    //   }
    //   return modalFormData
    // },
    getEditAdForm (ad) {
      // NO HAY EDCION, SOLO SE PUEDE EDITAR EL STATUS, DESDE LA TABLA
    },
    getAdCreativeFormData (ad) {
      // let modalFormData = Object.assign({}, this.adCreativeForm)
      let modalFormData = {
        fields: [
          {
            name: 'format',
            type: 'select',
            label: `${this.$t('format')} *`,
            title: this.$t('format'),
            dataList: [],
            value: '',
            required: false,
            class: '--w100',
            onChange (data, form) {
              let query = {
                id: form.hiddenFields.find(item => item.name === 'id').value,
                creative_id: form.hiddenFields.find(item => item.name === 'creative_id').value,
                format: data.value
              }
              const onComplete = function (form, responseData) {
                this.$nextTick(function () {
                  if (responseData.data && responseData.data.length > 0) {
                    this.$refs['iframe-modal'].innerHTML = responseData.data
                  }
                })
              }
              query.onComplete = onComplete.bind(this, form)
              form.getAdCreative(query)
            }
          }
        ],
        hiddenFields: [
          {
            name: 'id',
            value: ''
          },
          {
            name: 'creative_id',
            value: ''
          }
        ],
        iframe: true,
        hideModal: this.hideModal,
        getAdCreative: this.getAdCreative
      }
      modalFormData.hiddenFields.find(item => item.name === 'id').value = ad._id
      modalFormData.hiddenFields.find(item => item.name === 'creative_id').value = ad.creative.id
      modalFormData.fields.find(item => item.name === 'format').dataList = [...this.creativeFormats.facebook.map(item => {
        return { label: `${item[0]}${item.slice(1).split('_').join(' ').toLowerCase()}`, value: item }
      })]
      return modalFormData
    },
    // REPORTS:
    getNewReportForm (reportConfig) {
      // No se pueden modificar los campos segmentation ni metrics:
      let modalFormData = Object.assign({}, this.newReportForm)
      // let entity = {}
      // switch (reportConfig.entity) {
      //   case 'order':
      //   case 'lineitem':
      //     // TODO: AJUSTAR PARA ENVIAR TODAS LAS CAMPAÑAS DEL LINE ITEM
      //     entity = this.orders.map(order => order.line_items).flat().find(lineItem => lineItem._id === reportConfig.entityId)
      //     break
      //   case 'campaign':
      //     entity = this.campaigns.find(campaign => campaign._id === reportConfig.entityId)
      //     break
      //   default:
      //     window.alert('TIPO DE ENTIDAD NO RECONOCIDA')
      // }
      modalFormData.hiddenFields.find(item => item.name === 'team_id').value = this.authData.team._id
      modalFormData.hiddenFields.find(item => item.name === 'entity_ids').value = [...reportConfig.entityIds]
      modalFormData.hiddenFields.find(item => item.name === 'entity_class').value = reportConfig.entiyType
      // modalFormData.hiddenFields.find(item => item.name === 'entity_objective').value = entity.objective
      // OJO: no usar el nombre de la entidad ni sus fechas, ya que pueden ser más de una entidad:
      // modalFormData.fields.find(item => item.name === 'name').value = entity.name
      // modalFormData.fields.find(item => item.name === 'start_date').value = entity.start_date.split('T')[0]
      // modalFormData.fields.find(item => item.name === 'end_date').value = entity.end_date.split('T')[0]
      modalFormData.data.templates = [...this.templates]
      modalFormData.fields.find(item => item.name === 'template').dataList = this.templates.map(template => {
        return {
          label: template.name,
          value: template._id
        }
      })
      modalFormData.fields.find(item => item.name === 'template').value = ''
      modalFormData.fields.find(item => item.name === 'segmentation_text').value = ''
      modalFormData.fields.find(item => item.name === 'metrics_text').value = ''
      modalFormData.fields.find(item => item.name === 'summary_fields').value = []
      modalFormData.fields.find(item => item.name === 'format').value = ''
      return modalFormData
    },
    hideReportData () {
      this.hideReport()
    },
    // translateOptionsString (optionsObject) {
    //   return optionsObject ? Object.keys(optionsObject).map(item => `${this.$t(item)}${typeof optionsObject[item] === 'string' ? ` (${optionsObject[item]})` : ''}`).join(', ') : '-'
    // },
    // translateFilenameString (url, name) {
    //   let urlFragments = url.split('.')
    //   return `${name.toLowerCase()}.${urlFragments[urlFragments.length - 1]}`
    // },
    // translateColClass (type) {
    //   let colClass = ''
    //   switch (type) {
    //     case 'string':
    //       colClass = '--w300px'
    //       break
    //     case 'date':
    //       colClass = '--ta-c --w150px'
    //       break
    //     case 'float':
    //       colClass = '--ta-r --w100px'
    //       break
    //     case 'int':
    //       colClass = '--ta-r --w100px'
    //       break
    //   }
    //   return colClass
    // },
    showReports () {
      this.addParamsToLocation()
      this.$nextTick(function () {
        if (this.$route.path !== '/reports') {
          this.$router.push({ name: 'reports', params: { tab: 'all' }, query: this.$route.query })
        }
      })
    },
    showCreateReport () {
      this.addParamsToLocation()
      this.$nextTick(function () {
        if (this.$route.path !== '/reports/new') {
          this.$router.push({ name: 'reports-new', params: { tab: 'new' }, query: this.$route.query })
        }
      })
    },
    // translateColValue (value, type) {
    //   let colValue = ''
    //   switch (type) {
    //     case 'string':
    //       colValue = value
    //       break
    //     case 'date':
    //       colValue = this.formatDate(value)
    //       break
    //     case 'float':
    //       colValue = Number(value).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })
    //       break
    //     case 'int':
    //       colValue = Number(value).toLocaleString()
    //       break
    //   }
    //   return colValue
    // },
    // translateSummaryColValue (value) {
    //   let colValue = ''
    //   switch (typeof value) {
    //     case 'number':
    //       colValue = Number(value).toLocaleString()
    //       break
    //     default:
    //       colValue = value
    //   }
    //   return colValue
    // },
    clickAgenciesTab () {
      this.$refs['tab-agencies'].click()
    },
    clickClientsTab () {
      this.$refs['tab-clients'].click()
    },
    clickPlanningTab () {
      this.$refs['tab-planning'].click()
    },
    clickCampaignsTab () {
      this.$refs['tab-campaigns'].click()
    },
    clickAdsetsTab () {
      this.$refs['tab-adsets'].click()
    },
    clickAdsTab () {
      this.$refs['tab-ads'].click()
    },
    addParamsToLocation () {
      const params = this.$route.query
      const queryString = Object.keys(params).map(key => {
        return (typeof params[key] === 'string')
          ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
          : (params[key] === undefined || params[key].length === 0) ? '' : params[key].map(keyValue => `${key}=${keyValue}`).join('&')
      }).join('&')
      window.history.pushState({}, null, `${this.$route.path}?${queryString}`)
    },
    format ({ value, format, nullsy }) {
      let returnValue
      if (nullsy === true && value === undefined) {
        returnValue = ''
      } else {
        switch (format) {
          case 'number':
            returnValue = this.formatNumber(value)
            break
          case 'currency':
            returnValue = this.formatCurrency(value)
            break
          case 'decimal':
            returnValue = this.formatDecimal(value)
            break
          case 'date':
            returnValue = this.formatDate(value)
            break
          case 'dateLong':
            returnValue = this.formatDateLong(value)
            break
          case 'dateForDateFilter':
            returnValue = this.formatDateForDateFilter(value)
            break
          default:
            returnValue = this.$t(value) !== value ? this.$t(value) : value
        }
      }
      return returnValue
    },
    formatDate (date) {
      return date && date !== '' ? new Date(date).toLocaleString(this.locale, { year: 'numeric', month: 'numeric', day: 'numeric' }) : ''
    },
    formatDateLong (date) {
      return new Date(date).toLocaleString(this.locale, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })
    },
    formatDateForDateFilter (date) {
      return new Date(date).toLocaleString(this.locale, { month: 'short', day: 'numeric' })
    },
    formatCurrency (value) {
      return Number(value || 0).toLocaleString(this.locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    formatDecimal (value) {
      return Number(value || 0).toLocaleString(this.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    formatNumber (value) {
      return Number(value || 0).toLocaleString(this.locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    },
    translateInsight (insightKey) {
      const insight = this.insights.find(item => item.value === insightKey)
      return (insight) ? insight.label : insightKey.toLowerCase().split('_').join(' ')
    },
    translateObjective (objectiveKey) {
      const objective = this.objectives.find(item => item.value === objectiveKey || '-')
      return (objective) ? objective.label : objectiveKey.toLowerCase().split('_').join(' ')
    },
    translatePlatformsToIcons (platformKeys) {
      const keys = platformKeys.split(',')
      return keys.map(key => this.translatePlatformToIcon(key))
    },
    translatePlatformToIcon (platformKey) {
      const rssIconFolder = '/img/rrss'
      return [
        '', // Undefined
        `${rssIconFolder}/facebook.png`, // 1 Facebook
        `${rssIconFolder}/twitter.png`, // 2 Twitter
        `${rssIconFolder}/google.png`, // 3 Google Ads
        `${rssIconFolder}/linkedin.png` // 4 Linkedin
      ][platformKey]
    },
    fauxCampaignDataToChartData (chartData) {
      // SEE: https://www.paulirish.com/2009/random-hex-color-code-snippets/
      // const gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
      // gradient.addColorStop(0, 'rgba(255, 0, 0, 0.5)')
      // gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)')
      // gradient.addColorStop(1, 'rgba(255, 0, 0, 0)')
      // const gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
      // gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)')
      // gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)')
      // gradient2.addColorStop(1, 'rgba(0, 231, 255, 0)')
      const labels = chartData.map(item => item.date.replace('2019-', '').replace('-', '/'))
      // const labels = new Set(data.map(item => item.date))
      const clicks = chartData.map(dayData => dayData.clicks)
      const conversions = chartData.map(dayData => dayData.conversions)
      const impressions = chartData.map(dayData => dayData.impressions)
      const spend = chartData.map(dayData => dayData.spend)
      return {
        labels,
        datasets: [
          {
            label: 'Clicks',
            borderColor: '#FC2525',
            // pointBackgroundColor: 'white',
            borderWidth: 1,
            // pointBorderColor: 'white',
            backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
            data: clicks
          },
          {
            label: 'Conversions',
            borderColor: '#FC2525',
            // pointBackgroundColor: 'white',
            borderWidth: 1,
            // pointBorderColor: 'white',
            backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
            data: conversions
          },
          {
            label: 'Impressions',
            borderColor: '#FC2525',
            // pointBackgroundColor: 'white',
            borderWidth: 1,
            // pointBorderColor: 'white',
            backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
            data: impressions
          },
          {
            label: 'Spend',
            borderColor: '#FC2525',
            // pointBackgroundColor: 'white',
            borderWidth: 1,
            // pointBorderColor: 'white',
            backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
            data: spend
          }
        ]
      }
    },
    fauxInsightsDataToChartData (insights) {
      // TODO: ADD KEYS / VALUES !!! cc: @eneko
      const labels = Object.keys(insights)
      const datasets = labels.map((label, index) => {
        const data = []
        data.fill(0, 0, labels.length)
        data[index] = insights[label]
        return {
          label,
          // SEE: https://www.paulirish.com/2009/random-hex-color-code-snippets/
          backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
          data
        }
      })
      return {
        labels,
        datasets
      }
    },
    clearSearchText () {
      this.storeFilterSearchText('')
    },
    canUse (permissionName) {
      return this.authData ? (this.authData.role === 'admin' || this.authData.team.permissions.find(item => item.name === permissionName)) : false
    },
    previewReport (entiyType) {
      this.$router.push({ name: 'reports-new', params: { entiyType }, query: this.$route.query })
    },
    viewReports ({ entityClass, entityId }) {
      this.$router.push({ name: 'reports', params: { entityClass, entityId }, query: this.$route.query })
    },
    // EVENT handlers
    // TEAMS: Agencies & Clients
    onCreateTeam ({ isAgency }) {
      if (isAgency) {
        this.showModal({ newItem: true, title: this.$t('new-agency'), form: this.getNewAgencyModalForm() })
      } else {
        this.showModal({ newItem: true, title: this.$t('new-advertiser'), form: this.getNewClientModalForm() })
      }
    },
    onEditTeam ({ team, isAgency }) {
      if (isAgency) {
        this.showModal({ editItem: true, title: this.$t('edit-agency'), form: this.getEditAgencyForm(team) })
      } else {
        this.showModal({ editItem: true, title: this.$t('edit-advertiser'), form: this.getEditClientForm(team) })
      }
    },
    onDeleteTeam ({ teamId }) {
      let team = this.clients.find(team => team._id === teamId) || this.agencies.find(team => team._id === teamId)
      const role = team.role === 'agency' ? 'agency' : 'advertiser'
      // TODO: PROMPT UI!
      if (window.confirm(`${this.$t(`sure-to-delete-${role}`)}: ${team.name}`)) {
        let query = { id: teamId, role: team.role }
        const onComplete = function (responseData) {
          this.showNotification({ text: this.$t(`${role}-deleted`), type: 'success', icon: 'success' })
          if (role === 'agency') {
            this.fetchAgencies({ team_id: this.authData.team._id })
          } else {
            this.fetchClients({ team_id: this.filteredAgencies.join(',') })
          }
        }
        query.onComplete = onComplete.bind(this)
        this.deleteTeam(query)
      }
    },
    onCreateTeamReport ({ teamId }) {
      // TODO: CREATE TEAM REPORT
      window.alert(teamId)
    },
    onFilteredAgenciesChange ({ filteredAgencies }) {
      this.$route.query.fta = filteredAgencies
      this.addParamsToLocation()
      this.storeFilteredAgencies([...filteredAgencies])
      if (filteredAgencies.length === 0 && this.filteredClients.length > 0) {
        this.onFilteredClientsChange({ filteredClients: [] })
      }
    },
    onFilteredClientsChange ({ filteredClients }) {
      this.$route.query.ftc = filteredClients
      this.addParamsToLocation()
      this.storeFilteredClients([...filteredClients])
      if (filteredClients.length === 0) {
        if (this.filteredOrders.length > 0) {
          this.onFilteredOrdersChange({ filteredOrders: [] })
        }
        if (this.filteredLineItems.length > 0) {
          this.onFilteredLineItemsChange({ filteredLineItems: [] })
        }
      }
    },
    // PLANNING: Orders
    onCreateOrder () {
      this.showModal({ newItem: true, form: this.getNewOrderForm() })
    },
    onEditOrder ({ order }) {
      this.showModal({ editItem: true, title: this.$t('edit-planning'), form: this.getEditOrderForm(order) })
    },
    onDeleteOrder ({ orderId }) {
      const orderName = this.orders.find(order => order._id === orderId).name
      // TODO: PROMPT UI!
      if (window.confirm(`${this.$t('sure-to-delete-order')}: ${orderName}`)) {
        this.deleteOrder({ order_id: orderId })
      }
    },
    onCreateOrderReport ({ entityIds }) {
      const reportConfig = {
        entiyType: 'order',
        entityIds: entityIds
      }
      this.showModal({ newItem: true, title: this.$t('new-report'), form: this.getNewReportForm(reportConfig) })
    },
    // onCreateOrderReport ({ campaignIds }) {
    //   const reportConfig = {
    //     entity: 'order',
    //     entityIds: campaignIds
    //   }
    //   this.showModal({ newItem: true, title: this.$t('new-report'), form: this.getNewReportForm(reportConfig) })
    // },
    onFilteredOrdersChange ({ filteredOrders }) {
      this.$route.query.fo = filteredOrders
      this.addParamsToLocation()
      this.storeFilteredOrders([...filteredOrders])
      if (filteredOrders.length === 0 && this.filteredLineItems.length > 0) {
        this.onFilteredLineItemsChange({ filteredLineItems: [] })
      }
    },
    // PLANNING: Line Items
    onCreateLineItem ({ orderId }) {
      // const stepsTitle = [
      //   { value: '1', label: this.$t('new-planning'), status: 'completed' },
      //   { value: '2', label: this.$t('new-planning-line'), status: 'active' },
      //   { value: '3', label: this.$t('add-campaigns'), status: 'inactive' }
      // ]
      // this.showModal({ newItem: true, stepsTitle, form: this.getNewLineItemForm(orderId) })
      this.showModal({ newItem: true, form: this.getNewLineItemForm(orderId) })
    },
    onEditLineItem (lineItem) {
      this.showModal({ editItem: true, title: this.$t('edit-planning-line'), form: this.getEditLineItemForm(lineItem) })
    },
    onDeleteLineItem ({ lineItemId }) {
      // const lineItemName = this.lineItems.find(lineItem => lineItem._id === lineItemId).name
      const lineItemName = this.orders.map(order => order.line_items).flat().find(lineItem => lineItem._id === lineItemId).name
      // TODO: PROMPT UI!
      if (window.confirm(`${this.$t('sure-to-delete-planning-line')}: ${lineItemName}`)) {
        this.deleteLineItem({ id: lineItemId })
      }
    },
    onCreateLineItemReport ({ entityIds }) {
      const reportConfig = {
        entiyType: 'lineitem',
        entityIds: entityIds
      }
      this.showModal({ newItem: true, title: this.$t('new-report'), form: this.getNewReportForm(reportConfig) })
    },
    // onCreateLineItemReport ({ campaignIds }) {
    //   const reportConfig = {
    //     entity: 'lineitem',
    //     entityIds: campaignIds
    //   }
    //   this.showModal({ newItem: true, title: this.$t('new-report'), form: this.getNewReportForm(reportConfig) })
    // },
    onFilteredLineItemsChange ({ filteredLineItems }) {
      this.$route.query.fli = filteredLineItems
      this.addParamsToLocation()
      this.storeFilteredLineItems([...filteredLineItems])
      if (filteredLineItems.length === 0 && this.filteredCampaigns.length > 0) {
        this.onFilteredCampaignsChange({ filteredCampaigns: [] })
      }
    },
    onRefreshOrder ({ id }) {
      this.refreshOrder({ id, onComplete: this.fetchData })
    },
    onRefreshLineItem ({ id }) {
      this.refreshLineItem({ id, onComplete: this.fetchData })
    },
    // CAMPAIGNS:
    onCreateCampaign () {
      // this.showModal({ newItem: true, title: this.$t('new-campaign'), form: this.getNewCampaignForm() })
      let objective
      this.orders.forEach(order => {
        order.line_items.filter(lineItem => this.filteredLineItems.includes(lineItem._id))
          .forEach(lineItem => {
            objective = lineItem.objective
          })
      })
      this.$router.push({ name: 'campaign', params: { objective: objective }, query: this.$route.query })
    },
    onEditCampaign ({ campaign }) {
      this.showModal({ editItem: true, title: this.$t('edit-campaign'), form: this.getEditCampaignForm(campaign) })
    },
    onDeleteCampaign ({ campaignId }) {
      const campaignName = this.campaigns.find(campaign => campaign._id === campaignId).name
      // TODO: PROMPT UI!
      if (window.confirm(`${this.$t('sure-to-delete-campaign')}: ${campaignName}`)) {
        let query = { id: campaignId }
        // this.deleteCampaign({ campaign_id: campaignId })
        const onComplete = function (responseData) {
          this.showNotification({ text: this.$t('campaign-deleted'), type: 'success', icon: 'success' })
        }
        query.onComplete = onComplete.bind(this)
        this.deleteCampaign(query)
      }
    },
    onOptimizeCampaign ({ campaign }) {
      this.showModal({ editItem: true, title: this.$t('optimize-campaign'), subtitle: campaign.name, ins: campaign.ins })
    },
    onPlayCampaign ({ id }) {
      this.updateCampaign({ id, status: 'ACTIVE', onComplete: this.fetchData })
    },
    onPauseCampaign ({ id }) {
      this.updateCampaign({ id, status: 'PAUSED', onComplete: this.fetchData })
    },
    onRefreshCampaign ({ id }) {
      this.refreshCampaign({ id, onComplete: this.fetchData })
    },
    onCreateCampaignReport ({ entityIds }) {
      const reportConfig = {
        entiyType: 'campaign',
        entityIds: entityIds
      }
      this.showModal({ newItem: true, title: this.$t('create-report'), form: this.getNewReportForm(reportConfig) })
    },
    onFilteredCampaignsChange ({ filteredCampaigns }) {
      this.$route.query.fc = filteredCampaigns
      this.addParamsToLocation()
      this.storeFilteredCampaigns([...filteredCampaigns])
      if (filteredCampaigns.length === 0 && this.filteredAdsets.length > 0) {
        this.onFilteredAdsetsChange({ filteredAdsets: [] })
      }
    },
    // ADSETS:
    onCreateAdset ({ campaignId }) {
      this.$router.push({ path: `/campaign/${this.filteredCampaigns[0]}/adset`, query: this.$route.query })
    },
    onEditAdset ({ adset }) {
      this.showModal({ editItem: true, title: this.$t('edit-ad-set'), form: this.getEditAdsetForm(adset) })
    },
    onDeleteAdset ({ adsetId }) {
      const adsetName = this.adsets.find(adset => adset._id === adsetId).name
      // TODO: PROMPT UI!
      if (window.confirm(`${this.$t('sure-to-delete-adset')}: ${adsetName}`)) {
        let query = { id: adsetId }
        const onComplete = function (responseData) {
          this.showNotification({ text: this.$t('adset-deleted'), type: 'success', icon: 'success' })
        }
        query.onComplete = onComplete.bind(this)
        this.deleteAdset(query)
      }
    },
    onOptimizeAdset ({ adset }) {
      this.showModal({ editItem: true, title: this.$t('optimize-adset'), subtitle: adset.name, ins: adset.ins })
    },
    onPlayAdset ({ id }) {
      this.updateAdset({ id, status: 'ACTIVE', onComplete: this.fetchData })
    },
    onPauseAdset ({ id }) {
      this.updateAdset({ id, status: 'PAUSED', onComplete: this.fetchData })
    },
    onRefreshAdset ({ id }) {
      this.refreshAdset({ id, onComplete: this.fetchData })
    },
    onCreateAdsetReport ({ adsetId }) {
      // TODO: CREATE ADSET REPORT
      window.alert(adsetId)
    },
    onFilteredAdsetsChange ({ filteredAdsets }) {
      this.$route.query.fas = filteredAdsets
      this.addParamsToLocation()
      this.storeFilteredAdsets([...filteredAdsets])
      if (filteredAdsets.length === 0 && this.filteredAds.length > 0) {
        this.onFilteredAdsChange({ filteredAds: [] })
      }
    },
    // ADS:
    onCreateAd (adsetId) {
      this.$router.push({ path: `/campaign/${this.filteredCampaigns[0]}/adset/${this.filteredAdsets[0]}/ad`, query: this.$route.query })
    },
    onEditAd ({ ad }) {
      this.showModal({ editItem: true, title: this.$t('edit-ad'), form: this.getEditAdForm(ad) })
    },
    onDeleteAd ({ adId }) {
      const adName = this.ads.find(ad => ad._id === adId).name
      // TODO: PROMPT UI!
      if (window.confirm(`${this.$t('sure-to-delete-ad')}: ${adName}`)) {
        this.deleteAd({ ad_id: adId })
      }
    },
    onOptimizeAd ({ ad }) {
      this.showModal({ editItem: true, title: this.$t('optimize-ad'), subtitle: ad.name, ins: ad.ins })
    },
    onPlayAd ({ id }) {
      this.updateAd({ id, status: 'ACTIVE', onComplete: this.fetchData })
    },
    onPauseAd ({ id }) {
      this.updateAd({ id, status: 'PAUSED', onComplete: this.fetchData })
    },
    onRefreshAd ({ id }) {
      this.refreshAd({ id, onComplete: this.fetchData })
    },
    onCreateAdReport ({ adId }) {
      // TODO: CREATE AD REPORT
      window.alert(adId)
    },
    onFilteredAdsChange ({ filteredAds }) {
      this.$route.query.fa = filteredAds
      this.addParamsToLocation()
      this.storeFilteredAds([...filteredAds])
    },
    // COMPONENTS EVENTS:
    onDateFilterHideModal () {
      this.isDateSelectorVisible = false
    },
    onDateFilterChange ({ dateFilterFrom, dateFilterTo }) {
      this.storeFilterDateFrom(dateFilterFrom)
      this.storeFilterDateTo(dateFilterTo || dateFilterFrom)
    },
    onSearchTextChange () {
      this.storeFilterSearchText(this.searchText)
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
#dashboard-view {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.dashboard-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 20px;
}
.dashboard-header__breadcrumb {
  font-size: 14px;
  flex-basis: calc(100% - 650px);
  max-width: calc(100% - 660px);
  display: flex;
}
.header__breadcrumb-link {
  color: var(--app-dark-blue);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
.header__breadcrumb-link + .header__breadcrumb-link::before {
  content: ">";
  color: var(--app-dark-grey);
  display: inline-block;
  padding: 0 10px;
}
.dashboard-header__form {
  flex-basis: 650px;
  display: flex;
  position: relative;
}
#btn-clear-text {
  position: absolute;
  left: 360px;
}
#date-filter-range {
  flex-basis: 200px;
  width: 200px;
  cursor: pointer;
}
#date-filter-selector {
  position: absolute;
  top: 15px;
  right: 0;
}
</style>
