<template>
  <div class="modal">
    <div :class="`card card__modal`">
      <div class="card__header">
        <h4 class="card__title" v-if="selectedDateFrom">{{ dateFilter }}</h4>
        <h4 class="card__title" v-else>{{ $t('date') }}</h4>
        <button class="btn btn--close-modal" @click.prevent="hideModal">
          <img src="/img/baseline_clear_white_48dp.png" :alt="$t('cancel')" :title="$t('cancel')" class="btn__img btn__img--small">
        </button>
      </div>
      <div class="card__content --with-filter-bar">
        <ol class="filter-bar">
          <li v-for="(value, index) in filterValues" :key="index">
            <input type="radio" v-model="filterValue" :value="value.id" name="filterValue" :id="`filterValue_${value.id}`">
            <label :for="`filterValue_${value.id}`" :class="{ 'filter-bar__selected': filterValue === value.id}">{{ value.name }}</label>
          </li>
        </ol>
        <div>
          <!-- TODO: traducir!!! -->
          <div class="calendar-header">
            <button class="btn btn--calendar" @click.prevent="prevMonth">
              <img src="/img/baseline_navigate_before_white_48dp.png" alt="<" title="<" class="btn__img btn__img--small">
            </button>
            <h4 class="calendar-title">{{ formatDateForCalendarHeader(currentDate) }}</h4>
            <button class="btn btn--calendar" @click.prevent="nextMonth">
              <img src="/img/baseline_navigate_next_white_48dp.png" alt=">" title=">" class="btn__img btn__img--small">
            </button>
          </div>
          <ol class="calendar" v-if="currentDate">
            <li class="day col-header">L</li>
            <li class="day col-header">M</li>
            <li class="day col-header">X</li>
            <li class="day col-header">J</li>
            <li class="day col-header">V</li>
            <li class="day col-header">S</li>
            <li class="day col-header">D</li>
            <li class="day" v-for="(emptyDay, index) in getFirstDayInMonth(currentDate)" :key="`emptyDay-${index}`">&nbsp;</li>
            <li class="day" v-for="(day, index) in getDaysInMonth(currentDate)" :key="`day-${index}`"
              :class="{ 'selected-day': isInSelectionRange(day), 'first-selected': isFirstSelected(day), 'last-selected': isLastSelected(day) }"
              @click="selectDay(day)">{{ day }}</li>
          </ol>
          <div class="modal__actions calendar-actions">
            <div class="modal__actions--left">
              <!-- NOT USED -->
            </div>
            <div class="modal__actions--right">
              <button
                @click="hideModal"
                type="button" class="btn btn--text">{{ $t('cancel') }}</button>
              <button
                @click="changeDate"
                type="button" class="btn btn--ok">{{ $t('update') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// USO:
// <!-- DATE-RANGE -->
// <input
//   type="text"
//   id="date-filter-range"
//   class="input__date input__date-range"
//   v-model="dateFilter"
//   :placeholder="$t('date')"
//   @click="dateFilterShowModal">
// <!-- DATE-SELECTOR-MODAL -->
// <date-selector
//   id="date-filter-selector"
//   v-if="isDateSelectorVisible"
//   :dateFilterFrom="dateFilterFrom"
//   :dateFilterTo="dateFilterTo"
//   v-on:on-date-filter-hide-modal="dateFilterHideModal"
//   v-on:on-date-filter-change="dateFilterChange"></date-selector>

import i18n from '@/plugins/i18n'
export default {
  name: 'date-selector',
  props: [
    'dateFilterFrom',
    'dateFilterTo'
  ],
  data () {
    return {
      locale: i18n.locale,
      filterValue: 'custom',
      currentDate: null,
      selectedDateFrom: null,
      selectedDateTo: null,
      filterValues: [
        { id: 'custom', name: this.$t('CUSTOM') },
        { id: 'today', name: this.$t('TODAY') },
        { id: 'yesterday', name: this.$t('YESTERDAY') },
        { id: 'last7days', name: this.$t('LAST 7 DAYS') },
        { id: 'last14days', name: this.$t('LAST 14 DAYS') },
        { id: 'thismonth', name: this.$t('THIS MONTH') },
        { id: 'last30days', name: this.$t('LAST 30 DAYS') },
        { id: 'lastmonth', name: this.$t('LAST MONTH') },
        { id: 'lifetime', name: this.$t('LIFETIME') }
      ]
    }
  },
  computed: {
    dateFilter () {
      if (this.selectedDateFrom !== null && this.selectedDateTo !== null) {
        return (this.selectedDateFrom.getTime() === this.selectedDateTo.getTime())
          ? this.formatDateForCalendarTitle(this.selectedDateTo)
          : `${this.formatDateForCalendarTitle(this.selectedDateFrom)} - ${this.formatDateForCalendarTitle(this.selectedDateTo)}`
      } else if (this.selectedDateFrom !== null) {
        return `${this.formatDateForCalendarTitle(this.selectedDateFrom)} - `
      } else if (this.selectedDateTo !== null) {
        return ` - ${this.formatDateForCalendarTitle(this.selectedDateTo)}`
      } else {
        return this.$t('date')
      }
    }
  },
  watch: {
    filterValue (newValue, oldValue) {
      let oDate = new Date()
      oDate.setHours(0, 0, 0, 0)
      switch (newValue) {
        case 'custom':
          this.currentDate = new Date(oDate).getTime()
          this.selectedDateFrom = null
          this.selectedDateTo = null
          break
        case 'today':
          this.currentDate = new Date(oDate).getTime()
          this.selectedDateFrom = oDate
          this.selectedDateTo = oDate
          break
        case 'yesterday':
          let yesterday = new Date(oDate.setDate(oDate.getDate() - 1))
          this.currentDate = yesterday.getTime()
          this.selectedDateFrom = yesterday
          this.selectedDateTo = yesterday
          break
        case 'last7days':
          this.currentDate = new Date(oDate).getTime()
          this.selectedDateTo = new Date(oDate)
          let last7days = new Date(oDate.setDate(oDate.getDate() - 7))
          this.selectedDateFrom = last7days
          break
        case 'last14days':
          this.currentDate = new Date(oDate).getTime()
          this.selectedDateTo = new Date(oDate)
          let last14days = new Date(oDate.setDate(oDate.getDate() - 14))
          this.selectedDateFrom = last14days
          break
        case 'thismonth':
          this.currentDate = new Date(oDate).getTime()
          this.selectedDateFrom = new Date(oDate.setDate(1))
          this.selectedDateTo = new Date(oDate.setDate(new Date(oDate.getFullYear(), oDate.getMonth() - 1, 0).getDate()))
          break
        case 'last30days':
          this.currentDate = new Date(oDate).getTime()
          this.selectedDateTo = new Date(oDate)
          let last30days = new Date(oDate.setDate(oDate.getDate() - 30))
          this.selectedDateFrom = last30days
          break
        case 'lastmonth':
          oDate = new Date(oDate.setMonth(oDate.getMonth() - 1))
          this.currentDate = new Date(oDate).getTime()
          this.selectedDateFrom = new Date(oDate.setDate(1))
          this.selectedDateTo = new Date(oDate.setDate(new Date(oDate.getFullYear(), oDate.getMonth() - 1, 0).getDate()))
          break
        case 'lifetime':
          this.selectedDateFrom = null
          this.selectedDateTo = null
          break
        default:
          this.selectedDateFrom = null
          this.selectedDateTo = null
          break
      }
    }
  },
  mounted () {
    if (this.dateFilterFrom !== null) {
      let dateFrom = new Date(this.dateFilterFrom)
      dateFrom.setHours(0, 0, 0, 0)
      this.currentDate = new Date(dateFrom).getTime()
      this.selectedDateFrom = new Date(dateFrom)
    }
    if (this.dateFilterTo !== null) {
      let dateTo = new Date(this.dateFilterTo)
      dateTo.setHours(0, 0, 0, 0)
      this.currentDate = new Date(dateTo).getTime()
      this.selectedDateTo = new Date(dateTo)
    }
    if (this.dateFilterFrom === null && this.dateFilterTo === null) {
      this.currentDate = new Date()
      this.currentDate.setHours(0, 0, 0, 0)
      this.currentDate = new Date(this.currentDate).getTime()
    }
  },
  methods: {
    hideModal () {
      this.$emit('on-date-filter-hide-modal')
    },
    changeDate () {
      this.$emit('on-date-filter-change', { dateFilterFrom: this.selectedDateFrom, dateFilterTo: this.selectedDateTo })
      this.hideModal()
    },
    // TODO: date selection event
    getDaysInMonth (date) {
      let oDate = new Date(date)
      return new Date(oDate.getFullYear(), oDate.getMonth() - 1, 0).getDate()
    },
    getFirstDayInMonth (date) {
      let oDate = new Date(date)
      return new Date(oDate.getFullYear(), oDate.getMonth() - 1, 0).getDay()
    },
    selectDay (day) {
      let oDate = new Date(this.currentDate)
      if (this.selectedDateFrom === null) {
        oDate.setDate(day)
        oDate.setHours(0, 0, 0, 0)
        this.selectedDateFrom = new Date(oDate)
      } else if (this.selectedDateTo === null) {
        oDate.setDate(day)
        oDate.setHours(0, 0, 0, 0)
        if (new Date(oDate) < this.selectedDateFrom) {
          this.selectedDateTo = this.selectedDateFrom
          this.selectedDateFrom = new Date(oDate)
        } else {
          this.selectedDateTo = new Date(oDate)
        }
      } else {
        this.selectedDateFrom = null
        this.selectedDateTo = null
      }
    },
    isInSelectionRange (day) {
      let oDate = new Date(this.currentDate)
      oDate.setDate(day)
      oDate.setHours(0, 0, 0, 0)
      const currentDayTime = new Date(oDate).getTime()
      return (this.selectedDateTo !== null && this.selectedDateFrom !== null && this.selectedDateTo.getTime() === currentDayTime) ||
        (this.selectedDateTo !== null && this.selectedDateFrom !== null && this.selectedDateFrom.getTime() === currentDayTime) ||
        (this.selectedDateTo !== null && this.selectedDateFrom !== null && currentDayTime <= this.selectedDateTo.getTime() && currentDayTime >= this.selectedDateFrom.getTime())
    },
    isFirstSelected (day) {
      let oDate = new Date(this.currentDate)
      oDate.setDate(day)
      oDate.setHours(0, 0, 0, 0)
      return this.selectedDateFrom && oDate.getTime() === this.selectedDateFrom.getTime()
    },
    isLastSelected (day) {
      let oDate = new Date(this.currentDate)
      oDate.setDate(day)
      oDate.setHours(0, 0, 0, 0)
      return this.selectedDateTo && oDate.getTime() === this.selectedDateTo.getTime()
    },
    prevMonth () {
      let oDate = new Date(this.currentDate)
      this.currentDate = new Date(oDate.setMonth(oDate.getMonth() - 1))
    },
    nextMonth () {
      let oDate = new Date(this.currentDate)
      this.currentDate = new Date(oDate.setMonth(oDate.getMonth() + 1))
    },
    formatDateForCalendarHeader (date) {
      return new Date(date).toLocaleString(this.locale, { year: 'numeric', month: 'long' })
    },
    formatDateForCalendarTitle (date) {
      return new Date(date).toLocaleString(this.locale, { year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<i18n>
{
  "en": {
    "CUSTOM": "CUSTOM",
    "TODAY": "TODAY",
    "YESTERDAY": "YESTERDAY",
    "LAST 7 DAYS": "LAST 7 DAYS",
    "LAST 14 DAYS": "LAST 14 DAYS",
    "THIS MONTH": "THIS MONTH",
    "LAST 30 DAYS": "LAST 30 DAYS",
    "LAST MONTH": "LAST MONTH",
    "LIFETIME": "LIFETIME"
  },
  "es": {
    "CUSTOM": "A MEDIDA",
    "TODAY": "HOY",
    "YESTERDAY": "AYER",
    "LAST 7 DAYS": "7 DÍAS",
    "LAST 14 DAYS": "14 DÍAS",
    "THIS MONTH": "ESTE MES",
    "LAST 30 DAYS": "30 DÍAS",
    "LAST MONTH": "ÚLTIMO MES",
    "LIFETIME": "TODO"
  }
}
</i18n>
<style scoped>
.modal {
  position: absolute;
  padding: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-content: center;
  box-shadow: 0 0 50px var(--app-shadow-color);
  border-radius: var(--card-border-radius);
}
.card__modal {
  width: 100%;
  max-width: calc(100% - 20px);
  min-height: 300px;
  margin: 0;
  padding: 30px;
  border-radius: 30px;
  display: grid;
  grid-template-rows: 60px 1fr;
  overflow: visible;
  background-color: var(--app-white);
}
.card__header {
  padding-bottom: 30px;
}
.card__title {
  font-size: 24px;
  line-height: 30px;
  height: 30px;
  font-weight: var(--app-font-bold);
}
.card__content {
  padding: 0;
}
.--with-filter-bar {
  display: grid;
  grid-template-columns: 180px 280px;
  gap: 20px;
}
.filter-bar {
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  border-radius: 20px;
  background-color: var(--app-light-blue);
  overflow: hidden;
}
.filter-bar li {
  line-height: 50px;
  height: 50px;
  padding: 0;
}
.filter-bar input {
  display: none;
}
.filter-bar label {
  font-size: 16px;
  cursor: pointer;
  display: block;
  padding: 0 30px;
  border-bottom: 1px solid var(--app-white);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filter-bar li:last-of-type label {
  border-bottom: none;
}
.filter-bar__selected {
  border-left: 4px solid var(--app-dark-blue);
  background-color: #bab5E5;
}
.calendar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.calendar-header {
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.calendar-title {
  text-align: center;
  flex-basis: calc(100% - 60px);
  font-size: 14px;
  line-height: 30px;
  font-weight: var(--app-font-bold);
}
.btn--calendar {
  flex-basis: 30px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  line-height: 30px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  box-shadow: none;
}
.btn--calendar .btn__img {
  -webkit-filter: var(--app-png-dark-grey-filter);
  filter: var(--app-png-dark-grey-filter);
}
.calendar .day {
  margin: 0 0 10px;
  padding: 0;
  font-size: 12px;
  line-height: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.calendar .col-header {
  color: var(--app-disabled-color);
}
.selected-day {
  background-color: var(--app-light-ochre);
  color: var(--app-dark-grey);
}
.first-selected {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-image: radial-gradient(var(--app-ochre) 20px, transparent 0px);
}
.last-selected {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-image: radial-gradient(var(--app-ochre) 20px, transparent 0px);
}
.calendar-actions {
  margin-top: 100px;
}
.form__modal {
  margin: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form__modal--with-tabs {
  margin: 0;
}
.iframe__modal {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  height: 400px;
  width: 780px;
  overflow-y: auto;
}
.tabs__tab-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tabs {
  flex-basis: 100%;
}
.--with-subtitle {
  grid-template-rows: 64px 1fr 50px;
}
@media (min-width: 460px) {
  .card__modal {
    max-width: calc(100% - 50px);
  }
}
@media (min-width: 800px) {
  .card__modal {
    max-width: 800px;
  }
}
.modal__actions {
  margin-top: 0px;
}
</style>
