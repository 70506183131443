import { render, staticRenderFns } from "./Planning.vue?vue&type=template&id=e64e4b84&scoped=true&"
import script from "./Planning.vue?vue&type=script&lang=js&"
export * from "./Planning.vue?vue&type=script&lang=js&"
import style0 from "./Planning.vue?vue&type=style&index=0&id=e64e4b84&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e64e4b84",
  null
  
)

/* custom blocks */
import block0 from "@/core/i18n-common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fhtml%2Ffrontend%2Fsrc%2Fmodules%2Fdashboard%2Fcomponents%2FPlanning.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports