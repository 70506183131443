<template>
  <div class="tabs__tab-content-wrapper">
    <div class="tabs__tab-content-actions">
      <button class="btn btn--filter" :class="{ '--filter-active': isFieldsFilterActive }" @click.prevent="fieldsFilterShowModal">
        <img src="/img/baseline_filter_list_white_48dp.png" :alt="$t('filter')" :title="$t('filter')" class="btn__img btn__img--small">{{ $t('filter') }}
      </button>
      <button class="btn btn--filter" :class="{ '--filter-active': isColumnsFilterActive }" @click.prevent="columnsFilterShowModal">
        <img src="/img/baseline_view_week_white_48dp.png" :alt="$t('columns')" :title="$t('columns')" class="btn__img btn__img--small">{{ $t('columns') }}
      </button>
      <button v-if="$parent.canUse('order.create')" class="btn btn--new" @click.prevent="createOrder" :disabled="!filteredClients || filteredClients.length !== 1">
        <img src="/img/baseline_add_white_48dp.png" :alt="$t('create')" :title="$t('create')" class="btn__img btn__img--small">{{ $t('new-planning') }}
      </button>
      <button v-if="$parent.canUse('order.update')" class="btn btn--new btn--new-secondary" @click.prevent="createLineItem" :disabled="!filteredOrders || filteredOrders.length !== 1">
        <img src="/img/baseline_add_white_48dp.png" :alt="$t('create')" :title="$t('create')" class="btn__img btn__img--small btn__img--secondary">{{ $t('new-planning-line') }}
      </button>
      <!-- FIELDS-SELECTOR-MODAL -->
      <fields-selector
        id="fields-filter-selector"
        v-if="isFieldsSelectorVisible"
        :fieldsFilter="fieldsFilter"
        :fieldsMeta="fieldsMeta"
        v-on:on-fields-filter-hide-modal="onFieldsFilterHideModal"
        v-on:on-fields-filter-change="onFieldsFilterChange"></fields-selector>
      <!-- COLUMNS-SELECTOR-MODAL -->
      <columns-selector
        id="columns-filter-selector"
        v-if="isColumnsSelectorVisible"
        :columnsFilter="columnsFilter"
        :columnsMeta="columnsMeta"
        v-on:on-columns-filter-hide-modal="onColumnsFilterHideModal"
        v-on:on-columns-filter-change="onColumnsFilterChange"></columns-selector>
    </div>
    <div class="card planning-card">
      <div class="card__content">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('plannings') }}</h4>
          </div>
          <!--
            TODO: COMPROBAR QUE TIPO DE INFORME SE USARA,
            ES PROBABLE QUE SE PUEDA SELECCIONAR MAS DE UNA ORDEN O LINE ITEM?
          -->
          <div class="card__header-actions">
            <button v-if="$parent.canUse('report.create')" class="btn" @click.prevent="createPlanningReport" :disabled="isCreateReportDisabled">
              <img src="/img/baseline_assessment_white_48dp.png" :alt="$t('report')" :title="$t('report')" class="btn__img">{{ $t('report') }}
            </button>
            <button v-if="$parent.canUse('order.delete')" class="btn" @click.prevent="deleteLineItem" :disabled="!filteredLineItems || filteredLineItems.length !== 1">
              <img src="/img/baseline_delete_white_48dp.png" :alt="$t('delete')" :title="$t('delete')" class="btn__img">{{ $t('delete') }}
            </button>
          </div>
        </div>
        <div class="card__header" v-if="filteredLineItemsTags.length > 0">
          <div class="card__header-text">
            <a v-for="(data, index) in filteredLineItemsTags" :key="index" href="#" class="tag tag--with-margin tag--collapsible" :title="$t('clear')" @click.prevent="clearLineItemTag(data)">
              {{ data.label }}
              <img class="btn__img btn__img--small --clear-cursor" src="/img/baseline_cancel_white_48dp.png" :alt="$t('clear')">
            </a>
          </div>
        </div>
        <div class="scrollable-cols">
          <table class="card__table">
            <colgroup>
              <col class="--w50px">
              <col :class="[ showFullWidthName ? '--w600px' : '--w300px' ]">
              <col class="--w150px"><!-- platform -->
              <template v-for="(columnId, columnIndex) in columnsFilter">
                <col :class="getColumnsMeta(columnId).class" :key="columnIndex">
              </template>
              <col class="--w100px">
            </colgroup>
            <thead>
              <tr>
                <th class="sticky --left-1">&nbsp;</th>
                <th class="sticky --left-2">
                  {{ $t('planning') }}
                  <button class="btn btn--square--small" @click="changeTableOrder('name')">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'name' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button>
                  <button class="btn btn--square--small btn--sticky-right" @click="showFullWidthName = !showFullWidthName">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': showFullWidthName }" src="/img/baseline_swap_horiz_white_48dp.png" :alt="$t('expand')">
                  </button>
                </th>
                <th class="--ta-c">{{ $t('platforms') }}</th>
                <template v-for="(columnId, columnIndex) in columnsFilter">
                  <th class="--ta-r" :key="columnIndex">
                    {{ getColumnsMeta(columnId).label }}
                    <button class="btn btn--square--small" @click="changeTableOrder(columnId)" v-if="getColumnsMeta(columnId).sortable">
                      <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === columnId }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                    </button>
                  </th>
                </template>
                <th class="sticky --right">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(order, orderIndex) in orderedTableItems">
                <tr :key="order._id" class="parent-row"
                  v-show="isRowVisible(orderIndex)"
                  v-if="isParentRowFiltered(order)"
                  :class="{ 'is-selected': filteredOrders.includes(order._id), 'is-fav': isFavTableItem(order._id) }"
                  @dblclick="onParentTableRowDblClick(order._id)">
                  <td class="sticky --left-1">
                    <input type="checkbox" class="input__checkbox" :class="[ filteredOrders.includes(order._id) ? 'input__checkbox--checked' : '' ]" :id="`order-id-${order._id}`" :name="`order-id-${order._id}`" :value="order._id" :checked="filteredOrders.includes(order._id)" @change="onFilteredOrdersChange">
                    <label class="input__label input__label--checkbox input__label--only--checkbox" :for="`order-id-${order._id}`"></label>
                  </td>
                  <td class="sticky --left-2" :title="order.name">{{ order.name }}</td>
                  <td class="--ta-c">
                    <template v-if="order.platform && order.platform !== ''">
                      <template v-for="(icon, iconIndex) in $parent.translatePlatformsToIcons(order.platform)">
                        <img v-if="icon && icon !== ''" :key="iconIndex" class="table-cell-icon" :src="icon">
                      </template>
                    </template>
                  </td>
                  <template v-for="(columnId, columnIndex) in columnsFilter">
                    <td :class="`--ta-r column-${columnId}`" :key="columnIndex" :title="getColumnsMeta(columnId).format === '' ? $parent.format({ value: order[columnId], format: getColumnsMeta(columnId).format }) : false">{{ $parent.format({ value: order[columnId], format: getColumnsMeta(columnId).format, nullsy: true }) }}</td>
                  </template>
                  <td class="--ta-r sticky --right">
                    <div class="table-cell-actions">
                      <button v-if="$parent.canUse('order.update')" class="btn btn--hover" :title="$t('edit')" @click.prevent="editOrder(order)">
                        <img class="btn__img" src="/img/baseline_edit_white_48dp.png">{{ $t('edit') }}
                      </button>
                      <button v-if="$parent.canUse('order.update')" class="btn btn--hover" :title="$t('synchronize')" @click.prevent="refreshOrder({ id: order._id })">
                        <img class="btn__img" src="/img/baseline_autorenew_white_48dp.png" />{{ $t('synchronize') }}
                      </button>
                      <!-- <button class="btn btn--hover btn--fav" :title="$t('fav')" @click.prevent="favTableItem(order._id)">
                        <img class="btn__img" src="/img/baseline_star_white_48dp.png">{{ $t('fav') }}
                      </button> -->
                    </div>
                  </td>
                </tr>
                <template v-if="isRowVisible(orderIndex)">
                  <template v-for="(line, lineItemIndex) in order.line_items">
                    <tr :key="`${orderIndex}-${lineItemIndex}-lines`" class="child-row"
                      v-if="isRowFiltered(line)"
                      :class="{ 'is-selected': filteredOrders.includes(order._id) || filteredLineItems.includes(line._id) }"
                      @dblclick="onTableRowDblClick(line._id)">
                      <td class="sticky --left-1">
                        <img class="btn__img btn__img--small" src="/img/baseline_subdirectory_arrow_right_white_48dp.png">
                      </td>
                      <td :title="line.name" class="sticky --left-2">
                        <input type="checkbox" class="input__checkbox" :class="{ 'input__checkbox--checked': filteredLineItems.includes(line._id) }" :id="`line-id-${line._id}`" :name="`line-id-${line._id}`" :value="line._id" :checked="filteredLineItems.includes(line._id)" @change="onFilteredLineItemsChange">
                        <label class="input__label input__label--checkbox input__label--only--checkbox card__header-checkbox" :for="`line-id-${line._id}`"></label>
                        {{ line.name }}
                      </td>
                      <!-- <td class="--ta-c"><img class="table-cell-icon" v-if="line.platform" :src="$parent.translatePlatformToIcon(line.platform)"></td> -->
                      <td class="--ta-c">
                      <template v-if="order.platform && order.platform !== ''">
                        <template v-for="(icon, iconIndex) in $parent.translatePlatformsToIcons(line.platform)">
                          <img v-if="icon && icon !== ''" :key="iconIndex" class="table-cell-icon" :src="icon">
                        </template>
                      </template>
                    </td>
                      <template v-for="(columnId, columnIndex) in columnsFilter">
                        <td class="--ta-r" :key="columnIndex" :title="getColumnsMeta(columnId).format === '' ? $parent.format({ value: line[columnId], format: getColumnsMeta(columnId).format }) : false">{{ $parent.format({ value: line[columnId], format: getColumnsMeta(columnId).format }) }}</td>
                      </template>
                      <td class="--ta-r sticky --right">
                        <div class="table-cell-actions">
                          <button v-if="$parent.canUse('order.update')" class="btn btn--hover" :title="$t('edit')" @click.prevent="editLineItem(line, order._id)">
                            <img class="btn__img" src="/img/baseline_edit_white_48dp.png">{{ $t('edit') }}
                          </button>
                          <button v-if="$parent.canUse('order.update')" class="btn btn--hover" :title="$t('synchronize')" @click.prevent="refreshLine({ id: line._id })">
                            <img class="btn__img" src="/img/baseline_autorenew_white_48dp.png" />{{ $t('synchronize') }}
                          </button>
                          <!-- <button class="btn btn--hover btn--fav" :title="$t('fav')" @click.prevent="favTableItem(line._id)">
                            <img class="btn__img" src="/img/baseline_star_white_48dp.png">{{ $t('fav') }}
                          </button> -->
                        </div>
                      </td>
                    </tr>
                    <tr :key="`${orderIndex}-${lineItemIndex}-lines`" class="hidden-row" v-else>
                      <td :colspan="4+columnsFilter.length">&nbsp;</td>
                    </tr>
                  </template>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="pagination.total > pagination.perPage" class="card__actions">
        <div class="card__actions--left">&nbsp;</div>
        <div class="card__actions--right">
          <div class="tfoot__rows-page"
            v-for="(page, index) in new Array(pagination.totalPages)"
            :key="index"
            :class="{ 'tfoot__current-page': pagination.currentPage - 1 === index }"
            @click="setCurrentPage(index + 1)">
            {{ 1 + (index * pagination.perPage) }} - {{ Math.min((index + 1) * pagination.perPage, pagination.total) }}</div>
          <div class="tfoot__total-rows">&nbsp;{{ $t('of') }}&nbsp;{{ pagination.total }}&nbsp;</div>
          <div class="tfoot__page-actions">
            <button class="btn btn--square--small" :disabled="pagination.currentPage === 1" @click="setCurrentPage(pagination.currentPage - 1)">
              <img class="btn__img" src="/img/baseline_chevron_left_white_48dp.png" :alt="$t('prev')">
            </button>
            <button class="btn btn--square--small" :disabled="pagination.currentPage === pagination.totalPages" @click="setCurrentPage(pagination.currentPage + 1)">
              <img class="btn__img" src="/img/baseline_chevron_right_white_48dp.png" :alt="$t('next')">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FieldsSelector from '@/core/components/FieldsSelector.vue'
import ColumnsSelector from '@/core/components/ColumnsSelector.vue'
export default {
  name: 'planning',
  props: [
    'orders',
    'filteredClients',
    'filteredOrders',
    'filteredLineItems'
  ],
  components: {
    FieldsSelector,
    ColumnsSelector
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        perPage: 40,
        totalPages: 1,
        total: 0
      },
      orderField: 'none',
      orderedTableItems: [],
      favTableItems: '',
      showFullWidthName: false,
      fieldsFilter: [],
      isFieldsSelectorVisible: false,
      isFieldsFilterActive: false,
      fieldsMeta: [
        {
          id: 'platform',
          label: this.$t('platform'),
          values: [
            { id: 'platform-', label: this.$t('unasigned') },
            { id: 'platform-1', label: 'Facebook' },
            { id: 'platform-2', label: 'Twitter' }
          ]
        },
        {
          id: 'objective',
          label: this.$t('objective'),
          values: [
            { id: 'objective-LINK_CLICKS', label: this.$t('objective-link-clicks') },
            { id: 'objective-IMPRESSIONS', label: this.$t('objective-impressions') },
            { id: 'objective-REACH', label: this.$t('objective-reach') },
            { id: 'objective-PURCHASES', label: this.$t('objective-purchases') },
            { id: 'objective-APP_INSTALLS', label: this.$t('objective-app-installs') },
            { id: 'objective-CONVERSIONS', label: this.$t('objective-conversions') },
            { id: 'objective-LEAD_GENERATION', label: this.$t('objective-lead-generation') },
            { id: 'objective-VIDEO_VIEWS', label: this.$t('objective-video-views') },
            { id: 'objective-THRUPLAY', label: this.$t('objective-thruplay-views') },
            { id: 'objective-PAGE_ENGAGEMENT', label: this.$t('objective-page-engagement') },
            { id: 'objective-POST_ENGAGEMENT', label: this.$t('objective-engagement') }
          ]
        },
        {
          id: 'status',
          label: this.$t('status'),
          values: [
            { id: 'status-ACTIVE', label: this.$t('status-active') },
            { id: 'status-PAUSED', label: this.$t('status-paused') },
            { id: 'status-ARCHIVED', label: this.$t('status-archived') },
            { id: 'status-PROGRAMMED', label: this.$t('status-programmed') },
            { id: 'status-BILLED', label: this.$t('status-billed') },
            { id: 'status-FINALIZED', label: this.$t('status-finalized') }
          ]
        }
      ],
      columnsFilter: [],
      isColumnsSelectorVisible: false,
      isColumnsFilterActive: false,
      columnsMeta: [
        { id: 'status', label: this.$t('status'), format: '', sortable: true, class: '--w150px' },
        { id: 'objective', label: this.$t('objective'), format: '', sortable: true, class: '--w150px' },
        { id: 'model', label: this.$t('model'), format: '', sortable: true, class: '--w150px' },
        { id: 'budget', label: this.$t('budget'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'budget_remaining', label: this.$t('budget-remaining'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'target_results', label: this.$t('target-results'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'daily_target', label: this.$t('daily-target'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'results', label: this.$t('results'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'budget_progress', label: this.$t('budget-progress'), format: 'decimal', sortable: true, class: '--w150px' },
        { id: 'progress', label: this.$t('progress'), format: 'decimal', sortable: true, class: '--w150px' },
        { id: 'time_progress', label: this.$t('time-progress'), format: 'decimal', sortable: true, class: '--w150px' },
        { id: 'cpr', label: this.$t('cpr'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'eCPR', label: this.$t('eCPR'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'eCPR_projected', label: this.$t('eCPR-projected'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'selling_cost', label: this.$t('selling-cost'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'selling_cost_today', label: this.$t('selling-cost-today'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'spend', label: this.$t('spend'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'spend_today', label: this.$t('spend-today'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'margin', label: this.$t('margin'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'profit', label: this.$t('profit'), format: 'currency', sortable: true, class: '--w150px' },
        { id: 'impressions', label: this.$t('impressions'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'cv_link_clicks', label: this.$t('clicks'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'ctr', label: this.$t('ctr'), format: 'decimal', sortable: true, class: '--w150px' },
        { id: 'result_rate', label: this.$t('result-rate'), format: 'decimal', sortable: true, class: '--w150px' },
        { id: 'reach', label: this.$t('reach'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'frequency', label: this.$t('frequency'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'start_date', label: this.$t('start-date'), format: 'date', sortable: true, class: '--w150px' },
        { id: 'end_date', label: this.$t('end-date'), format: 'date', sortable: true, class: '--w150px' },
        { id: 'days_left', label: this.$t('days-left'), format: 'number', sortable: true, class: '--w150px' },
        { id: 'days_elapsed', label: this.$t('days-elapsed'), format: 'number', sortable: true, class: '--w150px' }
      ]
    }
  },
  computed: {
    isCreateReportDisabled () {
      return (this.filteredOrders && (this.filteredOrders.length === 0 || this.filteredOrders.length > 1) && this.filteredLineItems.length === 0) ||
        (this.filteredLineItems && (this.filteredLineItems.length === 0 || this.filteredLineItems.length > 1)) ||
        (this.filteredLineItems && (this.filteredLineItems.length === 1 && this.filteredSingleLineItem?.campaign_ids?.length === 0))
    },
    filteredLineItemsTags () {
      return this.lineItems.length > 0 && this.filteredLineItems && this.filteredLineItems.length > 0 ? this.filteredLineItems.map(lineItemId => {
        return {
          value: lineItemId,
          label: this.lineItems.find(lineItem => lineItem._id === lineItemId).name
        }
      }) : []
    },
    lineItems () {
      let lineItems = this.orders && this.orders.length > 0 ? this.orders.map(order => order.line_items).flat() : []
      return lineItems
    },
    filteredSingleLineItem () {
      let filteredSingleLineItem = this.lineItems.filter(lineItem => lineItem._id === this.filteredLineItems[0])
      return filteredSingleLineItem.length === 1 ? filteredSingleLineItem[0] : null
    }
  },
  mounted () {
    this.orderField = window.localStorage.getItem('orderField_planning') || 'none'
    this.favTableItems = window.localStorage.getItem('fav_planning') || ''
    // QUITADO: this.fieldsMeta.filter(item => item.id !== 'platform').map(field => field.values.map(value => value.id)).flat()
    this.fieldsFilter = window.localStorage.getItem('fieldsFilter_planning') ? window.localStorage.getItem('fieldsFilter_planning').split(',') : []
    this.columnsFilter = window.localStorage.getItem('columnsFilter_planning') ? window.localStorage.getItem('columnsFilter_planning').split(',') : this.columnsMeta.map(item => item.id)
  },
  watch: {
    orderField (newValue, oldValue) {
      window.localStorage.setItem('orderField_planning', newValue)
    },
    favTableItems (newValue, oldValue) {
      window.localStorage.setItem('fav_planning', newValue)
    },
    fieldsFilter (newValue, oldValue) {
      window.localStorage.setItem('fieldsFilter_planning', newValue.join(','))
      this.isFieldsFilterActive = true
    },
    columnsFilter (newValue, oldValue) {
      window.localStorage.setItem('columnsFilter_planning', newValue.join(','))
      this.isColumnsFilterActive = true
    },
    orders (newValue, oldValue) {
      this.pagination.total = newValue.length
      this.pagination.totalPages = Math.ceil(newValue.length / this.pagination.perPage)
      this.pagination.currentPage = 1
      this.orderedTableItems = this.orderTableByField(newValue || [])
    }
  },
  methods: {
    refreshOrder ({ id }) {
      this.$emit('on-refresh-order', { id })
    },
    refreshLine ({ id }) {
      this.$emit('on-refresh-line-item', { id })
    },
    createOrder () {
      this.$emit('on-create-order')
    },
    editOrder (order) {
      this.$emit('on-edit-order', { order })
    },
    deleteOrder () {
      this.$emit('on-delete-order', { orderId: this.filteredOrders[0] })
    },
    createOrderReport () {
      this.$emit('on-create-order-report', { entityIds: this.filteredOrders })
    },
    createLineItem () {
      this.$emit('on-create-line-item', { orderId: this.filteredOrders[0] })
    },
    editLineItem (lineItem, orderId) {
      this.$emit('on-edit-line-item', { ...lineItem, orderId })
    },
    deleteLineItem () {
      this.$emit('on-delete-line-item', { lineItemId: this.filteredLineItems[0] })
    },
    createLineItemReport () {
      this.$emit('on-create-line-item-report', { entityIds: this.filteredLineItems })
    },
    createPlanningReport () {
      if (this.filteredOrders && this.filteredOrders.length === 1) {
        this.createOrderReport()
      } else if (this.filteredLineItems && this.filteredLineItems.length === 1) {
        this.createLineItemReport()
      }
    },
    favTableItem (teamId) {
      if (this.favTableItems.indexOf(teamId) < 0) {
        this.favTableItems = `${this.favTableItems},${teamId}`
      } else {
        this.favTableItems = this.favTableItems.replace(`,${teamId}`, '')
      }
    },
    isFavTableItem (teamId) {
      return this.favTableItems.indexOf(teamId) >= 0
    },
    setCurrentPage (page) {
      this.pagination.currentPage = page
    },
    isRowVisible (index) {
      return index >= (this.pagination.currentPage - 1) * this.pagination.perPage && index < this.pagination.currentPage * this.pagination.perPage
    },
    orderTableByField (table) {
      const sortArray = function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      }
      const sortTextArray = function (a, b) {
        return a.localeCompare(b)
      }
      let sortedTable = (table) ? [...table] : []
      switch (this.orderField) {
        case 'name':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.name.toUpperCase(), b.name.toUpperCase()))
          break
        case 'role':
          sortedTable = sortedTable.sort((a, b) => sortArray(a.role.toUpperCase(), b.role.toUpperCase()))
          break
        case 'status':
          sortedTable = sortedTable.sort((a, b) => sortArray(a.status.toUpperCase(), b.status.toUpperCase()))
          break
        case 'start_date':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.start_date), new Date(b.start_date)))
          break
        case 'end_date':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.end_date), new Date(b.end_date)))
          break
        case 'none':
          break
        default:
          sortedTable = sortedTable.sort((a, b) => sortArray(Number(a[this.orderField]), Number(b[this.orderField])))
      }
      return sortedTable
    },
    changeTableOrder (field) {
      this.orderField = field
      this.orderedTeams = this.orderTableByField(this.orders)
    },
    isRowFiltered (row) {
      const filters = this.fieldsFilter.map(item => item.split('-'))
      let allFiltersPassed = {}
      filters.forEach(filter => { allFiltersPassed[filter[0]] = false })
      filters.forEach(filter => {
        if (row[filter[0]] === filter[1] || row[filter[0]] === Number(filter[1])) {
          allFiltersPassed[filter[0]] = true
        }
      })
      return Object.keys(allFiltersPassed).map(key => allFiltersPassed[key]).every(value => value === true)
    },
    isParentRowFiltered (row) {
      let allFiltersPassed = {
        unasigned: false,
        facebook: false,
        twitter: false
      }
      const someFilterSelected = this.fieldsFilter.includes('platform-') ||
        this.fieldsFilter.includes('platform-1') ||
        this.fieldsFilter.includes('platform-2')
      if (this.fieldsFilter.includes('platform-') && row.platform === '') {
        allFiltersPassed.unasigned = true
      }
      if (this.fieldsFilter.includes('platform-1') && row.platform.indexOf('1') >= 0) {
        allFiltersPassed.facebook = true
      }
      if (this.fieldsFilter.includes('platform-2') && row.platform.indexOf('2') >= 0) {
        allFiltersPassed.twitter = true
      }
      return !someFilterSelected || Object.keys(allFiltersPassed).map(key => allFiltersPassed[key]).some(value => value === true)
    },
    getFieldsMeta (fieldId) {
      return this.fieldsMeta.find(field => field.id === fieldId)
    },
    getColumnsMeta (columnId) {
      return this.columnsMeta.find(column => column.id === columnId)
    },
    fieldsFilterShowModal () {
      this.isFieldsSelectorVisible = true
    },
    columnsFilterShowModal () {
      this.isColumnsSelectorVisible = true
    },
    clearLineItemTag (data) {
      let filteredLineItems = [...this.filteredLineItems.filter(item => item !== data.value)]
      this.$emit('on-filtered-line-items-change', { filteredLineItems })
    },
    getFilteredCampaigns () {
      let returnValue = []
      this.orders.forEach(order => {
        order.line_items.filter(lineItem => this.filteredLineItems.includes(lineItem._id))
          .forEach(lineItem => {
            returnValue.push(...lineItem.campaign_ids)
          })
      })

      return returnValue
    },
    // EVENT handlers
    onFilteredOrdersChange (e) {
      let filteredOrders
      let filteredLineItems = [...this.filteredLineItems]
      if (e.target.checked) {
        filteredOrders = [...this.filteredOrders, e.target.value]
        this.orders.find(item => item._id === e.target.value).line_items.forEach(lineItem => {
          if (!filteredLineItems.includes(lineItem._id)) {
            filteredLineItems = [...filteredLineItems, lineItem._id]
          }
        })
      } else {
        filteredOrders = [...this.filteredOrders.filter(item => item !== e.target.value)]
        this.orders.find(item => item._id === e.target.value).line_items.forEach(lineItem => {
          filteredLineItems = [...filteredLineItems.filter(item => item !== lineItem._id)]
        })
      }
      this.$emit('on-filtered-orders-change', { filteredOrders })
      this.$emit('on-filtered-line-items-change', { filteredLineItems })
    },
    onFilteredLineItemsChange (e) {
      let filteredLineItems
      if (e.target.checked) {
        filteredLineItems = [...this.filteredLineItems, e.target.value]
      } else {
        filteredLineItems = [...this.filteredLineItems.filter(item => item !== e.target.value)]
        this.orders.forEach(order => {
          order.line_items.forEach(lineItem => {
            if (lineItem._id === e.target.value) {
              let filteredOrders = [...this.filteredOrders.filter(item => item !== order._id)]
              this.$emit('on-filtered-orders-change', { filteredOrders })
            }
          })
        })
      }
      this.$emit('on-filtered-line-items-change', { filteredLineItems })
    },
    onParentTableRowDblClick (orderId) {
      let filteredOrders = [...this.filteredOrders]
      let filteredLineItems = [...this.filteredLineItems]

      if (!filteredOrders.includes(orderId)) {
        filteredOrders.push(orderId)
        this.orders.find(item => item._id === orderId).line_items.forEach(lineItem => {
          filteredLineItems = [...filteredLineItems, lineItem._id]
        })
      }
      this.$emit('on-filtered-orders-change', { filteredOrders })
      this.$emit('on-filtered-line-items-change', { filteredLineItems })
      window.setTimeout(() => {
        this.$parent.clickCampaignsTab()
      })
    },
    onTableRowDblClick (lineId) {
      let filteredLineItems = [...this.filteredLineItems]
      if (!filteredLineItems.includes(lineId)) {
        filteredLineItems.push(lineId)
      }
      this.$emit('on-filtered-line-items-change', { filteredLineItems })
      window.setTimeout(() => {
        this.$parent.clickCampaignsTab()
      })
    },
    onFieldsFilterHideModal () {
      this.isFieldsSelectorVisible = false
    },
    onFieldsFilterChange ({ fieldsFilter }) {
      this.fieldsFilter = [...fieldsFilter]
    },
    onColumnsFilterHideModal () {
      this.isColumnsSelectorVisible = false
    },
    onColumnsFilterChange ({ columnsFilter }) {
      this.columnsFilter = [...columnsFilter]
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
.tabs__tab-content-wrapper {
  grid-template-columns: 1fr;
  grid-template-rows: 66px 1fr;
  grid-column-gap: 20px;
}
#columns-filter-selector,
#fields-filter-selector {
  position: absolute;
  top: 150px;
  right: 50px;
}
#fields-filter-selector {
  left: 50%;
  right: unset;
}
.column-target_results,
.column-daily_target,
.column-results,
.column-progress,
.column-cpr,
.column-eCPR,
.column-eCPR_projected,
.column-spend_today,
.column-impressions,
.column-cv_link_clicks,
.column-ctr,
.column-result_rate,
.column-model,
.column-objetive {
  color: transparent;
}
</style>
