import { render, staticRenderFns } from "./Adsets.vue?vue&type=template&id=85bd6396&scoped=true&"
import script from "./Adsets.vue?vue&type=script&lang=js&"
export * from "./Adsets.vue?vue&type=script&lang=js&"
import style0 from "./Adsets.vue?vue&type=style&index=0&id=85bd6396&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85bd6396",
  null
  
)

/* custom blocks */
import block0 from "@/core/i18n-common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fhtml%2Ffrontend%2Fsrc%2Fmodules%2Fdashboard%2Fcomponents%2FAdsets.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports